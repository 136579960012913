import React, { useEffect, useState,useMemo } from "react";
import { useNavigate } from "react-router-dom";
import IneligibleCandidateStatusTable from "../../components/IneligibleCandidateStatusTable";
import Loader from "../../components/Loader";
import './ineligibleCandidateStatus.css'
import {
  APP_ERROR_CODES,
  INELIGIBLE_CANDIDATE_STATUS_HEADER,
  muleApiInstance,
  DEFAULT_DATE_SPAN,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import IneligibleCandidateStatusFilters from "../../components/IneligibleCandidateStatusFilters";

const IneligibleCandidateStatus = () => {
  const navigate = useNavigate();
  const state = useSelector((fnState) => fnState);
  const [dataLoading, setDataLoading] = useState(true);
  const [appliedFilters, setappliedFilters] = useState({});
  const [ineligibleDetails, setIneligibleDetails] = useState([]);
  const [downloadData,setDownloadData] = useState([]);
  const [totalDetails, setTotalDetails] = useState([]);


  
  const getAcademicYear = (date) =>  {
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();
    return currentMonth >= 8 ? date.getFullYear() + 1 : date.getFullYear()
  }
  
  const today = new Date(); 
  const academicYear = `Summer ${getAcademicYear(today)}`;
  console.log(academicYear,'which year?')

  const getIneligibleData = (
    centreno,
    ocrcandidatenumber,
    firstname,
    lastname,
  ) => {
    return muleApiInstance({
      url: "eligibilityHistory",
      params: {
        centreId: centreno ? centreno : null,
        ocrCandidateNumber: ocrcandidatenumber ? ocrcandidatenumber : null,
        candidateFirstname: firstname ? firstname : null,
        candidateLastname: lastname? lastname : null,
        level: "all",
      },
    })
  }
  


  const catchError=(err)=>{
    console.log(err);
    setDataLoading(false);
    navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
  }

  const fetchData = async () => {
    
    try {
        
      const response = await getIneligibleData(
        appliedFilters?.centreId ? appliedFilters?.centreId : null,
    
        appliedFilters?.searchFieldFilter?.ocrcandidatenumber ? appliedFilters?.searchFieldFilter?.ocrcandidatenumber : null,
    
        appliedFilters?.searchFieldFilter?.candidateFirstname
          ? appliedFilters?.searchFieldFilter?.candidateFirstname
          : null,
    
        appliedFilters?.searchFieldFilter?.candidateLastname
          ? appliedFilters?.searchFieldFilter?.candidateLastname
          : null,
      )
        
        console.log(response.data,"Datainside")
        setDownloadData(response.data)
        const live = response.data.filter((x)=> x.status === "Live")
        setIneligibleDetails(live);
        if(!appliedFilters?.centreId && !appliedFilters?.searchFieldFilter?.ocrcandidatenumber && !appliedFilters?.searchFieldFilter?.candidateFirstname && !appliedFilters?.searchFieldFilter?.candidateLastname ){
        setTotalDetails(response.data);
        // setDownloadData(resp.data)
        console.log(totalDetails,'intot') //duration as filter and no filter for other options
      }
      setDataLoading(false);
    }

    catch(err){
      setDataLoading(false);
      setIneligibleDetails([]);
     console.log(err)
    };
  }
  useEffect(() => {
    setDataLoading(true);
    fetchData();  
    console.log("chai app", appliedFilters);
  }, [appliedFilters]);

  console.log(downloadData,"DataDownload")
  console.log(appliedFilters,"filterdata")
  console.log(ineligibleDetails,"details")

  return dataLoading ? (
    <div className="d-flex checkpoint-table-loader">
      <Loader />
    </div>
  ) : (
    <>
      <div className="d-flex">
        <IneligibleCandidateStatusFilters
          centersList={ineligibleDetails}
          totalDetails={totalDetails}
          applyFilter={setappliedFilters}
        />
        <IneligibleCandidateStatusTable
          downloadData={downloadData}
          tableData={ineligibleDetails}
          tableHeaders={INELIGIBLE_CANDIDATE_STATUS_HEADER}
          appliedFilters={appliedFilters}
        />
      </div>
    </>
  );
};

export default IneligibleCandidateStatus;
