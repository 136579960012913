import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Error404 } from "@cambridgeassessment/cambridge-ui";
import { Routes, Route, useNavigate } from "react-router-dom";
import { setOrgSelection, setUserInfo } from "./redux/actions/actions";
import Layout from "./containers/Layout/Layout";
import Homepage from "./containers/Homepage";
import AccessErrorPage from "./containers/AccessErrorPage";
import GenericErrorPage from "./containers/GenericErrorPage";
import Loader from "./components/Loader";
import { createSelectedOrg } from "./utils/utils";
import ProtectedRoute from "./containers/ProtectedRoute/ProtectedRoute";
import "./App.css";
import WelcomeMessageScreen from "./components/WelcomeMessageScreen";
import CandidatesPage from "./containers/Candidates";
import ResultPage from "./containers/Candidates/ResultPage";
import { APP_ERROR_CODES } from "./utils/constants";
import InternalAdminCheckpoint from "./containers/Checkpoints/InternalAdminCheckpoint";
import IneligibleCandidateStatus from "./containers/IneligibleCandidateStatus";
import InternalAdminCertificationStatus from "./containers/Checkpoints/InternalAdminCertificationStatus";
import CheckpointDateRange from "./components/CheckpointDate";
const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInfoLoding, setUserInfoLoading] = useState(true);
  const url = process.env.REACT_APP_LAMBDA_URL;

  const redirectToErrorPage=(err)=>{
    console.log(err);
    setUserInfoLoading(false);
    navigate("/unauthorized", { state: { code: APP_ERROR_CODES.unauthorized } });
  }

  useEffect(() => {
      if (window.location.search.includes("code=")) {
        //1.get the auth url
        axios
          .get(`${url}/login${window.location.search}`)
          .then((response) => {
            window.history.pushState("", "", "/");
            // above call will give access_token as a response
            axios
              .get(`${url}/userDetails`, {
                headers: {
                  authorization: response.data.access_token,
                },
              })
              .then((userDetailResponse) => {
                localStorage.setItem(
                  "cambridge_session",
                  JSON.stringify(userDetailResponse.data)
                );
                dispatch(setUserInfo(userDetailResponse.data));
                if (userDetailResponse.data.lastSelectedOrg) {
                  dispatch(
                    setOrgSelection(
                      createSelectedOrg(
                        userDetailResponse.data.orgs.filter(
                          (org) =>
                            org.bpid === userDetailResponse.data.lastSelectedOrg
                        )[0],
                        userDetailResponse.data.roles
                      )
                    )
                  );
                } else {
                  const reduxSlice = createSelectedOrg(
                    userDetailResponse.data.orgs[0],
                    userDetailResponse.data.roles
                  );
                  dispatch(setOrgSelection(reduxSlice));
                }
                setUserInfoLoading(false);
              });
          })
          .catch((err) => {
            redirectToErrorPage(err)
          });
      }
      //if ?code='***' parameter is not present make a call to get it[auth-grant code]
      else {
        axios
          .get(`${url}/`)
          .then((responseUrl) => {
            return window.location.href = responseUrl.data; // NOSONAR
          })
          .catch((err) => {
            redirectToErrorPage(err)
          });
      }
  }, []);

  return (
    <div className="App" data-testid="App">
      {userInfoLoding ? (
        <div className="app-loader d-flex justify-content-center min-vh-100"> <Loader /></div>
      ) : (
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route element={<ProtectedRoute />}>
            <Route path="dashboard" element={<Homepage />}>
              <Route index element={<WelcomeMessageScreen />} />
              <Route path="candidates" element={<CandidatesPage />}>
                <Route path=":id" element={<ResultPage />} />
              </Route>
              <Route path='checkpoint-status' element={<InternalAdminCheckpoint/>}/>
              <Route path='certification-status' element={<InternalAdminCertificationStatus/>}/>
              <Route path='ineligible-candidate-status' element={<IneligibleCandidateStatus/>}/>
              <Route path='checkpoint-setDate' element={<CheckpointDateRange />} />            
            </Route>
          </Route>
          <Route path="unauthorized" element={<AccessErrorPage />} />
          <Route path="error" element={<GenericErrorPage />} />
          {/* catch all */}
          <Route path="*" element={<Error404 />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
