import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as NumberOne } from "../../assets/images/number-one-icon.svg";
import { ReactComponent as NumberTwo } from "../../assets/images/number-two-icon.svg";
import { ReactComponent as NumberThree } from "../../assets/images/number-three-icon.svg";
import { ReactComponent as NumberFour } from "../../assets/images/number-four-icon.svg";
import { ReactComponent as NumberFive } from "../../assets/images/number-five-icon.svg";

import { Button, IconButton, Rating, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setFeedback } from "../../redux/actions/actions";

const FeedbackForm = (props) => {
  const state = useSelector((fnState) => fnState);
  const dispatch = useDispatch();
  const [currentQuestionNo, setCurrentQuestionNo] = useState(0);
  const [inactive, setInactive] = useState(true);
  const [feedbackResponse, setFeedbackResponse] = useState({
    rating: 0,
    textFeedback: "",
  });

  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const customIcons = {
    1: {
      icon: (
        <SvgIcon color="info" sx={{ marginX: "4px" }}>
          <NumberOne />
        </SvgIcon>
      ),
      label: "Very Dissatisfied",
    },
    2: {
      icon: (
        <SvgIcon color="info" sx={{ marginX: "4px" }}>
          <NumberTwo />
        </SvgIcon>
      ),
      label: "Dissatisfied",
    },
    3: {
      icon: (
        <SvgIcon color="info" sx={{ marginX: "4px" }}>
          <NumberThree />
        </SvgIcon>
      ),
      label: "Neutral",
    },
    4: {
      icon: (
        <SvgIcon color="info" sx={{ marginX: "4px" }}>
          <NumberFour />
        </SvgIcon>
      ),
      label: "Satisfied",
    },
    5: {
      icon: (
        <SvgIcon color="info" sx={{ marginX: "4px" }}>
          <NumberFive />
        </SvgIcon>
      ),
      label: "Very Satisfied",
    },
  };

  const IconContainer = (iconProps) => {
    const { value, ...other } = iconProps;
    return <span {...other}>{customIcons[value].icon}</span>;
  };

  const RadioGroupRating = (ratingProps) => {
    return (
      <StyledRating
        {...ratingProps}
        onChange={(e) => {
          setFeedbackResponse({ ...feedbackResponse, rating: e.target.value });
          setInactive(false);
        }}
      />
    );
  };
  const renderFeedbackContent = (currentQuestionNum) => {
    if (currentQuestionNum < 0) {
      return (
        <div>
          <Typography className="px-2 py-4 fs-4">{`Thank you ${state.userInfo.name} for your valuable feedback`}</Typography>
        </div>
      );
    }
    let currentQuestion = props.questions[currentQuestionNum];
    switch (currentQuestion.question_type) {
      case "rating":
        return (
          <div className="text-center">
            <Typography className="px-2 py-4 fs-4">
              {currentQuestion.question_text}
            </Typography>
            {/* <Rating
              precision={0.5}
              size="large"
              onChange={() => setInactive(false)}
              className="fs-1"
              IconContainerComponent={IconContainer}
            /> */}
            <RadioGroupRating
              defaultValue={feedbackResponse.rating}
              name="highlight-selected-only"
              IconContainerComponent={IconContainer}
              getLabelText={(value) => customIcons[value].label}
              // highlightSelectedOnly
              size="large"
              style={{ transform: "scale(1.42)", marginTop: "10px" }}
            />
          </div>
        );
      case "text":
        return (
          <div className="text-center">
            <Typography className="px-2 py-4 fs-4">
              {currentQuestion.question_text}
            </Typography>
            <TextareaAutosize
              minRows={6}
              placeholder="Your answer"
              style={{ minWidth: "480px" }}
              onChange={(e) => {
                setFeedbackResponse({
                  ...feedbackResponse,
                  textFeedback: e.target.value,
                });
                setInactive(false);
              }}
              className="px-3 pt-2"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleFeedbackAction = () => {
    if (currentQuestionNo < 0) {
      dispatch(setFeedback(false));
      return;
    }
    if (
      currentQuestionNo >= 0 &&
      currentQuestionNo < props.questions.length - 1
    ) {
      setCurrentQuestionNo(currentQuestionNo + 1);
      setInactive(true);
    } else {
      props.submitResponse(feedbackResponse);
      setCurrentQuestionNo(-1);
      if (currentQuestionNo === -1) {
        dispatch(setFeedback(false));
      }
    }
  };
  return (
    <>
      <Dialog
        maxWidth="md"
        open={state.feedback}
        onClose={() => {
          dispatch(setFeedback(false));
        }}
      >
        <DialogTitle>
          <Typography variant="h3">Feedback</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch(setFeedback(false));
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {renderFeedbackContent(currentQuestionNo)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={inactive}
            onClick={() => {
              handleFeedbackAction();
            }}
            size="large"
          >
            {currentQuestionNo < 0
              ? "Done"
              : currentQuestionNo < props.questions.length - 1
              ? "Next"
              : "Submit feeback"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FeedbackForm;
