import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Outlet, useOutletContext, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import {
  APP_ERROR_CODES,
  CANDIDATES_LIST_TABLE_HEADER,
  DEFAULT_DATE_SPAN,
  muleApiInstance,
} from "../../utils/constants";
import _ from "lodash";
import CustomCandidateTable from "../../components/CustomCandidateTable";
import "./candidate.css";
import { setLoader } from "../../redux/actions/actions";

const CandidatesPage = () => {
 
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((fnState) => fnState);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [resultDetailData, setResultDetailData] = useState([]);
  const [ineligibleData,setIneligibleData] = useState([]);
  const [ineligibleOverride,setIneligibleOverride] = useState([])
  const [childOutlet,setChildOutlet] = useState({});
  const outletProps = useOutletContext();
  const [ineligibleDataCount,setIneligibleDataCount] = useState([]);

  const getCandidateSummary = (
    startDate,
    endDate,
    rsacandidateno,
    subjectlevel,
    qualification,
    qualificationStatus,
    certificatingStatus
  ) => {
    
    // let resultFlag = null;
    // if (qualificationStatus === 'Achieved' || qualificationStatus === 'Eligible' || qualificationStatus === 'Not Eligible') {
    //     resultFlag = qualificationStatus;
    // }
    // console.log('resultflag',resultFlag)
    return muleApiInstance({
      url: "candidatesSummary",
      params: {
        centreId: state.selectedOrg.orgId,
        startDate: startDate ? startDate : DEFAULT_DATE_SPAN.startDate,
        endDate: endDate ? endDate : DEFAULT_DATE_SPAN.endDate,
        rsaCandidateNumber: rsacandidateno ? rsacandidateno : null,
        subjectLevel: subjectlevel ? subjectlevel : null,
        mainscheme: qualification ? qualification : null,
        qualificationResult: certificatingStatus ? certificatingStatus : null,
        resultFlag: qualificationStatus ?  qualificationStatus : null,
      },
    });
  };

  const getCandidateResultDetails = (methodParams) => {
    const{startDate,
      endDate,
      rsacandidateno,
      qualificationFilter,
      subjectLevelFilter,
      unitFilter,
      certificatingStatus,
      qualificationStatus,
      unitAchievementStatus}=methodParams
    return muleApiInstance({
      url: "candidatesResults",
      params: {
        centreNumber: state.selectedOrg.orgId,
        startDate: startDate ? startDate : DEFAULT_DATE_SPAN.startDate,
        endDate: endDate ? endDate : DEFAULT_DATE_SPAN.endDate,
        rsCandidateNumber: rsacandidateno ? rsacandidateno : null,
        subjectLevel: subjectLevelFilter ? subjectLevelFilter : null,
        mainscheme: qualificationFilter ? qualificationFilter : null,
        unitNumber: unitFilter ? unitFilter && unitFilter.join(",") : null,
        qualificationResult: qualificationStatus ? qualificationStatus : null,
        certificationStatus: certificatingStatus ? certificatingStatus : null,
        unitResultStatus: unitAchievementStatus ? unitAchievementStatus : null,
      },
    });
  };

  const getAcademicYear = (date) =>  {
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();
    return currentMonth >= 8 ? date.getFullYear() + 1 : date.getFullYear()
  }
  
  const today = new Date(); 
  const academicYear = `Summer ${getAcademicYear(today)}`;
  const getIneligibleData = (
    startDate,
    endDate,
    rsacandidateno,
    subjectlevel,
    qualification,
    qualificationStatus,
    certificatingStatus
  ) => {
    return muleApiInstance({
      url: "candidatesSummary",
      params: {
        centreId: state.selectedOrg.orgId,
        startDate: startDate ? startDate : DEFAULT_DATE_SPAN.startDate,
        endDate: endDate ? endDate : DEFAULT_DATE_SPAN.endDate,
        rsaCandidateNumber: rsacandidateno ? rsacandidateno : null,
        subjectLevel: subjectlevel ? subjectlevel : null,
        mainscheme: qualification ? qualification : null,
        qualificationResult: academicYear ,
        resultFlag: "Not Eligible"
      },
    });
  };

  const renderComponentBody = () => {
    
    if (window.location.pathname === "/dashboard/candidates") {
      return (
        <CustomCandidateTable
          tableHeaders={CANDIDATES_LIST_TABLE_HEADER}
          tableData={tableData}
          getCandidateSummary = {fetchCandidateSummary}
          getCandidateResultDetails = {fetchResults}
          ineligibleDetails={fetchIneligibleData}
          outletProps = {childOutlet}
          detailedView={resultDetailData}
          ineligibleView={ineligibleData}
          getIneligibleSummary = {fetchIneligibleData}
          ineligibleDataCount={ineligibleDataCount}
          eligibilityOverrideCount = {ineligibleOverride}
          fetchIneligibleDataCount = {fetchIneligibleDataCount}
        />
      );
    }
    return <Outlet context={{ ...outletProps }} />;
  };

  


  const catchApiError=(err)=>{
    console.log(err);
    setDataLoading(false);
    navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
  }
const fetchCandidateSummary = () =>  getCandidateSummary(
  outletProps.appliedFilters.regStartDateFilter,
  outletProps.appliedFilters.regEndDateFilter,
  outletProps.appliedFilters.searchFieldFilter,
  outletProps.appliedFilters.subjectLevelFilter,
  outletProps.appliedFilters.qualificationFilter,
  outletProps.appliedFilters.qualificationStatus,
  outletProps.appliedFilters.certificatingStatus
)
  .then((resp) => {
    setTableData(resp.data);
    console.log("data",resp.data);
    dispatch(setLoader(true))
    setDataLoading(false);
  })
  .catch((err) => {
    catchApiError(err)
    dispatch(setLoader(false))
  });

  const fetchIneligibleData = () =>  getIneligibleData(
    outletProps.appliedFilters.regStartDateFilter,
    outletProps.appliedFilters.regEndDateFilter,
    outletProps.appliedFilters.searchFieldFilter,
    outletProps.appliedFilters.subjectLevelFilter,
    outletProps.appliedFilters.qualificationFilter,
    outletProps.appliedFilters.certificatingStatus
  )
    .then((resp) => {
      setIneligibleData(resp.data);
      console.log("data",resp.data);
      dispatch(setLoader(true))
      setDataLoading(false);
    })
    .catch((err) => {
      catchApiError(err)
      dispatch(setLoader(false))
    });

    const fetchIneligibleDataCount = () =>  getIneligibleData()
      .then((resp) => {
        setIneligibleDataCount(resp.data);
        setIneligibleOverride(resp.data.filter((row) => row.eligibilityoverride === 'false'))
        console.log("dataMain");
        dispatch(setLoader(true))
        setDataLoading(false);
      })
      .catch((err) => {
        catchApiError(err)
        dispatch(setLoader(false))
      });

  const fetchResults = () => getCandidateResultDetails(
    {startDate: outletProps.appliedFilters.regStartDateFilter,
      endDate:outletProps.appliedFilters.regEndDateFilter,
      rsacandidateno:outletProps.appliedFilters.searchFieldFilter,
      qualificationFilter:outletProps.appliedFilters.qualificationFilter,
      subjectLevelFilter:outletProps.appliedFilters.subjectLevelFilter,
      unitFilter:outletProps.appliedFilters.unitFilter,
      certificatingStatus:outletProps.appliedFilters.certificatingStatus,
      qualificationStatus:outletProps.appliedFilters.qualificationStatus,
      unitAchievementStatus:outletProps.appliedFilters.unitAchievementStatus
    }
  )
    .then((resp) => {
      setResultDetailData(resp.data);
      const uniqueStatus = resp.data.map((item) => item.finalResultStatus);
      setDataLoading(false);

      outletProps.unitFilterItems[1]({
        unitNumberDropdown: _.uniqBy(
          resp.data.map((item) => ({
            unitNumber: item.unitNumber,
            unitAchievableName: item.unitAchievableName,
          })),
          "unitNumber"
        ).sort((a, b) => a.unitNumber - b.unitNumber),
        unitAchievementDropdown: _.uniqWith(uniqueStatus, _.isEqual),
        unitAchievementDropdownDefault: _.uniqWith(uniqueStatus, _.isEqual),
      });
      setDataLoading(false);
      console.log(outletProps,"hidden");
    })
    .catch((err) => {
      catchApiError(err)
    });
  useEffect(() => {
    setDataLoading(true);
    fetchCandidateSummary();
    fetchResults();
    fetchIneligibleData();
    fetchIneligibleDataCount();

  }, [outletProps.appliedFilters]);

  
  console.log(outletProps,"outletProps");
  if (dataLoading) {
    return (
      <div className="candidate-loader d-flex justify-content-center min-vh-100">
        <Loader />
      </div>
    );
  }
  return <>{renderComponentBody()}</>;
};

export default CandidatesPage;
