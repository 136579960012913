import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@cambridgeassessment/cambridge-ui";
//import {makeStyles} from '@mui/styles';
import { Button, TablePagination, Typography,Tooltip } from "@mui/material";
import dayjs from 'dayjs';
import { csvGenerator, getComparator, getStatusStyle } from "../../utils/utils";
import EnhancedTableHead from "../EnhancedTabelHead";
import "./detailedViewTable.css";
import { candidateListPageLabels } from "../../utils/labels";
import { CANDIDATES_DETAILEDVIEW_TABLE_HEADER } from "../../utils/constants";
const DetailedViewTable = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("ocrCandidateNumber");

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - props.detailsTableData.length)
      : 0;

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  console.log(rowsPerPage,"rowsPerPage");
  console.log(props.detailsTableData,"DetailTableData")
  console.log(props.detailsTableData.length,"number")
  const getPagination = () => {
    if (props.detailsTableData.length > 0) {
      return rowsPerPage > 0
        ? props.detailsTableData
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : props.detailsTableData.sort(getComparator(order, orderBy));
    } else return [];
  
  };

  const onDownloadClick = () => {
    let jsonData = props.detailsTableData;

    let csv = csvGenerator(
      jsonData,
      CANDIDATES_DETAILEDVIEW_TABLE_HEADER,
      'candidateDetailed'
    );
    const link = document.createElement("a");
    let blob = new Blob([csv]);
    let url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${candidateListPageLabels.detailedFileLabel}_${dayjs().format("DD/MM/YYYY")}.csv`; //Name the file here
    link.click();
  };
  // const styles = makeStyles((theme) => ({
  //   customTooltip: {
  //     backgroundColor: '#ffcccc',
  //     color: 'black',
  //     borderRadius: '4px',
  //     padding: '8px',
  //     fontSize: '14px',
  //     maxWidth: '200px', 
  //     boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  //   },
  //   customArrow:{
  //     '&:before': {
  //     backgroundColor: '#ffcccc', 
  //   }}
  // }));

  // const tooltipClasses = styles();

  return (
    <div className="">
      <TableContainer sx={{ width: "auto" }}>
        <div className="download-csv-button py-1">
        <Button
          color="primary"
          onClick={() => onDownloadClick()}
          size="large"
          style={{
            margin: 8,
          }}
          variant="contained"
          className="px-3"
        >
          {candidateListPageLabels.downloadCsv}
        </Button>
      </div>
        <Table
          sx={{
            width: "auto",
          }}
          aria-label="simple table"
          // className="table table-responsive"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={props.tableHeader}
            inDetailViewTable={true}
          />
          <TableBody>
            {props.detailsTableData.length > 0 ? (
              getPagination().map((row, idx) => {
                return (
                  <TableRow key={`row_${idx}`}>
                    <TableCell>{row.centreCandidateNo}</TableCell>
                    <TableCell>{row.ocrCandidateNumber}</TableCell>
                    <TableCell>{row.candidateFirstname}</TableCell>
                    <TableCell>{row.candidateLastname}</TableCell>
                    <TableCell>{row.qualification}</TableCell>
                    <TableCell>{row.mainScheme}</TableCell>
                    <TableCell>{row.expectedGlh}</TableCell>
                    {/*<TableCell className="hideElement">{row.actualGlh}</TableCell>*/}
                    <TableCell>{row.certificationStatus}</TableCell>
                    {/* <TableCell className="hideElement">{getStatusStyle(row?.finalQualificationResult)}</TableCell> */}
                    <TableCell>{row.glhUnit}</TableCell>
                    <TableCell>{row.unitType}</TableCell>
                    <TableCell>{row.unitNumber}</TableCell>
                    <TableCell>{row.unitAchievableName}</TableCell>
                    <TableCell>
                      {getStatusStyle(row.finalResultStatus)}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <Typography variant="subtitle1" className="text-center py-3">
                    No Results Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={props.detailsTableData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100, 150, 200]}
      />
    </div>
  );
};
export default DetailedViewTable;
