import React from "react";
import questions_list from "./questions.json";
import FeedbackForm from "../../components/FeedbackForm";
import { mulePostApiInstance } from "../../utils/constants";
import { useSelector } from "react-redux";
const Feedback = () => {
  const state = useSelector((fnState) => fnState);

  const submitFeedback = (resp) => {
    mulePostApiInstance({
      url: "feedbacks",
      data: [
        {
          centreId: state.selectedOrg.orgId,
          centrename: state.selectedOrg.name,
          submitttedBy: state.userInfo.email,
          role: state.selectedOrg.role,
          score: resp.rating,
          feedback: resp.textFeedback,
        },
      ],
    });
  };

  return (
    <FeedbackForm questions={questions_list} submitResponse={submitFeedback} />
  );
};

export default Feedback;
