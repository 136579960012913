import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "./checkpointdate.css";
import {
  muleApiInstance,
  mulePostApiInstance,
  APP_ERROR_CODES,
} from "../../utils/constants";
import { useSelector } from "react-redux";
import Loader from "../Loader";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { csvGenerator } from "../../utils/utils";
const CheckpointDateRange = () => {
  const state = useSelector((fnState) => fnState);
  const navigate = useNavigate();
  const [snackBarProps, setSnackBarProps] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  const [dataLoading, setDataLoading] = useState(true);
  const [startDate, setstartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [editMode, setEditMode] = useState(false);
  const [startDateEmbar, setstartDateEmbar] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDateEmbar, setEndDateEmbar] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [startDateIneligible, setstartDateIneligible] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDateIneligible, setEndDateIneligible] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [editModeEmbar, setEditModeEmbar] = useState(false);
  const [editModeIneligible, setEditModeIneligible] = useState(false);

  const showSnackBar=(severity,message)=>{
    return setSnackBarProps({
      open: true,
      severity: severity,
      message: message,
    });
  }

  const handleApiError=(err)=>{
    console.log(err);
    setDataLoading(false);
    navigate("/error", APP_ERROR_CODES.apiFailed);
  }

  const onSave = () => {
    setEditMode(false);
    mulePostApiInstance({
      url: "adminDateForCentres",
      data: [
        {
          modifiedBy: state.userInfo.email,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          name: "checkpoint",
        },
      ],
    })
      .then((_resp) => {
        showSnackBar("success","Dates updated successfully",
        );
      })
      .catch((err) => {
        console.log(err);
        showSnackBar("error", "Something went wrong. Please try again.");
      });
  };

  const onSaveEmbar = () => {
    setEditModeEmbar(false);
    mulePostApiInstance({
      url: "adminDateForCentres",
      data: [
        {
          modifiedBy: state.userInfo.email,
          startDate: dayjs(startDateEmbar).format("YYYY-MM-DD"),
          endDate: dayjs(endDateEmbar).format("YYYY-MM-DD"),
          name: "embargo",
        },
      ],
    })
      .then((_resp) => {
        showSnackBar("success","Dates updated successfully")
      })
      .catch((err) => {
        console.log(err);
        showSnackBar("error", "Something went wrong. Please try again.");
      });
  };

  const onSaveIneligible = () => {
    setEditModeIneligible(false);
    mulePostApiInstance({
      url: "adminDateForCentres",
      data: [
        {
          modifiedBy: state.userInfo.email,
          startDate: dayjs(startDateIneligible).format("YYYY-MM-DD"),
          endDate: dayjs(endDateIneligible).format("YYYY-MM-DD"),
          name: "ineligible",
        },
      ],
    })
      .then((_resp) => {
        showSnackBar("success","Dates updated successfully")
      })
      .catch((err) => {
        console.log(err);
        showSnackBar("error", "Something went wrong. Please try again.");
      });
  };

  useEffect(() => {
    muleApiInstance({
      url: "adminDateForCentres",
      params: {
        name: "checkpoint",
      },
    })
      .then((resp) => {
        setstartDate(resp.data[0].startDate);
        setEndDate(resp.data[0].endDate);
      })
      .catch((err) => {
        return handleApiError(err)
      });
    
      muleApiInstance({
        url: "adminDateForCentres",
        params: {
          name: "embargo",
        },
      })
        .then((resp) => {
          setstartDateEmbar(resp.data[0].startDate);
          setEndDateEmbar(resp.data[0].endDate);
          setDataLoading(false);
        })
        .catch((err) => {
          return handleApiError(err)
        });


      muleApiInstance({
        url: "adminDateForCentres",
        params: {
          name: "ineligible",
        },
      })
        .then((resp) => {
          setstartDateIneligible(resp.data[0].startDate);
          setEndDateIneligible(resp.data[0].endDate);
          setDataLoading(false);
        })
        .catch((err) => {
          return handleApiError(err)
        });
  
  }, []);

  const downloadFeedbackCsv = () => {
    muleApiInstance({
      url: "feedbacks",
    })
      .then((resp) => {
        let csv = csvGenerator(resp.data,  [
          "centreName",
          "centreNo",
          "score",
          "feedback",
          "submitttedBy",
          "modifiedOn"
        ], "feedbackReport");
        const link = document.createElement("a");
        let blob = new Blob(["\ufeff", csv]);
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = `feedbackReport_${dayjs().format("DD/MM/YYYY")}.csv`; //Name the file here
        link.click();
      })
      .catch((err) => {
        return handleApiError(err)
      });
  };
  return (
    <div className="container-fluid text-start" data-testid="checkpoint-">
      <div className="py-4">
        <Link to={"/dashboard"} className="text-decoration-none d-flex">
          <ArrowBackIosRoundedIcon />
          Back to progress tracker
        </Link>
      </div>

      <div className="py-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Typography variant="h3">Configure Dates</Typography>
          </div>
          <div className="feedback-csv">
            <Typography variant="subtitle1">Feedback report</Typography>
            <Button onClick={downloadFeedbackCsv}>Download csv</Button>
          </div>
        </div>

        <div className="py-4">
          <Typography variant="h5">Checkpoint dates</Typography>
        </div>
      </div>

      {dataLoading ? (
        <div className="text-center my-5">
          {" "}
          <Loader />
        </div>
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Start date(dd/mm/yyyy)</TableCell>
                <TableCell>End date (dd/mm/yyyy)</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className="date-picker-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth className="py-3">
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          disabled={!editMode}
                          minDate={dayjs()}
                          onChange={(newValue) => {
                            setstartDate(newValue);
                          }}
                          value={startDate}
                          renderInput={(params) => <TextField {...params} />}
                          data-testid="startDatePicker"
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="date-picker-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth className="py-3">
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          disabled={!editMode}
                          minDate={dayjs().add(1, "day")}
                          value={endDate}
                          onChange={(newValue) => {
                            setEndDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          data-testid="endDatePicker"
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setEditMode(true);
                    }}
                    variant="outlined"
                    color="info"
                    size="large"
                    sx={{ marginRight: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={snackBarProps.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          onClose={() => setSnackBarProps({ open: false })}
          severity={snackBarProps.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          <strong>{snackBarProps.message}</strong>
        </MuiAlert>
      </Snackbar>

      <div className="py-3">
        <div className="py-4">
          <Typography variant="h5">Result embargo dates</Typography>
        </div>
      </div>

      {dataLoading ? (
        <Loader />
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Start date(dd/mm/yyyy)</TableCell>
                <TableCell>End date (dd/mm/yyyy)</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className="date-picker-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth className="py-3">
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          disabled={!editModeEmbar}
                          minDate={dayjs()}
                          onChange={(newValue) => {
                            setstartDateEmbar(newValue);
                          }}
                          value={startDateEmbar}
                          renderInput={(params) => <TextField {...params} />}
                          data-testid="startDatePicker"
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="date-picker-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth className="py-3">
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          disabled={!editModeEmbar}
                          minDate={dayjs().add(1, "day")}
                          value={endDateEmbar}
                          onChange={(newValue) => {
                            setEndDateEmbar(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          data-testid="endDatePicker"
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setEditModeEmbar(true);
                    }}
                    variant="outlined"
                    color="info"
                    size="large"
                    sx={{ marginRight: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      onSaveEmbar();
                    }}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={snackBarProps.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          onClose={() => setSnackBarProps({ open: false })}
          severity={snackBarProps.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          <strong>{snackBarProps.message}</strong>
        </MuiAlert>
      </Snackbar>

      <div className="py-3">
        <div className="py-4">
          <Typography variant="h5">Ineligible Candidates</Typography>
        </div>
      </div>

      {dataLoading ? (
        <Loader />
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Start date(dd/mm/yyyy)</TableCell>
                <TableCell>End date (dd/mm/yyyy)</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className="date-picker-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth className="py-3">
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          disabled={!editModeIneligible}
                          minDate={dayjs()}
                          onChange={(newValue) => {
                            setstartDateIneligible(newValue);
                          }}
                          value={startDateIneligible}
                          renderInput={(params) => <TextField {...params} />}
                          data-testid="startDatePicker"
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="date-picker-wrapper">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth className="py-3">
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          disabled={!editModeIneligible}
                          minDate={dayjs().add(1, "day")}
                          value={endDateIneligible}
                          onChange={(newValue) => {
                            setEndDateIneligible(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          data-testid="endDatePicker"
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setEditModeIneligible(true);
                    }}
                    variant="outlined"
                    color="info"
                    size="large"
                    sx={{ marginRight: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      onSaveIneligible();
                    }}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={snackBarProps.open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          onClose={() => setSnackBarProps({ open: false })}
          severity={snackBarProps.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          <strong>{snackBarProps.message}</strong>
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
export default CheckpointDateRange;
