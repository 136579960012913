import React from "react";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    Button,
    Select,
    MenuItem,
    FormControl,
    OutlinedInput,
    Typography,
    Box,
    Modal,
    TableHead,
    TableContainer,
    Paper,
    Dialog,
    Tooltip,
    useTheme,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@mui/material";
  
  const ConfirmationPop = (props) =>
  {
    const {Modal,handleWithdraw,handleCancel,handleConfirm,EligileModal,EligibleCancel,EligibleConfirm} = props;
    return (
        <>
        <Dialog
           open={Modal}
           onClose={handleWithdraw}
           maxWidth="md"
           fullWidth
         >
           <DialogTitle color = "primary" style={{fontWeight: 'bold',textAlign: 'center'}}>
           <WarningAmberIcon color = "primary" style={{fontWeight: 'bold'}}/> Are you sure you want to request a withdrawal?
           </DialogTitle>
        
           <DialogContent style={{textAlign: 'center'}}>
             Only candidates who have left the programme and will not be claiming any more units can be withdrawn. You <b>must not</b> withdraw candidates with any pending unit results or candidates who will be completing their qualification in a future year.
           </DialogContent >
            <DialogActions style={{justifyContent: 'center'}}>
            <Button onClick = {handleConfirm}>
               Confirm
              </Button>
             <Button onClick = {handleCancel}>
               Cancel
             </Button>
             </DialogActions>
          </Dialog>
          <Dialog
           open={EligileModal}
           onClose={EligibleCancel}
           maxWidth="md"
           fullWidth
         >
           <DialogTitle color = "primary" style={{fontWeight: 'bold',textAlign: 'center'}}>
           <WarningAmberIcon color = "primary" style={{fontWeight: 'bold'}}/> Are you sure you want to change the candiadate to Eligible?
           </DialogTitle>
        
           <DialogContent style={{textAlign: 'center'}}>
             Only candidates who have left the programme and will not be claiming any more units can be withdrawn. You <b>must not</b> withdraw candidates with any pending unit results or candidates who will be completing their qualification in a future year.
           </DialogContent >
            <DialogActions style={{justifyContent: 'center'}}>
            <Button onClick = {EligibleConfirm}>
               Confirm
              </Button>
             <Button onClick = {EligibleCancel}>
               Cancel
             </Button>
             </DialogActions>
          </Dialog>
        </>
    )
  }

  export default ConfirmationPop;