import React, { useEffect, useState } from "react";
import {
  APP_ERROR_CODES,
  CANDIDATES_RESULTSLIST_TABLE_HEADER,
  muleApiInstance,
} from "../../../utils/constants";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CandidateResults from "../../../components/CustomCandidateResults";
import Loader from "../../../components/Loader";
import _ from "lodash";
import ResultFilters from "../../../components/ResultsFilters";
const ResultPage = () => {
  const state = useSelector((fnState) => fnState);
  const location = useLocation();
  const { candidateDetails } = location.state||{};
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [resultTableData, setResultTableData] = useState([]);
  const [unitsFilter,setUnitsFilter]=useState({});
  const [unitFilterDropdownItems, setUnitFilterDropdownItems] = useState([]);
  const [unitAchievementDropdownItems, setunitAchievementDropdownItems] = useState([]);
 
  useEffect(() => {
    setIsLoading(true);
    muleApiInstance({
      url: "candidatesResults",
      params: {
        centreNumber: state.selectedOrg.orgId,
        rsCandidateNumber: candidateDetails.rsaCandidateNo,
        mainscheme: candidateDetails.specificationCode,
        sortFlag: 'C',
        // subjectLevel: "",
      },
    })
      .then((resp) => {
        setResultTableData(resp.data);
        const uniqueStatus = resp.data.map((item) => 
        item.finalResultStatus);    
        setUnitFilterDropdownItems(resp.data.map(item=>({unitNumber:item.unitNumber, unitAchievableName:item.unitAchievableName})));
        setunitAchievementDropdownItems( _.uniqWith(
          uniqueStatus,
        _.isEqual)   );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
      });
  }, [candidateDetails]);

  return isLoading ? (
    <div className="candidate-loader d-flex justify-content-center min-vh-100"> <Loader /></div>
    ) : (<div className='d-flex'>
      <ResultFilters defaultValue={unitsFilter} unitDropdownData={unitFilterDropdownItems} unitAchievementDropdown={unitAchievementDropdownItems} applyFilter={setUnitsFilter}/>
      <CandidateResults
    resultTableData={resultTableData}
    tableHeaders={CANDIDATES_RESULTSLIST_TABLE_HEADER}
    selectedUnits={unitsFilter}
  />
    </div>
  );
};

export default ResultPage;
