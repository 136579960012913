import React, { useState, useEffect, useRef } from "react";
import Loader from "../../../components/Loader";
import { Checkbox, Typography } from "@mui/material";
import {
  Notification,
  Button,
  Modal,
  ModalTitle,
  ModalActions,
  ModalContent,
} from "@cambridgeassessment/cambridge-ui";
import {
  muleApiInstance,
  mulePostApiInstance,
  APP_ERROR_CODES,
} from "../../../utils/constants";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { isValidCheckpointDate } from "../../../utils/utils";
import { setMainFilter,setTableData,setEoApprove }from "../../../redux/actions/actions";
import dayjs from "dayjs";
import "./eoCheckpoint.css";

const EoCheckpointModal = (props) => {
  const [open, setOpen] = useState(0);
  const eoNotificationRef = useRef();
  const [dataLoading, setDataLoading] = useState(true);
  const [postDataLoading, setPostDataLoading] = useState(false);
  const navigate = useNavigate();
  const state = useSelector((fnState) => fnState);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [checkpointConfirm, setCheckpointConfirm] = useState(false);
  const [checkpointDetails, setCheckpointDetails] = useState({});
  const MonthYrCalc = () =>
  {
    const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
    const currentYr =  new Date().getFullYear();
    const currMonth = new Date().getMonth();
    const result =  currMonth <= 8 ? `${`August ${currentYr}`}` : `${`August ${currentYr + 1}`}`
    return result;
  } 
  const getCheckpointDetails = () => {
    return muleApiInstance({
      url: "adminDateForCentres",
      params: {
        name: "checkpoint",
      },
    });
  };

  const getCheckpointStatus = () => {
    return muleApiInstance({
      url: "checkPointStatusForCentres",
      params: {
        centreId: state.selectedOrg.orgId,
      },
    });
  };

  const handleEoApprove = () =>
{
    dispatch(setEoApprove(true));
}

  const renderNotificationBody = () => {
    if (props.checkpointProps === null) {
      return `\n\nCheckpoint details for selected center not found. Please contact OCR support`;
    }
    if (submitted) {
      return `\n\nEstimated completion date declaration has been submitted by ${
        checkpointDetails.details[0].modifiedBy
      } on ${dayjs(checkpointDetails.details[0].modifiedOn).format(
        "DD MMM YYYY, HH:MM"
      )}`;
    } else {
      return (<p>Check estimated completion dates for all subjects. Exams officers should submit the declaration when all subjects have been checked. 
      This declaration is the term-time checkpoint which must be submitted by <strong>30 April.</strong></p>);
    }
  };

  const onContinue = () => {
    setPostDataLoading(true);
    mulePostApiInstance({
      url: "checkPointStatusForCentres",
      data: [
        {
          centreId: state.selectedOrg.orgId,
          modifiedBy: state.userInfo.email,
          checkPointStatus: "C",
        },
      ],
    })
      .then((_resp) => {
        setSubmitted(true);
        setPostDataLoading(false);
      })
      .catch((_err) => {
        setSubmitted("error");
        setPostDataLoading(false);
      });
  };

  const isSubmitted = () => {
    return props.checkpointProps !== null && !submitted ? false : true;
  };

  const catchApiError = (err) => {
    console.log(err);
    setDataLoading(false);
    navigate("/error", {
      state: { code: APP_ERROR_CODES.apiFailed },
    });
  };
  const renderCheckpointNotification = () => {
    let notificationTitle =  "Action Required: Estimated completion date declaration";
    let notificationVariant = "warning";
    let notificationAction = null;
    if (isSubmitted()) {
      notificationTitle = "";
      notificationVariant = "info";
    }
    if (
      props.checkpointProps !== null &&
      !submitted &&
      state.selectedOrg.role === "EXAMS_OFFICER" && 
      checkpointDetails.details[0].checkPointStatus !== 'C' 
    ) {
      notificationAction = (
        <Button
          style={{
            backgroundColor: "#bd4f00",
            padding: "12px 12px",
          }}
          onClick={() => handleEoApprove()}
        >
          View checkpoint status
        </Button>
      );
    }
    return isValidCheckpointDate(
      checkpointDetails.details.checkPointStartDate,
      checkpointDetails.details.checkPointEndDate
    ) ? (
      <div ref={eoNotificationRef} className="eo-checkpoint-wrapper">
        <Notification
          action={{
            onClick: () => {
              setOpen(1);
            },
          }}
          closeable
          size="small"
          // style={{
          //   margin: 8,
          // }}
          title={notificationTitle}
          onClose={() => {
            eoNotificationRef.current.className = "d-none";
          }}
          variant={notificationVariant}
        >
          <div className="my-2 text-start">
          <span>{renderNotificationBody()}</span>
          </div>
          {notificationAction}
        </Notification>
      </div>
    ) : null;
  };

  const renderNotificationElements = () => {
    let modalTitle = !submitted
      ? `Confirm candidate details`
      : `Checkpoint 2 has been successfully submitted`;
    let modalBody = !submitted ? (
      <>
        <ReactMarkdown
          children={`**Declaration: For all Cambridge Technicals qualifications delivered in our centre** \n\n**Please only submit this declaration when you can confirm all the steps.**\n\nI confirm on behalf of our centre that for candidates who are expecting to achieve their Cambridge Technicals qualification this summer, we have checked the Progress Tracker and: \n\n- These candidates are showing as ‘Certificating in August 2023’ \n- These candidates are showing against the expected qualification \n- Any required examined units are showing as ‘Result achieved’ or ‘Entry received’ \n- All required moderated units are showing as ‘Result achieved’, ‘Claim submitted’ or ‘Claim sampled’ \n- All ‘pending’ reduction in assessment requests are showing as ‘RIA received’. \n\n I confirm our centre: \n\n- Has checked these candidates are taking the right combination of units with the minimum number of guided learning hours to achieve the qualification \n- Has scheduled all final moderation visits \n- Has informed OCR about any candidates who are no longer able to complete their qualification this summer so their status can be amended in the Progress Tracker \n- Has contacted OCR about any candidates where units will not be achieved without additional centre and/or OCR action \n- Will respond to OCR requests for more information where candidates are at risk of a delayed or missing result.`}
        />
        <div className="d-flex align-items-center">
          <Checkbox
            checked={checkpointConfirm}
            onClick={() => setCheckpointConfirm(!checkpointConfirm)}
          />
          <Typography>
            <strong>I agree to the declaration on behalf of our centre</strong>
          </Typography>
        </div>
      </>
    ) : (
      <Typography sx={{ width: "450px" }}>
        Thank you for submitting your checkpoint 2 declaration
      </Typography>
    );

    let modalButtons = !submitted ? (
      <>
        <Button
          color="primary"
          onClick={() => {
            setOpen(0);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={onContinue}
          disabled={!checkpointConfirm || postDataLoading}
        >
          Submit
        </Button>
      </>
    ) : (
      <Button
        color="primary"
        onClick={() => {
          setOpen(0);
        }}
      >
        Back to search results
      </Button>
    );
    return { modalTitle, modalButtons, modalBody };
  };

  const renderModalBody = () => {
    return postDataLoading ? (
      <div style={{ width: 568, height: 560 }}>
        <Loader />
      </div>
    ) : (
      <div className="">{renderNotificationElements().modalBody}</div>
    );
  };

  useEffect(() => {
    let actualCheckpointDetail;
    getCheckpointStatus()
      .then((resp) => {
        actualCheckpointDetail = resp.data;
        if (resp.data.length > 0) {
          if (
            resp.data[0].checkPointStatus !== null &&
            resp.data[0].checkPointStatus === "C"
          ) {
            getCheckpointDetails()
              .then((_resp) => {
                setCheckpointDetails({
                  ...checkpointDetails,
                  details: actualCheckpointDetail,
                  submitted: true,
                });
                setSubmitted(true);
                setDataLoading(false);
              })
              .catch((err) => {
                catchApiError(err);
              });
          } else {
            getCheckpointDetails()
              .then((response) => {
                setCheckpointDetails({
                  ...checkpointDetails,
                  details: {
                    ...actualCheckpointDetail,
                    checkPointStartDate: response.data[0].startDate,
                    checkPointEndDate: response.data[0].endDate,
                  },
                  submitted: false,
                });
                setDataLoading(false);
              })
              .catch((err) => {
                catchApiError(err);
              });
          }
        } else {
          setCheckpointDetails(null);
        }
      })
      .catch((err) => {
        catchApiError(err);
      });
  }, [submitted]);

  return dataLoading ? (
    <div className="eo-checkpoint-wrapper">
      <Loader />
    </div>
  ) : (
    <>
      {renderCheckpointNotification()}
      <Modal
        onClose={() => {
          setOpen(0);
        }}
        open={open}
        size="medium"
      >
        <ModalTitle
          onClose={() => {
            setOpen(0);
          }}
        >
          {renderNotificationElements().modalTitle}
        </ModalTitle>
        <ModalContent>{renderModalBody()}</ModalContent>
        <ModalActions>{renderNotificationElements().modalButtons}</ModalActions>
      </Modal>
    </>
  );
};

export default EoCheckpointModal;
