import React from 'react'
import axios from 'axios';
import Header from '../../components/Header';
import './accessErrorPage.css'

const AccessErrorPage = () => {
    const onLoginClick=()=>{
        axios.get(`${process.env.REACT_APP_LAMBDA_URL}/login`,{
            headers:{
                Cookie: document.cookie
            }
        }).then(resp=>{
            console.log('IN LOGIN RESPONSE')
            if(resp.status===200){
            window.location=resp.request.responseURL
          }
        }).catch(e=>console.log(e))
    }
    return <div className='container-fluid p-0'>
        <Header />
        <div className='d-flex text-start access-error-page-container'>
            <div className='my-auto access-error-message'>
                <h2 className='MuiTypography-root page-title'>You need to have permission to access this site</h2>
                <p className=''>If you require access please contact OCR at <a href="mailto:support@ocr.org.uk">support@ocr.org.uk</a>.</p>
                <button type='button' className='btn btn-outline-primary page-description' onClick={onLoginClick}>Back to login</button>
            </div></div>
    </div>
}

export default AccessErrorPage;