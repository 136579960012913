import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import RuleIcon from "@mui/icons-material/Rule";
import CloudOffIcon from "@mui/icons-material/CloudOffOutlined";
import PublicOffIcon from "@mui/icons-material/PublicOffOutlined";
import NoAccountsIcon from "@mui/icons-material/NoAccountsOutlined";
import LockPersonIcon from "@mui/icons-material/LockPersonOutlined";
import { commonLabels } from "../../utils/labels";
import { GENERIC_ERROR_PAGE_MESSAGES } from "../../utils/constants";
import "./genericErrorPage.css";
import { useEffect, useState } from "react";

const GenericErrorPage = () => {
  const onLoginClick = () => {
    window.location.replace(process.env.REACT_APP_MY_CAMBRIDGE_HOMEPAGE);
  };
  const location = useLocation();
  const [renderError, setRenderError] = useState({
    icon: null,
    message: "",
  });

  const renderErrorMessages = () => {
    switch (location&&location.state&&location.state.code.code) {
      case "ctcpt-401":
        setRenderError({
          icon: <NoAccountsIcon fontSize="large" color="error" />,
          message: GENERIC_ERROR_PAGE_MESSAGES.unauthorized,
        });
        break;
      case "ctcpt-403":
        setRenderError({
          icon: <LockPersonIcon fontSize="large" color="info"/>,
          message: GENERIC_ERROR_PAGE_MESSAGES.invalidRole,
        });
        break;
      case "ctcpt-400":
        setRenderError({
          icon: <RuleIcon fontSize="large" color="info" />,
          message: GENERIC_ERROR_PAGE_MESSAGES.centerSelectionMissing,
        });
        break;
      case "ctcpt-500":
        setRenderError({
          icon: <PublicOffIcon fontSize="large" color="warning"/>,
          message: GENERIC_ERROR_PAGE_MESSAGES.dataFetchFailed,
        });
        break;
      default:
        setRenderError({
          icon: <CloudOffIcon fontSize="large" color="error"/>,
          message: GENERIC_ERROR_PAGE_MESSAGES.somethingWentWrong,
        });
        break;
    }
  };

  useEffect(() => {
    renderErrorMessages();
  }, []);

  return (
    <div className="container-fluid p-0" data-testid="genericerrorpage">
      <Header />
      <div className="d-flex text-start generic-error-page-container">
        <div className="m-auto generic-error-message">
          <h1>{renderError.icon}</h1>
          <ReactMarkdown children={renderError.message} />
          <button
            type="button"
            className="btn btn-outline-primary page-description"
            onClick={onLoginClick}
          >
            {commonLabels.myCambrigeLinkText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenericErrorPage;
