export const commonLabels = {
    portalTitle: 'Cambridge Technicals Progress Tracker',
    myAccount: 'My Account',
    signOut: 'Sign Out',
    myCambrigeLinkText: 'Go to My Cambridge',
    switchCenter: 'Switch centre',
}

export const candidateListPageLabels={
    pageTitle: `Search Results`,
    pageSubtitle: `List of candidates`,
    downloadCsv: `Download (.csv)`,
    fileLabel: `ctcptCsvExport`,
    detailedFileLabel:`ctcptDetailedCsvExport`,
    ineligibleFileLabel:`ctcptCandidatesatriskCsvExport`
}

export const landingPageLabels = {
    welcomeMessage: {
        line_one: 'Welcome to the Cambridge Technicals Progress Tracker',
        line_two: "You can use the Progress Tracker to check qualification entries (registrations), check and amend a student's estimated completion date and track the progress of students' unit achievements.",
        line_three: 'Please use the filter panel on the left to search for your candidates and click done'
    },
    filterLabels: {
        filterTitle: 'Filter by',
        registrationDate: 'Registration period (dd/mm/yyyy)',
        candidateSearch: 'Candidate',
        subject: 'Subject and level',
        qualifications: 'Qualifications',
        certificating: 'Estimated completion date',
        qualificationResult:'Qualification result status',
        unitAchivement:'Unit achievement status',
        units: 'Unit(s)',
        centre:'Centre',
        checkpointStatus: 'Checkpoint status'
    }
}

export const internalUserModalLabels={
    popupLine1:'Because you’re administering multiple centres you need to select one first.',
    popupLine2:'Later, you will be able to switch between the centres by clicking the My Account from the top right corner of the screen.',
    listHeading:'OCR',
    modalTitle:'Select centre'
}