import React from "react";
import DetailedViewTable from "../../components/DetailedViewTable";
import { CANDIDATES_DETAILEDVIEW_TABLE_HEADER } from "../../utils/constants";
const DetailedView = (props) => {
  return (
    <DetailedViewTable
      tableHeader={CANDIDATES_DETAILEDVIEW_TABLE_HEADER}
      detailsTableData={props.detailedView}
    />
  );
};
export default DetailedView;
