import React from "react";
import {useState,useEffect} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableContainer,
  Paper,
  Dialog,
  TableHead,
  Box,
  useTheme
} from "@mui/material";
import dayjs from "dayjs";
import {
  csvGenerator,
  getCheckpointStatusStyle,
  getComparator,
} from "../../utils/utils";
import EnhancedTableHead from "../EnhancedTabelHead";
import _ from "lodash";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { candidateListPageLabels } from "../../utils/labels";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {mulePostApiInstance,muleApiInstance,DEFAULT_DATE_SPAN} from "../../utils/constants";
import "./iuCertificationStatus.css";
import { margin } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';

const IuCertificationstatusTable = (props) => {
  const { tableHeaders, tableData, appliedFilters,options,selectedOption,setSelectedOption,durationValue } = props;
  const state = useSelector(fnState=>fnState)
  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("lastUpdatedTime");
  const [time,setTime] = useState(false);
  const [by,setBy] = useState([]);
  const [excelData,setExcelData] = useState([]);
  const[open,setOpen] = useState(false);
  const downloadHeaders = [
    "Centre number",
    "Candidate number",
    "OCR candidate number",
    "First name",
    "Last name",
    "Qualification",
    "Specification code",
    "Intended completion status",
    'Modified By' ,
     'Modified On',
  ];
  const historyHeaders = [
    "Centre number",
    "OCR candidate number",
    "First name",
    "Last name",
    "Qualification",
    "Specification code",
    "Intended completion status",
    "Modified By" ,
    "Modified On",
    "Status",
  ];

  useEffect( () =>
{
  handleExcel()
},[selectedOption,appliedFilters])

  const getCandidateHistory = (centreno,rsacandidateno, subjectlevel,status) => {
    setTime(true);
    setOpen(true);
    return muleApiInstance({
      url: "estimatedCompletionDateHistory",
      params: {
        centreId: centreno ? centreno : null,
        ocrCandidateNumber: rsacandidateno ? rsacandidateno : null,
        mainScheme: subjectlevel ? subjectlevel : null,
        certificationstatus: status,
        level: 'row',
        duration: null,
      },
    })
    .then((resp)  => {
       console.log(resp.data,"response");
      //  let arr = resp.data;
      setBy(resp.data)
      // console.log(final,"fetching");
    })
    .catch((err) =>
   {
  console.log(err);
  })
  };
console.log(appliedFilters,"filters")
console.log(state.status,"statusFilter")
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () =>
{
  setTime(false);
  setOpen(false);
}

const handleDateString = (str) =>
  {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    console.log(str,"string");
    const month = new Date(str).getMonth();
    const yr = new Date(str).getFullYear();
    const day = new Date(str).getDate();
    const hours = new Date(str).getHours();
    const minutes = new Date(str).getMinutes();
    const seconds = new Date(str).getSeconds();

    return `${months[month]} ${day}, ${yr} ${hours}:${minutes}:${seconds}`
  }


  const onDownloadClick = () => {
      let jsonData = tableData.map((centre, idx) => {
      const dateString = centre.lastUpdatedTime;
         const date = new Date(dateString);
         const year = date.getFullYear();
         const month = date.getMonth();
         const day = date.getDate();
         const hours = date.getHours();
         const minutes = date.getMinutes();
         const seconds = date.getSeconds();
         console.log(centre,'c')
       return (
         {centreNo : centre.centreNo,
          centreCandidateNo : centre.centreCandidateNo,
          rsaCandidateNo : centre.rsaCandidateNo,
          candidateFirstname : centre.candidateFirstname,
          candidateLastname : centre.candidateLastname,
          qualification : centre.qualification,
          specificationCode : centre.specificationCode,
          certificationStatus : centre.certificationStatus,
          lastUpdatedBy : centre.lastUpdatedBy,
          lastUpdatedTime : `${months[month]} ${day}, ${year} ${hours}:${minutes}:${seconds}`,
        }
       );
     });
    let csv = csvGenerator(jsonData, downloadHeaders, "candidateCertificationstatus");
    const link = document.createElement("a");
    let blob = new Blob([csv],{type: 'text/csv; charset=utf-8'});
    let url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `certificationstatus_${dayjs().format("DD/MM/YYYY")}.csv`; //Name the file here
    link.click();
  };
 
  const handleExcel = () =>
  {
     muleApiInstance({
      url: "estimatedCompletionDateHistory",
      params: {
        centreId:  appliedFilters ? appliedFilters?.centreId : null,
        ocrCandidateNumber: appliedFilters ? appliedFilters?.searchFieldFilter?.rsaCandidateNo : null,
        mainScheme: appliedFilters ? appliedFilters?.searchFieldFilter?.specificationCode : null,
        certificationstatus: appliedFilters ? appliedFilters.certificatingStatus : null,
        level: "all",
        duration: durationValue,
      },
    })
    .then((resp)  => {
       console.log(resp.data,"response");
       setExcelData(resp.data)
    })
    .catch((err) =>
   {
  console.log(err);
  })
  }

console.log(excelData,"ExcelData");

  const OnDownloadExcel = () =>
  {
  let jsonData = excelData.map((centre, idx) => {
    return (
      {
       centreno : centre.centreno,
       ocrcandidatenumber : centre.ocrcandidatenumber,
       firstname : centre.firstname,
       lastname : centre.lastname,
       qualification : centre.qualification,
       specificationcode : centre.specificationcode,
       intendedcompletionstatus: centre.intendedcompletionstatus,
       modifiedby : centre.modifiedby ? centre.modifiedby : '-' ,
       modifiedon: centre.modifiedon ? handleDateString(centre.modifiedon) : '-',
       status : centre.status,
     }
    );
  });
  let csv = csvGenerator(jsonData, historyHeaders, "certificationstatusHistoryHeader");
  const link = document.createElement("a");
  let blob = new Blob([csv],{type: 'text/csv; charset=utf-8'});
  let url = URL.createObjectURL(blob);
  link.href = url;
  link.download = `certificationstatus_${dayjs().format("DD/MM/YYYY")}.csv`; //Name the file here
  link.click();
}
  const handleChange = (e) => {
    console.log('chai drop',e.target.value)
      setSelectedOption(e.target.value);
  }

  
  let filteredResult = [];
 if(orderBy !== "centreCandidateNo"){
  var sort1 = (filteredResult.length > 0 ? filteredResult : tableData)
 .sort(getComparator(order,orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
 } 
 else {
var sort3 = (filteredResult.length > 0 ? filteredResult : tableData)
 .sort((a, b) => {
 const valueA = a.centreCandidateNo;
 const valueB = b.centreCandidateNo;
 return (order === "asc" ? 1 : -1) * (valueA - valueB);
}).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
 }
 console.log('order by',orderBy)
 let Tdata1 = orderBy !== "centreCandidateNo" ? sort1.map((entry,history) => ({...entry,history: "History"})) : sort3.map((entry,history) => ({...entry,history: "History"}))

 if(orderBy !== "centreCandidateNo"){
   var sort2 = (filteredResult.length > 0 ? filteredResult : tableData).sort(
  getComparator(order,orderBy)
)
}
 else {
  var sort4 = (filteredResult.length > 0 ? filteredResult : tableData)
   .sort((a, b) => {
   const valueA = a.centreCandidateNo;
   const valueB = b.centreCandidateNo;
   return (order === "asc" ? 1 : -1) * (valueA - valueB);
  }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
   }
let Tdata2 = orderBy !== "centreCandidateNo" ? sort2.map((entry,history) => ({...entry,history: "History"})) : sort4.map((entry,history) => ({...entry,history: "History"}))

  const getPagination = () => {
    if (tableData.length > 0) {
      if (_.isEmpty(appliedFilters)) {
        filteredResult = tableData;
      } else {
        filteredResult = _.filter(tableData, appliedFilters);
      }
      if (rowsPerPage > 0) {
        return Tdata1
      } else {
        return Tdata2
      }
    } else return [];
  };
  
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
 
  const tableDataContent = getPagination().map((centre, idx) => {
   const dateString = centre.lastUpdatedTime;
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const hours = date.getHours() % 12;
      // const period = hours >= 12 ? "PM" : "AM";
      // const hoursto12 = date.getHours() % 12;
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
    return (
      <TableRow key={Number(centre.rsaCandidateNo + idx)}>
      <TableCell padding="normal">{centre.centreNo}</TableCell>
        <TableCell padding="normal">{centre.centreCandidateNo !== " " || "" ? centre.centreCandidateNo : "-"}</TableCell>
        <TableCell padding="normal">{centre.rsaCandidateNo}</TableCell>
        <TableCell padding="normal">{centre.candidateFirstname}</TableCell>
        <TableCell padding="normal">{centre.candidateLastname}</TableCell>
        <TableCell padding="normal">{centre.qualification}</TableCell>
        <TableCell padding="normal">{centre.specificationCode}</TableCell>
        <TableCell padding="normal">{centre.certificationStatus}</TableCell>
        <TableCell padding="normal">
          {centre.lastUpdatedBy ? centre.lastUpdatedBy: "-"}
        </TableCell>
        <TableCell padding="normal">
          {centre.lastUpdatedTime ? `${months[month]} ${day}, ${year} ${hours ? hours : 12}:${minutes}:${seconds}` : "-"}
        </TableCell>
        <TableCell className="minWidthStatus-col">
          <Link onClick = {() => getCandidateHistory(centre.centreNo, centre.rsaCandidateNo ,centre.specificationCode,centre.certificationStatus)} className="text-decoration-none"> {centre.history} </Link>
          </TableCell> 
      </TableRow>
    );
  });

  const tableDataContentNo = (
    <TableRow>
      <TableCell colSpan={12}>
        <Typography variant="subtitle1" className="text-center py-3">
          No Results Found
        </Typography>
      </TableCell>
    </TableRow>
  );
  return (
    <div className="p-2">
      <div className="iu-checkpoint-text-section px-2">
        <Button
          variant="text"
          className="text-start"
          onClick={() => navigate(-1)}
          style={{ marginLeft: '-10px' }}
        >
          <ChevronLeftIcon />Back to progress tracker
        </Button>
        <Typography variant="h5" className="pt-3 pb-3 text-start ps-3">
          Certification status
        </Typography>
      </div>

      <div className="table-data-wrap">
      <div className="wrapper">
        <div className="download-checkpoint-csv-button">
        <Button
            disabled = {excelData !== null && excelData.length !== 0 ? false : true}
            color="primary"
            onClick={() => OnDownloadExcel()}
            size="large"
            variant="contained"
            className=""
          >
            Download History
          </Button>
          </div>
          <div className="dropdown-duration">
          <FormControl>
        <Select
          label="Select"
          onChange={handleChange}
          value={selectedOption}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>  
        </div>
        </div>

     <div style ={{position: 'relative'}}>
     <TableContainer component = {Paper}>   
     <Table aria-label="simple table" className="aaastable table-responsive">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableHeaders}
            certificationStatus={true}
          />
          <TableBody>
            {tableData.length > 0 ? tableDataContent : tableDataContentNo}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        </TableContainer> 
{time ? 
  <Dialog open ={open} onClose = {handleClose} maxWidth = "md" 
  PaperProps = {{style:{position: 'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',width:'58%'}}}>
  <Box>
  <Typography id="modal-modal-title" variant="h6" component="h2">
  <div style={{display:'flex',alignItems:'center',width:'auto'}}>  
  <span style ={{paddingLeft: '10px'}}><b>History</b></span>
   <CloseIcon style={{marginLeft : 'auto' }} onClick = {() => {handleClose()}}/>
   </div>
  </Typography>
  <TableContainer component={Paper}>
  <Table  aria-label="simple table">
  <TableHead color="primary">
    <TableRow>
      <TableCell component="th" scope="row" style={{color: "black"}}>Subject</TableCell>
      <TableCell  style ={{color:"black",textAlign:'center'}}>Qualification</TableCell>
      <TableCell  style ={{color: "black",textAlign:'center'}}>Intended completion status</TableCell>
      <TableCell align='center' style ={{color:"black}"}}>Modified by</TableCell>
      <TableCell align='center' style ={{color: "black"}}>Modified on</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {by.map((row) => (
      <TableRow
      >
        <TableCell align="center">{row.subject}</TableCell>
        <TableCell align="center">{row.schemedesc}</TableCell>
        <TableCell align="center">{row.intendedcompletionstatus}</TableCell>
        <TableCell align="center">{row.modifiedby}</TableCell>
        <TableCell align="center">{handleDateString(row.modifiedon)}</TableCell>
      </TableRow>
    ))}
 </TableBody>
</Table>
</TableContainer>
   </Box>
   </Dialog>
   :   ''}
   </div>

        <TablePagination
          component="div"
           count={filteredResult.length ? _.filter(tableData, appliedFilters).length : tableData.length }
           onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[50, 100, 150, 200]}
        />
      
    </div>
    </div>
  );
};

export default IuCertificationstatusTable;
