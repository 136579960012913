import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@cambridgeassessment/cambridge-ui";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { Link } from "react-router-dom";
import { getComparator, getStatusStyle } from "../../utils/utils";
import "./customcandidateresults.css";
import EnhancedTableHead from "../EnhancedTabelHead";
import { TablePagination, Typography } from "@mui/material";
import _ from "lodash";
const CandidateResults = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const { resultTableData } = props;
  const [resultArray, setResultArray] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - resultTableData.length)
      : 0;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getRenderingArray = () => {
    let unitFiltered = [],
      statusFiltered = [];
    if (!_.isEmpty(props.selectedUnits)) {
      if (
        props.selectedUnits &&
        props.selectedUnits.unitFilter &&
        props.selectedUnits.unitFilter.length > 0
      ) {
        unitFiltered = props.selectedUnits.unitFilter.map((unit) =>
          resultTableData.find((item) => item.unitNumber === unit)
        );
      }
      if (props.selectedUnits && props.selectedUnits.achievementStatus) {
        statusFiltered = resultTableData.filter((item) => {
          let statusVar =
            props.selectedUnits.achievementStatus === "-"
              ? " "
              : props.selectedUnits.achievementStatus;
          return item.finalResultStatus === statusVar;
        });
      }
      return _.isEmpty(props.selectedUnits.unitFilter) ||
        _.isEmpty(props.selectedUnits.achievementStatus)
        ? _.union(unitFiltered, statusFiltered)
        : _.intersection(unitFiltered, statusFiltered);
    } else {
      return resultTableData;
    }
  };

  if(orderBy !== "unitNumber"){
    var sort1 = [...resultArray].sort(getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ); 
  }else {
    var sort3 = [...resultArray].sort((a, b) => {
    const valueA = parseInt(a.unitNumber);
    const valueB = parseInt(b.unitNumber);
 if (isNaN(valueA) || isNaN(valueB)) {
  return a.unitNumber.localeCompare(b.unitNumber) * (order === "asc" ? 1 : -1);
} else {
  return (order === "asc" ? 1 : -1) * (valueA - valueB);
}
}).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  }
  let Tdata1 = orderBy !== "unitNumber" ? sort1 : sort3;

  if(orderBy !== "unitNUmber"){
    var sort2 = resultArray.sort(getComparator(order, orderBy));
  }
  else{
    var sort4 = resultArray.sort((a, b) => {
      const valueA = parseInt(a.unitNumber);
      const valueB = parseInt(b.unitNumber);
      return (order === "asc" ? 1 : -1) * (valueA - valueB);
     })
  }

  let Tdata2 = orderBy !== "unitNumber" ? sort2 : sort4;
  const getPagination = () => {
    // let finalRenderingArray;
    if (resultTableData.length > 0) {
      if (rowsPerPage > 0) {
        // finalRenderingArray = [...resultArray].sort(getComparator(order, orderBy));
        // return finalRenderingArray.slice(
        //   page * rowsPerPage,
        //   page * rowsPerPage + rowsPerPage
        // );
        return Tdata1;
      } else {
        //return resultArray.sort(getComparator(order, orderBy));
        return Tdata2;
      }
    } else return [];
  };
  
  useEffect(() => {
    setResultArray(getRenderingArray());
  }, [props]);

  return (
    <div className="container-fluid d-flex p-0">
      <div className="mx-auto container-fluid">
        <div className="candidate-details-section">
          <div>
            <Link
              to={"/dashboard/candidates"}
              className="text-decoration-none d-flex"
            >
              <ArrowBackIosRoundedIcon />
              Back to search result
            </Link>
          </div>
          <Typography variant="h4" className="py-4 px-2">{`${
            resultTableData.length > 0 && resultTableData[0].candidateFirstname
          } ${
            resultTableData.length > 0 && resultTableData[0].candidateLastname
          } - ${
            resultTableData.length > 0 && resultTableData[0].ocrCandidateNumber
          }`}</Typography>
          <Typography variant="h5" className="p-2">{`${
            resultTableData.length > 0 && resultTableData[0].qualification
          }`}</Typography>
        </div>
        <div className="table-data-wrap">
          <Typography variant="subtitle1" className="pt-3 pb-3 text-start ps-3">
            List of Units
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={props.tableHeaders}
                unitResultTable={true}
              />

              <TableBody>
                {resultArray.length > 0 ? (
                  getPagination().map((unit, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component="td" padding="normal" scope="row">
                          {unit.unitNumber}
                        </TableCell>
                        <TableCell className="light-blue-color">
                          {unit.unitAchievableName}
                        </TableCell>
                        <TableCell className="light-blue-color">
                          {`${unit.unitReferenceNumber}`}
                        </TableCell>
                        <TableCell>{unit.unitType}</TableCell>
                        <TableCell>{unit.glhUnit}</TableCell>
                        <TableCell>{unit.assessmentType}</TableCell>
                        <TableCell>
                          {getStatusStyle(unit.finalResultStatus)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Typography
                        variant="subtitle1"
                        className="text-center py-3"
                      >
                        No Results Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={getRenderingArray().length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[15, 20, 25]}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidateResults;
