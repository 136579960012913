import { useState } from "react";
import { useSelector } from "react-redux";
import { APP_ERROR_CODES, PORTAL_USER_ROLES } from "../../utils/constants";
import { Navigate, Outlet } from "react-router-dom";
import { isInternalAdmin } from "../../utils/utils";
import Header from "../../components/Header";
import InternalUserModal from "../../components/InternalUserModal";

const ProtectedRoute = () => {
  const allowedRoles = PORTAL_USER_ROLES.map((item) => item.role);
  const reduxState = useSelector((state) => state);
  const [internalAdminCenters, setInternalAdminCenters] = useState([]);
  const foundRole = allowedRoles.includes(reduxState.selectedOrg.role);
  const internalUserFlow = () => {
    if (foundRole && reduxState.selectedOrg.orgId) {
      if (isInternalAdmin(reduxState.userInfo).length > 0 && !reduxState.aliasedOrg) {
        return (
          <>
            <Header />
            <div className="modal-placeholder">
              <InternalUserModal setCenters={setInternalAdminCenters} />
            </div>
          </>
        );
      } else {
        return (
          <Outlet context={{ internalAdminCenters: internalAdminCenters }} />
        );
      }
    }
    return (
      <Navigate
        to="/error"
        state={{ code: APP_ERROR_CODES.invalidUserRole }}
        replace
      />
    );
  };
  return internalUserFlow();
};

export default ProtectedRoute;
