import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import { Notification } from "@cambridgeassessment/cambridge-ui";
import { muleApiInstance, APP_ERROR_CODES } from "../../../../utils/constants";
import Loader from "../../../../components/Loader";
import { isValidCheckpointDate } from "../../../../utils/utils";
import "./iaCheckpoint.css";

const IaCheckpointNotification = (props) => {
  const iaNotificationRef = useRef();
  const navigate = useNavigate();
  const state = useSelector((fnState) => fnState);
  const [submitted, setSubmitted] = useState(false);
  const [checkpointDetails, setCheckpointDetails] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const getCheckpointDetails = () => {
    return muleApiInstance({
      url: "adminDateForCentres",
      
        params:{
          name:'checkpoint'
        }
      
    });
  };

  const getCheckpointStatus = () => {
    return muleApiInstance({
      url: "checkPointStatusForCentres",
      params: {
        centreId: state.selectedOrg.orgId,
      },
    });
  };
  const catchApiError = (err) => {
    console.log(err);
    setDataLoading(false);
    navigate("/error", {
      state: { code: APP_ERROR_CODES.apiFailed },
    });
  };
  const renderNotificationBody = () => {
    if (props.checkpointProps === null) {
      return `\n\nCheckpoint details for selected center not found. Please contact OCR support`;
    }
    if (submitted) {
      return `\n\nCheckpoint 2 has been submitted by ${
        checkpointDetails.details[0].modifiedBy
      } on ${dayjs(checkpointDetails.details[0].modifiedOn).format(
        "DD MMM YYYY, HH:MM"
      )}`;
    } else {
      return `\n\nPlease complete the candidate data confirmation process from ${
        checkpointDetails.details.checkPointStartDate
      }
      to ${checkpointDetails.details.checkPointEndDate}${
        state.selectedOrg.role === "EXAMS_OFFICER" ? ` by clicking on` : "."
      }`;
    }
  };

  const renderCheckpointNotification = () => {
    if (
      isValidCheckpointDate(
        checkpointDetails.details.checkPointStartDate,
        checkpointDetails.details.checkPointEndDate
      )
    ) {
      return (
        <div ref={iaNotificationRef}>
          <Notification
            action={{
              onClick: () => {
                navigate("checkpoint-status");
              },
              // text:
              //   props.checkpointProps !== null &&
              //   !submitted &&
              //   state.selectedOrg.role === "INTERNAL_ADMIN"
              //     ? "checkpoint status link"
              //     : "",
            }}
            closeable
            size="small"
            style={{
              margin: 8,
            }}
            title="Notification"
            onClose={() => {
              iaNotificationRef.current.className = "d-none";
            }}
          >
            <div style={{marginTop:'6px'}}>{renderNotificationBody()}</div>
            {props.checkpointProps !== null && !submitted && state.selectedOrg.role === "INTERNAL_ADMIN"
                  ? <p onClick={ () => {
                    navigate("checkpoint-status")}
                  } style={{textDecoration:'underline',cursor:'pointer', marginTop:'2px',textAlign:'left'}}><b>checkpoint status link</b></p>
                  : ""}
          </Notification>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    let actualCheckpointDetail;
    getCheckpointStatus()
      .then((resp) => {
        actualCheckpointDetail = resp.data;
        if (resp.data.length > 0) {
          if (
            resp.data[0].checkPointStatus !== null &&
            resp.data[0].checkPointStatus === "C"
          ) {
            getCheckpointDetails()
              .then((_resp) => {
                setCheckpointDetails({
                  ...checkpointDetails,
                  details: actualCheckpointDetail,
                  submitted: true,
                });
                setSubmitted(true);
                setDataLoading(false);
              })
              .catch((err) => {
                catchApiError(err);
              });
          } else {
            getCheckpointDetails()
              .then((response) => {
                setCheckpointDetails({
                  ...checkpointDetails,
                  details: {
                    ...actualCheckpointDetail,
                    checkPointStartDate: response.data[0].startDate,
                    checkPointEndDate: response.data[0].endDate,
                  },
                  submitted: false,
                });
                setDataLoading(false);
              })
              .catch((err) => {
                catchApiError(err);
              });
          }
        } else {
          setCheckpointDetails(null);
        }
      })
      .catch((err) => {
        console.log(err);
        setDataLoading(false);
        navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
      });
  }, [submitted]);

  if (dataLoading) {
    return (
      <div style={{ transform: "scale(0.5)" }}>
        <Loader />
      </div>
    );
  } else {
    return renderCheckpointNotification();
  }
};

export default IaCheckpointNotification;
