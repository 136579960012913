import React, { useEffect, useState,useMemo } from "react";
import { useNavigate } from "react-router-dom";
import IuCertificationstatusTable from "../../../components/IuCertificationstatusTable";
import Loader from "../../../components/Loader";
import './internalAdminCertificationStatus.css'
import {
  APP_ERROR_CODES,
  CERTIFICATION_STATUS_TABLE_HEADER,
  muleApiInstance,
  DEFAULT_DATE_SPAN,
} from "../../../utils/constants";
import { useSelector } from "react-redux";
import CertificationStatusFilters from "../../../components/CertificationStatusFilters";

const InternalAdminCertificationStatus = () => {
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(true);
  const [appliedFilters, setappliedFilters] = useState({});
  const [certificatingDetails, setCertificatingDetails] = useState({});
  const [totalDetails, setTotalDetails] = useState({});
  const state = useSelector((fnState) => fnState);

  const [options,setOptions] = useState(['Select','1 day','1 week','1 month','3 months','6 months']);
  const [selectedOption,setSelectedOption] = useState(options[2]);
  console.log(appliedFilters,'fills')

  const selectedValue = useMemo(
    () => {
      const options = {
         'Select': null,
          '1 day': 1,
          '1 week': 7,
          '1 month': 30,
          '3 months': 90,
          '6 months': 180,
      };
  
      return options[selectedOption];
  },
    [selectedOption],
  ) 


  
  const getCertificationStatus = (
    centreId,
    rsaCandidateNo,
    candidateFirstname,
    candidateLastname,
    subjectlevel,
    qualification,
    certificatingStatus,
    centreCandidateNo,
    duration
  ) => {
    return muleApiInstance({
      url: "candidatesSummary",
      params: {
        centreId : centreId ? centreId : null,
        rsaCandidateNumber: rsaCandidateNo ? rsaCandidateNo : null,
        candidateFirstname: candidateFirstname ? candidateFirstname : null,
        candidateLastname : candidateLastname ? candidateLastname : null,
        subjectLevel: subjectlevel ? subjectlevel : null,
        mainscheme: qualification ? qualification : null,
        qualificationResult: certificatingStatus ? certificatingStatus : null,
        centreCandidateNo : centreCandidateNo ? parseInt(centreCandidateNo,10) : null,
        duration: selectedValue ,
      },
    });
  };


  const catchError=(err)=>{
    console.log(err);
    setDataLoading(false);
    navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
  }

  const fetchData = () =>  getCertificationStatus(
    appliedFilters.centreId ? appliedFilters.centreId : null,

    appliedFilters?.searchFieldFilter?.rsaCandidateNo ? appliedFilters?.searchFieldFilter?.rsaCandidateNo : null,

    appliedFilters?.searchFieldFilter?.candidateFirstname
      ? appliedFilters?.searchFieldFilter?.candidateFirstname
      : null,

    appliedFilters?.searchFieldFilter?.candidateLastname
      ? appliedFilters?.searchFieldFilter?.candidateLastname
      : null,

    null,
    null,

    appliedFilters.certificatingStatus
      ? appliedFilters.certificatingStatus
      : null,

    null
  )
    .then((resp) => {
      setCertificatingDetails(resp.data);
        if( !appliedFilters.centreId && !appliedFilters?.searchFieldFilter?.rsaCandidateNo && !appliedFilters?.searchFieldFilter?.candidateFirstname && !appliedFilters?.searchFieldFilter?.candidateLastname && !appliedFilters.certificatingStatus ){
        setTotalDetails(resp.data); //duration as filter and no filter for other options
      }
    
      setDataLoading(false);
    })

    .catch((err) => {
      catchError(err);
    });
  useEffect(() => {
    setDataLoading(true);
   fetchData();
    console.log("chai app", appliedFilters);
 
   
  }, [selectedValue, appliedFilters]);

console.log('certificating details',certificatingDetails)
      

  return dataLoading ? (
    <div className="d-flex checkpoint-table-loader">
      <Loader />
    </div>
  ) : (
    <>
      <div className="d-flex">
        <CertificationStatusFilters
          centersList={certificatingDetails}
          totalDetails={totalDetails}
          applyFilter={setappliedFilters}
          setSelectedOption={setSelectedOption}
        />
        <IuCertificationstatusTable
          tableData={certificatingDetails}
          tableHeaders={CERTIFICATION_STATUS_TABLE_HEADER}
          appliedFilters={appliedFilters}
          options={options}
          setSelectedOption = {setSelectedOption}
          selectedOption = {selectedOption}
          durationValue = {selectedValue}
        />
      </div>
    </>
  );
};

export default InternalAdminCertificationStatus;
