import React, { useState } from "react";
import { useSelector } from "react-redux";
import { landingPageLabels } from "../../utils/labels";
import { Button, SearchField } from "@cambridgeassessment/cambridge-ui";
import _ from "lodash";
import "./result-filter.css";
import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

const ResultFilters = (props) => {
  const state = useSelector((fnState) => fnState);
  const [suggestionOpen, setSuggestionOpen] = useState(true);
  const [suggestedSearchList, setSuggestedSearchList] = useState([]);
  const [inputValues, setInputValues] = useState({
    unitSelection: [],
    achivementStatus: "",
  });
  const [filters, setFilters] = useState({});
  const isAllSelected =
    props.unitDropdownData.length > 0 &&
    inputValues.unitSelection.length === props.unitDropdownData.length;
  

  const clearFilters = () => {
    setInputValues({ ...inputValues, unitSelection: [], achivementStatus:'' });
    setFilters({});
   };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setInputValues({
        ...inputValues,
        unitSelection:
          inputValues.unitSelection.length === props.unitDropdownData.length
            ? []
            : props.unitDropdownData.map((item) => item.unitNumber),
      });
      setFilters({
        ...filters,
        unitFilter: props.unitDropdownData.map((item) => item.unitNumber),
      });
      return;
    }
    setInputValues({ ...inputValues, unitSelection: value });
    setFilters({ ...filters, unitFilter: value });
  };

  return (
    <div className="bg-white result-filter-wrapper mv-100">
      <div className="text-start">
        <div className="my-1 p-3 border-bottom">
          <span className="typography-medium18-lato-bold">
            {state.selectedOrg
              ? `${state.selectedOrg.orgId} - ${state.selectedOrg.name}`
              : ""}
          </span>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 border-bottom">
          <span className="typography-medium18-lato-bold">
            {landingPageLabels.filterLabels.filterTitle}
          </span>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 px-3">
          <p className="typography-small-bold">
            {landingPageLabels.filterLabels.units}
          </p>
          <FormControl fullWidth>
            <Select
              displayEmpty
              multiple
              value={inputValues.unitSelection}
              onChange={handleChange}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return `Select unit(s)`;
                }
                return selected.join(", ");
              }}
              MenuProps={{
                classes: {
                  paper: "unit-select-dropdown",
                },
              }}
            >
              <MenuItem className="unit-list-item">
                <SearchField
                  dropdownVariant="suggested"
                  onChange={(input) => {
                    if (input == "") {
                      setInputValues({
                        ...inputValues,
                        searchFieldText: input,
                      });
                      delete filters.searchFieldFilter;
                      return setFilters({ ...filters });
                    }
                    setSuggestionOpen(true);
                    setInputValues({ ...inputValues, searchFieldText: input });
                    let list = props.unitDropdownData.filter(
                      (item) =>
                        item.unitnumber.toLowerCase().includes(input) ||
                        item.unitachievablename.toLowerCase().includes(input)
                    );
                    list = list.map((item) => ({
                      ...item,
                      text: `${item.unitnumber} - ${item.unitachievablename}`.trim(),
                    }));
                    setSuggestedSearchList(list);
                    setFilters({
                      ...filters,
                      searchFieldFilter: inputValues.searchFieldText,
                    });
                  }}
                  onDropdownItemSelect={(item) => {
                    setInputValues({
                      ...inputValues,
                      unitSelection: [item.split("-")[0].trim()],
                    });
                    setFilters({
                      ...filters,
                      unitFilter: [item.split("-")[0].trim()],
                    });
                    setSuggestionOpen(false);
                  }}
                  placeholder="Search units"
                  popperProps={{
                    style: {
                      height: 150,
                      width: 268,
                    },
                  }}
                  suggestedSearchResults={suggestedSearchList}
                  value={inputValues.searchFieldText}
                  open={suggestionOpen && inputValues.searchFieldText}
                />
              </MenuItem>
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      inputValues.unitSelection.length > 0 &&
                      inputValues.unitSelection.length <
                        props.unitDropdownData.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
              {props.unitDropdownData.map((option) => (
                <MenuItem key={option.unitNumber} value={option.unitNumber}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        inputValues.unitSelection.indexOf(option.unitNumber) >
                        -1
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${option.unitNumber} - ${option.unitAchievableName}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 border-bottom px-3">
          <Typography variant="subtitle1" className="mb-3">
            Unit achivement status
          </Typography>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={inputValues.achivementStatus}
              onChange={(e) => {
                setInputValues({ ...inputValues, achivementStatus: e.target.value });
                setFilters({
                  ...filters,
                  achievementStatus: e.target.value,
                });
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return `Select achievement`;
                }
                return inputValues.achivementStatus;
              }}
            >
              <MenuItem disabled value="">
                <em>Select achivement</em>
              </MenuItem>
              {props.unitAchievementDropdown.map((option) => (
                <MenuItem key={option + 1} value={option===' '?'-':option}>
                  <ListItemText primary={`${option===' '?'-':option}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="d-flex justify-content-evenly p-3 button-container">
          <Button
            color="primary"
            onClick={() => clearFilters()}
            size="large"
            style={{
              margin: 8,
            }}
            variant="outlined"
            disabled={_.isEmpty(filters)}
          >
            Clear filters
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.applyFilter(filters);
            }}
            size="large"
            style={{
              margin: 8,
            }}
            variant="contained"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ResultFilters;
