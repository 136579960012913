import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { landingPageLabels } from "../../utils/labels";
import { Button, SearchField } from "@cambridgeassessment/cambridge-ui";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import _ from "lodash";
import { useNavigate,useOutletContext } from "react-router-dom";
import "./filter.css";
import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  TextField,
  } from "@mui/material";
import { getRelevantFilters } from "../../utils/utils";
import {
  CERTIFICATION_STATUS_FILTER_OPTIONS,
  DEFAULT_DATE_SPAN,
  QUALIFICATION_STATUS_FILTER_OPTIONS,muleApiInstance,
  APP_ERROR_CODES,
  QUALIFICATION_STATUS_FILTER_OPTIONS_SUMMARY
} from "../../utils/constants";
import { setMainFilter,setLoader,setAlertClick } from "../../redux/actions/actions";

const Filters = (props) => {
  const { initialFilterData, candidateDataArray } = props;
  const state = useSelector((fnState) => fnState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outletProps = useOutletContext();
  const [load,setLoad] = useState(false);
  const [statusData,setStatusData] = useState([]);
  const getEstimatedCompletion = (
    centreId
  ) => {
    return muleApiInstance({
      url: "certificationStatusForCentres",
      params: {
        centreId: state.selectedOrg.orgId,
      },
    });
  };
  const catchApiError=(err)=>{
    console.log(err);
    navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
  }
console.log(state,'state')
  useEffect(() => {
    getEstimatedCompletion(state.selectedOrg.orgId)
      .then((resp) => {
        const uniqueStatus = Array.from(new Set(resp.data.map((candidate) => candidate.certificationstatus)));
         setStatusData(uniqueStatus.sort());
         
        
      })
      .catch((err) => {
        catchApiError(err)
      })
      .finally(() => {
        if(state.loader){
        dispatch(setLoader(false))
      }})
    },[state.loader])


  const getPersistedFilters = () => {
    return !_.isEmpty(state.mainFilter)
      ? {
          regStartDate: state.mainFilter.regStartDateFilter
            ? dayjs(state.mainFilter.regStartDateFilter)
            : dayjs(DEFAULT_DATE_SPAN.startDate),
          regEndDate: state.mainFilter.regEndDateFilter
            ? dayjs(state.mainFilter.regEndDateFilter)
            : dayjs(DEFAULT_DATE_SPAN.endDate),
          searchFieldText: state.mainFilter.searchFieldFilter
            ? state.mainFilter.searchFieldFilter
            : "",
          subjectSelection: state.mainFilter.subjectLevelFilter
            ? initialFilterData.qualifications.find(
                (item) =>
                  item.subjectlevelcode === state.mainFilter.subjectLevelFilter
              )?.subjectlevel
            : "",
          qualificationSelection: state.mainFilter.qualificationFilter
            ? initialFilterData.qualifications.find(
                (item) =>
                  item.schemecode === state.mainFilter.qualificationFilter
              )?.qualification
            : "",
          unitSelection: state.mainFilter.unitFilter
            ? state.mainFilter.unitFilter
            : [],
          unitAchievementStatus: state.mainFilter.unitAchievementStatus
            ? state.mainFilter.unitAchievementStatus
            : "",
          certificatingStatus: state.mainFilter.certificatingStatus
            ? state.mainFilter.certificatingStatus
            : "",
          qualificationStatus: state.mainFilter.qualificationStatus
            ? state.mainFilter.qualificationStatus
            : "",
        }
      : {
          regStartDate: dayjs(DEFAULT_DATE_SPAN.startDate),
          regEndDate: dayjs(DEFAULT_DATE_SPAN.endDate),
          searchFieldText: "",
          subjectSelection: "",
          qualificationSelection: "",
          unitSelection: [],
          unitAchievementStatus: "",
          certificatingStatus: "",
          qualificationStatus: "",
        };
  };

  const [inputValues, setInputValues] = useState(getPersistedFilters());
  const [prev,setPrev] = useState(getPersistedFilters())
  const [filters, setFilters] = useState(
    !_.isEmpty(state.mainFilter) ? state.mainFilter : {}
  );
  const [suggestionOpen, setSuggestionOpen] = useState(true);
  const [suggestedSearchList, setSuggestedSearchList] = useState([]);
  const [filteredSubQual, setFilteredSubQual] = useState([]);
  const isAllSelected =
    props?.unitFilterItems?.unitNumberDropdown &&
    props?.unitFilterItems?.unitNumberDropdown.length > 0 &&
    inputValues?.unitSelection?.length ===
      props?.unitFilterItems?.unitNumberDropdown?.length;

  const isResultScreen = () => {
    return /\d{8,8}$/.test(window.location.pathname); // NOSONAR
  };
  const isCheckpointScreen = () => {
    return window.location.pathname.includes("checkpoint-status"); // NOSONAR
  };
  const isCertificationStatusScreen = () => {
    return window.location.pathname.includes("certification-status"); // NOSONAR
  };
  const isIneligibleStatusScreen = () => {
    return window.location.pathname.includes("ineligible-candidate-status"); // NOSONAR
  };
  const clearFilters = (ofResult) => {
    if (ofResult) {
      setInputValues({
        ...inputValues,
        unitSelection: { unit: "" },
      });
      delete filters.unitFilter;
    } else {
      setInputValues({
        regStartDate: dayjs(DEFAULT_DATE_SPAN.startDate),
        regEndDate: dayjs(DEFAULT_DATE_SPAN.endDate),
        searchFieldText: "",
        subjectSelection: "",
        qualificationSelection: "",
        certificatingStatus: "",
        qualificationStatus: "",
        unitSelection: [],
        unitAchievementStatus: "",
      });
    
      setFilters({});
      setFilteredSubQual([]);
      props.unitFilterItems.unitAchievementDropdown = [];
    }
  };
  useEffect(()=> {
    //store current input values into a different state
    setPrev({...inputValues})
    if(state.alertClick){
 setInputValues({
    regStartDate: dayjs(DEFAULT_DATE_SPAN.startDate),
    regEndDate: dayjs(DEFAULT_DATE_SPAN.endDate),
    searchFieldText: "",
    subjectSelection: "",
    qualificationSelection: "",
    certificatingStatus: `Summer ${academicYear}`,
    qualificationStatus: "Not Eligible",
    unitSelection: [],
    unitAchievementStatus: "",
  });
  // setFilters({});
  // setFilteredSubQual([]);
}
else{
  setInputValues(prev)
}
  },[state.alertClick]);
console.log(state.statusload,"flagchecking");
console.log(statusData,"what is there?")
  const onDoneClick = (result) => {
    dispatch(setMainFilter(filters));
    dispatch(setAlertClick(false));
    
    if (isResultScreen() && result) {
      navigate(`candidates/${window.location.pathname.split("/")[3]}`, {
        state: {
          candidateDetails: {
            rsacandidateno: window.location.pathname.split("/")[3],
            specificationcode: inputValues.unitSelection.schemecode,
          },
        },
      });
    }
    if (
      window.location.pathname === "/dashboard" ||
      window.location.pathname === "/dashboard/"
    ) {
      navigate("candidates");
    }
  };
  const handleUnitFieldChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setInputValues({
        ...inputValues,
        unitSelection:
          inputValues.unitSelection.length ===
          props.unitFilterItems.unitNumberDropdown.length
            ? []
            : props.unitFilterItems.unitNumberDropdown.map(
                (item) => item.unitNumber
              ),
      });
      setFilters({
        ...filters,
        unitFilter: props.unitFilterItem.unitNumberDropdowns.map(
          (item) => item.unitNumber
        ),
      });
      return;
    }
    setInputValues({ ...inputValues, unitSelection: value });
    setFilters({ ...filters, unitFilter: value });
  };

  const getDropdownRenderValues = () => {
    if (filteredSubQual.length > 0) {
      return {
        qualArr: filteredSubQual,
        subArr: _.uniqBy(filteredSubQual, "subjectlevelcode"),
      };
    }
    return {
      qualArr: initialFilterData.qualifications,
      subArr: _.uniqBy(initialFilterData.qualifications, "subjectlevelcode"),
    };
  };

  const renderDetailedUnitFields = () => {
    if (
      inputValues?.unitSelection?.length &&
      props?.unitFilterItems?.unitNumberDropdown?.length === 1
    ) {
      return null;
    }
    return (
      <MenuItem value="all">
        <ListItemIcon>
          <Checkbox
            checked={isAllSelected}
            indeterminate={
              inputValues.unitSelection.length > 0 &&
              inputValues.unitSelection.length <
                props.unitFilterItems.unitNumberDropdown.length
            }
          />
        </ListItemIcon>
        <ListItemText primary="Select All" />
      </MenuItem>
    );
  };
  const getAcademicYear = (date) =>  {
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();
    return currentMonth >= 8 ? date.getFullYear() + 1 : date.getFullYear()
  }
  
  const today = new Date(); 
  const academicYear = getAcademicYear(today);
  console.log(academicYear); 
  return (
    <div
      className={`bg-white filter-wrapper h-100 ${
        isResultScreen() || isCheckpointScreen() || isCertificationStatusScreen() || isIneligibleStatusScreen() ? "d-none" : ""
      }`}
      data-testid="filter-component"
    >
      <div className="text-start">
        <div className="my-1 p-3 border-bottom">
          <span className="typography-medium18-lato-bold">
            {state.selectedOrg
              ? `${state.selectedOrg.orgId} - ${state.selectedOrg.name}`
              : ""}
          </span>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 border-bottom">
          <span className="typography-medium18-lato-bold">
            {landingPageLabels.filterLabels.filterTitle}
          </span>
        </div>
        {/* CERTIFICATING DROPDOWN FIELDS */}
        <div className="my-1 ps-3 pb-3 pt-2 px-3">
          <p className="typography-small-bold">
            {landingPageLabels.filterLabels.certificating}
          </p>
          <FormControl fullWidth>
          {state.ineligibleViewActive ? (
                 <TextField value={`Summer ${academicYear}`} InputProps={{
                  readOnly: true,
                }}>
                   Ineligible
                 </TextField>
            ) :
            <Select
              displayEmpty
              value={inputValues.certificatingStatus}
              onChange={(e) => {
                setInputValues({
                  ...inputValues,
                  certificatingStatus: e.target.value,
                });
                setFilters((fnFilters) => ({
                  ...fnFilters,
                  certificatingStatus: e.target.value,
                }));
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected === "") {
                  return `Select`;
                }
                return inputValues.certificatingStatus;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {statusData.map((option, idx) => (
                <MenuItem key={`certificating_${idx}`} value={option}>
                  {option}
                </MenuItem>
              ))
              }
            </Select>
          }
          </FormControl>
        </div>

        {/* QUALIFICATION RESULT DROPDOWN FIELDS */}
        {state.summaryViewActive || state.ineligibleViewActive ?
        <div className="my-1 ps-3 pb-3 pt-2 px-3">
          <p className="typography-small-bold">
            {landingPageLabels.filterLabels.qualificationResult}
          </p>
          <FormControl fullWidth>
          {state.ineligibleViewActive ? (
                 <TextField value={`Not Eligible`} InputProps={{
                  readOnly: true,
                }}>
                   Not Eligible
                 </TextField>
            ) :
            <Select
              displayEmpty
              value={inputValues.qualificationStatus}
              onChange={(e) => {
                setInputValues({
                  ...inputValues,
                  qualificationStatus: e.target.value,
                });
                setFilters((fnFilters) => ({
                  ...fnFilters,
                  qualificationStatus: e.target.value,
                }));
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected === "") {
                  return `Select`;
                }
                return inputValues?.qualificationStatus;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem> 
              {QUALIFICATION_STATUS_FILTER_OPTIONS.map((option, idx) => (
                <MenuItem key={`qualification_option_${idx}`} value={option}>
                  {option}
                </MenuItem> )) }
              
              }
            </Select>
          }
          </FormControl>
        </div> : ''}
       
        <div className="my-1 ps-3 pt-2 px-3">
          <span className="typography-small-bold">
            {landingPageLabels.filterLabels.registrationDate}
          </span>
          {/* DATE FIELDS */}
          <div className="input-group mb-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth className="py-3">
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={inputValues.regStartDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setInputValues({
                        ...inputValues,
                        regStartDate: newValue,
                      });
                      setFilters({
                        ...filters,
                        regStartDateFilter: dayjs(newValue.$d).format(
                          "YYYY-MM-DD"
                        ),
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  data-testid="startDate"
                />
              </FormControl>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth className="py-3">
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={inputValues.regEndDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      setInputValues({
                        ...inputValues,
                        regEndDate: newValue,
                      });
                      setFilters({
                        ...filters,
                        regEndDateFilter: dayjs(newValue.$d).format(
                          "YYYY-MM-DD"
                        ),
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  data-testid="EndDate"
                />
              </FormControl>
            </LocalizationProvider>

            {/* CANDIDATE SEARCH BAR */}
            <div className="candidate-search-bar py-3 pb-0">
              <p className="typography-small-bold">
                {landingPageLabels.filterLabels.candidateSearch}
              </p>
              {/* <Tooltip title={"Click on Done button to apply filter"} open={true}>  */}
              <SearchField
               data-title={`Click the done button below\n\n to apply filter`}
                dropdownVariant="suggested"
                onChange={(input) => {
                  if (input == "") {
                    setInputValues({
                      ...inputValues,
                      searchFieldText: input,
                    });
                    delete filters.searchFieldFilter;
                    setFilteredSubQual(
                      getRelevantFilters(
                        "",
                        initialFilterData.qualifications,
                        candidateDataArray,
                        true
                      ).updatedSubQual
                    );
                    return setFilters({ ...filters });
                  }
                  setSuggestionOpen(true);
                  setInputValues({ ...inputValues, searchFieldText: input });
                  let list = candidateDataArray.filter((item) => {
                    return (
                      `${item.forename} ${item.surname}`
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      item.rsacandidateno.includes(input?.toLowerCase()) ||
                      item.centrecandidateno.includes(input?.toLowerCase())
                    );
                  });
                  list = list.map((item) => ({
                    ...item,
                    text: `${item.forename} ${item.surname} (${item.rsacandidateno})`.trim(),
                  }));
                  setSuggestedSearchList(list);
                  setFilters({
                    ...filters,
                    searchFieldFilter: inputValues.searchFieldText,
                  });
                }}
                onDropdownItemSelect={(item) => {
                  let idOnly;
                  if (item.includes("(")) {
                    let number = item.split("(")[1];
                    idOnly = number.trim().replace(")", "");
                  }
                  setInputValues({ ...inputValues, searchFieldText: item });
                  setFilters({ ...filters, searchFieldFilter: idOnly });
                  setSuggestionOpen(false);
                  setSuggestedSearchList(
                    getRelevantFilters(
                      suggestedSearchList[0].schemecode,
                      initialFilterData.qualifications,
                      candidateDataArray,
                      true
                    ).updatedCandidates
                  );
                  setFilteredSubQual(
                    getRelevantFilters(
                      suggestedSearchList[0].schemecode,
                      initialFilterData.qualifications,
                      candidateDataArray,
                      true
                    ).updatedSubQual
                  );
                }}
                placeholder="Candidate name or number"
                popperProps={{
                  style: {
                    height: 150,
                  },
                }}
                suggestedSearchResults={suggestedSearchList}
                value={inputValues.searchFieldText}
                open={suggestionOpen && inputValues.searchFieldText}
                dataTestId="searchField"
              />
              {/* </Tooltip>  */}
            </div>
          </div>
        </div>

        {/* SUBJECT DROPDOWN FIELDS */}
        <div className="my-1 ps-3 pb-3 pt-2 px-3">
          <p className="typography-small-bold">
            {landingPageLabels.filterLabels.subject}
          </p>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={inputValues.subjectSelection}
              onChange={(e) => {
                setInputValues({
                  ...inputValues,
                  subjectSelection: e.target.value,
                });
                setFilters((fnFilters) => ({
                  ...fnFilters,
                  subjectLevelFilter: initialFilterData?.qualifications?.find(
                    (item) => item?.subjectlevel === e.target.value
                  )?.subjectlevelcode,
                }));
                setFilteredSubQual(
                  getRelevantFilters(
                    initialFilterData?.qualifications?.find(
                      (item) => item?.subjectlevel === e.target.value
                    )?.subjectlevelcode,
                    initialFilterData.qualifications,
                    candidateDataArray,
                    false,
                    true
                  )?.updatedSubQual
                );
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === 0 || selected === undefined) {
                  return `Select subject and level`;
                }
                return inputValues.subjectSelection;
              }}
              inputProps={{ "aria-label": "Without label" }}
              dataTestId="subjectDropdown"
            >
              <MenuItem value="">
                <em>Select subject and level</em>
              </MenuItem>
              {getDropdownRenderValues().subArr.map((subjectlevel, idx) => (
                <MenuItem
                  key={`subject${idx}`}
                  value={subjectlevel.subjectlevel}
                >
                  {subjectlevel.subjectlevel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* QUALIFICATION DROPDOWN FIELDS */}
        <div className="my-1 ps-3 pb-3 pt-2 border-bottom px-3">
          <p className="typography-small-bold">
            {landingPageLabels.filterLabels.qualifications}
          </p>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={inputValues?.qualificationSelection}
              onChange={(e) => {
                setInputValues({
                  ...inputValues,
                  qualificationSelection: e.target.value,
                });
                setFilters((fnFilters) => ({
                  ...fnFilters,
                  qualificationFilter: initialFilterData?.qualifications?.find(
                    (item) => item?.qualification === e.target.value
                  )?.schemecode,
                }));
                setFilteredSubQual(
                  getRelevantFilters(
                    initialFilterData?.qualifications?.find(
                      (item) => item?.qualification === e.target.value
                    )?.schemecode,
                    initialFilterData?.qualifications,
                    candidateDataArray
                  )?.updatedSubQual
                );
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === 0 || selected === undefined) {
                  return `Select qualification`;
                }
                return inputValues.qualificationSelection;
              }}
              inputProps={{ "aria-label": "Without label" }}
              dataTestId="qualificationDropdown"
            >
              <MenuItem value="">
                <em>Select qualification</em>
              </MenuItem>
              {getDropdownRenderValues().qualArr.map((qualification, idx) => (
                <MenuItem
                  key={`qualification${idx}`}
                  value={qualification.qualification}
                >
                  {qualification.qualification}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* UNITS DROPDOWN FIELDS */}
        {state.detailViewActive ? (
          <>
            <div className="my-1 ps-3 pb-3 pt-2 px-3">
              <p className="typography-small-bold">
                {landingPageLabels.filterLabels.units}
              </p>
              <FormControl fullWidth>
                <Select
                  displayEmpty
                  multiple
                  value={inputValues.unitSelection}
                  onChange={handleUnitFieldChange}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return `Select unit(s)`;
                    }
                    return selected.join(", ");
                  }}
                  MenuProps={{
                    classes: {
                      paper: "unit-select-dropdown",
                    },
                  }}
                >
                  <MenuItem className="unit-list-item">
                    <SearchField
                      dropdownVariant="suggested"
                      onChange={(input) => {
                        if (input == "") {
                          setInputValues({
                            ...inputValues,
                            unitSearchFieldText: input,
                          });
                          delete filters.searchFieldFilter;
                          return setFilters({ ...filters });
                        }
                        setSuggestionOpen(true);
                        setInputValues({
                          ...inputValues,
                          unitSearchFieldText: input,
                        });
                        let list =
                          props.unitFilterItems.unitNumberDropdown.filter(
                            (item) =>
                              item.unitNumber.toLowerCase().includes(input) ||
                              item.unitAchievableName
                                .toLowerCase()
                                .includes(input)
                          );
                        list = list.map((item) => ({
                          ...item,
                          text: `${item.unitNumber} - ${item.unitAchievableName}`.trim(),
                        }));
                        setSuggestedSearchList(list);
                        setFilters({
                          ...filters,
                          searchFieldFilter: inputValues.unitSearchFieldText,
                        });
                      }}
                      onDropdownItemSelect={(item) => {
                        setInputValues({
                          ...inputValues,
                          unitSelection: [item.split("-")[0].trim()],
                        });
                        setFilters({
                          ...filters,
                          unitFilter: [item.split("-")[0].trim()],
                        });
                        setSuggestionOpen(false);
                      }}
                      placeholder="Search units"
                      popperProps={{
                        style: {
                          height: 150,
                          width: 268,
                        },
                      }}
                      suggestedSearchResults={suggestedSearchList}
                      value={inputValues.unitSearchFieldText}
                      open={suggestionOpen && inputValues.unitSearchFieldText}
                    />
                  </MenuItem>

                  {renderDetailedUnitFields()}
                  {props.unitFilterItems.unitNumberDropdown.map((option) => (
                    <MenuItem key={option.unitNumber} value={option.unitNumber}>
                      <ListItemIcon>
                        <Checkbox
                          checked={
                            inputValues.unitSelection.indexOf(
                              option.unitNumber
                            ) > -1
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${option.unitNumber} - ${option.unitAchievableName}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* UNIT ACHIVEMENT DROPDOWN FIELDS */}
            <div className="my-1 ps-3 pb-3 pt-2 px-3">
              <p className="typography-small-bold">
                {landingPageLabels.filterLabels.unitAchivement}
              </p>
              <FormControl fullWidth>
                <Select
                  displayEmpty
                  value={inputValues.unitAchievementStatus}
                  onChange={(e) => {
                    setInputValues({
                      ...inputValues,
                      unitAchievementStatus: e.target.value,
                    });
                    setFilters((fnFilters) => ({
                      ...fnFilters,
                      unitAchievementStatus: e.target.value,
                    }));
                  }}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return `Select`;
                    }
                    return inputValues.unitAchievementStatus;
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {props.unitFilterItems.unitAchievementDropdown.map(
                    (achievement, idx) => (
                      <MenuItem
                        key={`certificating_${idx}`}
                        value={achievement}
                      >
                        {achievement}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
          </>
        ) : null}

        <div className="d-flex justify-content-evenly p-3 button-container">
          <Button
            color="primary"
            onClick={() => clearFilters()}
            size="large"
            style={{
              margin: 8,
            }}
            variant="outlined"
            disabled={_.isEmpty(filters)}
            dataTestId="clearFilter"
          >
            Clear filters
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.applyFilter(filters);
              onDoneClick();
            }}
            size="large"
            style={{
              margin: 8,
            }}
            variant="contained"
            data-testid="donesubmitfilter"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Filters;
