import {
  SET_USER_INFO,
  SET_ORG_SELECTION,
  SET_ALIAS_ORG,
  SET_TAB_VALUE,
  SET_SUMMARY_VIEW_ACTIVE,
  SET_DETAIL_VIEW_ACTIVE,
  SET_INELIGIBLE_VIEW_ACTIVE,
  SET_MAIN_FILTER,
  SET_FEEDBACK,
  SET_TABLE_DATA,
  SET_CERTIFICATING_FILTER,
  SET_INELIGIBLE_FILTER,
  SET_LOADER,
  SET_APPROVE,
  SET_EOAPPROVE,
  SET_STATUS,
  SET_ALERT_CLICK,
  SET_INELIGIBLE_COUNT
} from "../actions/actionType";
const initialState = {
  userInfo: null,
  selectedOrg: null,
  aliasedOrg: null,
  tabValue : "1",
  summaryViewActive:true,
  detailViewActive: false,
  ineligibleViewActive: false,
  mainFilter:{},
  certificatingFilter:{},
 ineligibleFilter:{},
  ineligibleCount:[],
  feedback:false,
  tabData:[{}],
  loader:false,
  appr: false,
  eoappr: false,
  status:"",
  alertClick:false,
};

export const rootReducer = (state = initialState, action) => { // NOSONAR 
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case SET_ORG_SELECTION:
      return {
        ...state,
        selectedOrg: action.payload,
         statusload:true
      };
    case SET_ALIAS_ORG:
      return {
        ...state,
        aliasedOrg: action.payload,
      };
    case SET_TAB_VALUE:
      return {
        ...state,
        tabValue: action.payload,
      };
      case SET_SUMMARY_VIEW_ACTIVE:
      return {
        ...state,
        summaryViewActive: action.payload,
      };
    case SET_DETAIL_VIEW_ACTIVE:
      return {
        ...state,
        detailViewActive: action.payload,
      };
    case SET_INELIGIBLE_VIEW_ACTIVE:
      return {
        ...state,
        ineligibleViewActive: action.payload,
      };
    case SET_MAIN_FILTER:
      return {
        ...state,
        mainFilter: {...action.payload},
      };
    case SET_APPROVE:
      return {
        ...state,
        appr: action.payload,
      };
    case SET_EOAPPROVE:
      return {
        ...state,
        eoappr: action.payload,
      };
      case SET_CERTIFICATING_FILTER:
      return {
        ...state,
        certificatingFilter: {...action.payload},
      };
      case SET_INELIGIBLE_FILTER:
      return {
        ...state,
        ineligibleFilter: {...action.payload},
      };
      case SET_INELIGIBLE_COUNT:
      return {
        ...state,
        ineligibleCount: action.payload,
      };
    case SET_FEEDBACK:
      return {
        ...state,
        feedback:action.payload
      }
      case SET_STATUS:
      return {
        ...state,
        status:action.payload
      }
      case SET_TABLE_DATA:
      return{
        ...state,
        tabData: action.payload
      }
      case SET_LOADER:
      return{
        ...state,
        loader: action.payload
      }
      case SET_ALERT_CLICK:
      return{
        ...state,
        alertClick: action.payload
      }
    default:
      return state;
  }
};
