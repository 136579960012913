import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { landingPageLabels } from "../../utils/labels";
import { Button, SearchField } from "@cambridgeassessment/cambridge-ui";
import { useNavigate,useOutletContext } from "react-router-dom";
import _ from "lodash";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import {DEFAULT_DATE_SPAN,muleApiInstance,
    APP_ERROR_CODES } from "../../utils/constants";
import { getRelevantFilters } from "../../utils/utils";
import { setIneligibleFilter } from "../../redux/actions/actions";

const IneligibleCandidateStatusFilters = (props) => {
  const { initialFilterData, candidateDataArray } = props;
  const [candidateData, setCandidateData] = useState();
  const navigate = useNavigate();
  const outletProps = useOutletContext();
  const [suggestionOpenCenter, setSuggestionOpenCenter] = useState(true);
  const [suggestionOpenCandidate, setSuggestionOpenCandidate] = useState(true);
  const [suggestedSearchList, setSuggestedSearchList] = useState([]);
  const state = useSelector((fnState) => fnState);
  const isRsaEmpty = useRef(false)
  const dispatch = useDispatch();

  useEffect(() => {
  setCandidateData(props.totalDetails);
  if(state?.ineligibleFilter?.searchFieldFilter?.ocrcandidatenumber){
  let item = props?.centersList?.filter(x => x?.ocrcandidatenumber == state?.ineligibleFilter?.searchFieldFilter?.ocrcandidatenumber.toString().trim())[0];
  console.log(item,"typeof");
  let rsaInput =  item !== undefined ? `${item?.firstname} ${item?.lastname} (${item?.ocrcandidatenumber})`: state?.ineligibleFilter?.searchFieldFilter?.ocrcandidatenumber;
  if(rsaInput){
    setInputValues(prev => ({...prev,searchFieldText: rsaInput}))
  }
    }},[])
  const getPersistedFilters = () => {
    console.log(state.ineligibleFilter,'state values')
    return !_.isEmpty(state.ineligibleFilter)
      ? {
        centreId: state.ineligibleFilter.centreId 
          ? state.ineligibleFilter.centreId
          : "",
          searchFieldText: state?.ineligibleFilter?.searchFieldFilter ? Object.keys(state?.ineligibleFilter?.searchFieldFilter)?.length > 3 
          ? `${state.ineligibleFilter.searchFieldFilter.candidateFirstname} ${state.ineligibleFilter.searchFieldFilter?.candidateLastname} (${state.ineligibleFilter.searchFieldFilter?.ocrcandidatenumber})`
          : "" : "",
          }
          : {
            centreId:"",
            searchFieldText: "",
          };
        };

   const [inputValues, setInputValues] = useState(getPersistedFilters());
   console.log(inputValues,state.ineligibleFilter,'input values')
  const [filters, setFilters] = useState(
    !_.isEmpty(state.ineligibleFilter) ? state.ineligibleFilter : {}
  );
  const [filteredSubQual, setFilteredSubQual] = useState([]);
  
  const clearFilters = () => {
    setInputValues({ ...inputValues, centreId: '',searchFieldText: "" });
    setFilters({});
    dispatch(setIneligibleFilter({}));
   };

  return (
    <div className="bg-white checkpoint-filter-wrapper">
      <div className="text-start">
        <div className="my-1 ps-3 pb-3 pt-2 border-bottom">
          <span className="typography-medium18-lato-bold">
            {landingPageLabels.filterLabels.centre}
          </span>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 px-3">
          <FormControl fullWidth>
          <SearchField
                dropdownVariant="suggested"
                onChange={(input) => {
                  if (input == "") {
                    setInputValues({
                      ...inputValues,
                      centreId: input,
                    });
                    delete filters.centreId;
                    setSuggestionOpenCenter(false);
                  setSuggestionOpenCandidate(false);
                  }
                  setSuggestionOpenCenter(true);
                  setSuggestionOpenCandidate(false);
                  setInputValues({ ...inputValues, centreId: input });
                  let list = props.totalDetails.filter((item) => {
                    return item.centreno.includes(input?.toLowerCase())
                  });
                  list = list.map((item) => ({
               text: `${item.centreno}`.trim(),})).filter((item, index, self) => 
               index === self.findIndex((t) => t.text === item.text)
                   )
  .map((item) => ({text:item.text}));
  console.log(filters,'fill')
                  setSuggestedSearchList(list);
                  isRsaEmpty.current =! list.length 
                  console.log(suggestedSearchList,'suggest')
                  setFilters({
                    ...filters,
                    centreId: input.trim(),
                  });
                  // setFilters({
                  //   ...filters,
                  //   centreId: inputValues.centreId,
                  // });
                }}
                onDropdownItemSelect={(item) => {
                   setInputValues({ ...inputValues, centreId: item });
                  setFilters({ ...filters, centreId: item.trim() });
                  setSuggestionOpenCenter(false);              
                }}
                onClear={()=>{
                  setInputValues({ ...inputValues, centreId: '' });
                  delete filters.centreId
                }}
                placeholder="Centre number"
                popperProps={{
                  style: {
                    height: 150,
                    width:280
                  },
                }}
                
                suggestedSearchResults={suggestedSearchList}
                value={inputValues.centreId}
                open={suggestionOpenCenter && inputValues.centreId}
                dataTestId="searchField"
              />
          </FormControl>
        </div>
        <div className="candidate-search-bar my-1 ps-3 pb-3 pt-2 px-3">
              <p className="typography-small-bold">
                {landingPageLabels.filterLabels.candidateSearch}
              </p>
              {/* <Tooltip title="Click on Done button to apply filter" placement="right-start" PopperProps={{sx:{marginLeft:12}}} open={true}> */}
              <div>
              <FormControl>
              <SearchField
                dropdownVariant="suggested"
                onChange={(input) => {
                  if (input == "" || input.length < 3) {
                    setInputValues({
                      ...inputValues,
                      searchFieldText: input,
                    });
                    delete filters.searchFieldFilter;
                    setSuggestionOpenCenter(false);
                  setSuggestionOpenCandidate(false);
                  return false;
                  }
                  setSuggestionOpenCenter(false);
                  setSuggestionOpenCandidate(true);
                  setInputValues({ ...inputValues, searchFieldText: input });
                  console.log(input,'chai input')
                  let list = candidateData.filter((item) => {
                    return (
                      `${item.firstname} ${item.lastname}`
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      item.ocrcandidatenumber.includes(input.toLowerCase())
                    );
                    
                  }).slice(0,10);

                  list = list.map((item) => ({...item,text: `${item.firstname} ${item.lastname} (${item.ocrcandidatenumber})`.trim(),}))
                  .filter((item, index, self) => index === self.findIndex((t) => t.text === item.text))
                  .map((item) => ({text:item.text}));
                  console.log(filters,'fill')
                  setSuggestedSearchList(list);
                 isRsaEmpty.current =! list.length 
                  console.log(suggestedSearchList,'suggest')
                  setFilters({
                    ...filters,
                    searchFieldFilter:{...filters.searchFieldFilter,ocrcandidatenumber:input.trim()},
                  });
                }}
                onDropdownItemSelect={(item) => { 
                  let idOnly;
                  if (item.includes("(")) {
                    let number = item.split("(")[1];
                    idOnly = number.trim().replace(")", "");
                  }
                  console.log(item,'item of search')
                const selectedItem = candidateData.filter((i) => i.ocrcandidatenumber === idOnly )[0] || ''
                console.log(selectedItem,'selected')
                //console.log(searchFieldText,'search')
                const det = `${selectedItem.firstname} ${selectedItem.lastname} (${selectedItem.ocrcandidatenumber})`
                setInputValues({ ...inputValues, searchFieldText: item });               
                setFilters((fnFilters) => ({
                  ...fnFilters,
                  searchFieldFilter: selectedItem,
                }));
                 
                  setSuggestionOpenCandidate(false);
                }}
               
                onClear = {() => {
                  setInputValues({...inputValues,searchFieldFilter : ''});
                  delete filters.searchFieldFilter
                }}
                placeholder="Candidate name or number"
                popperProps={{
                  style: {
                    height: 150,
                    width:280,
                  },
                }}
              // {console.log(inputValues.searchFieldText,'search')}
                suggestedSearchResults={suggestedSearchList}
                value={inputValues.searchFieldText}
                open={suggestionOpenCandidate && inputValues.searchFieldText}
                dataTestId="searchField"
              />
              {/* </Tooltip> */}
              </FormControl>
              </div>
        </div>

        <div className="d-flex justify-content-evenly p-3 button-container">
          <Button
            color="primary"
            onClick={() => clearFilters()}
            size="large"
            style={{
              margin: 8,
            }}
            variant="outlined"
            disabled={_.isEmpty(filters)}
          >
            Clear filters
          </Button>
          <Button
            color="primary"
            onClick={() => {  
              // if(isRsaEmpty.current){
              // }
              dispatch(setIneligibleFilter(filters)); 
              console.log(filters,'incer')
              props.applyFilter(filters);
              setSuggestionOpenCenter(false);
                  setSuggestionOpenCandidate(false);
            }}
            size="large"
            style={{
              margin: 8,
            }}
            variant="contained"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
export default IneligibleCandidateStatusFilters;
