import React from "react";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableContainer,
  Paper,
  Dialog,
  TableHead,
  Box,
  useTheme,
  Pagination
} from "@mui/material";
import dayjs from "dayjs";
import {
  csvGenerator,
  getCheckpointStatusStyle,
  getComparator,checkpointsHeader,
} from "../../utils/utils";

import EnhancedTableHead from "../EnhancedTabelHead";
import _ from "lodash";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { candidateListPageLabels } from "../../utils/labels";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileExcel} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import "./iuCheckPoint.css";
import {useState,useEffect} from "react";
import { useSelector ,useDispatch} from "react-redux";
import {
  muleApiInstance,
  mulePostApiInstance,
  APP_ERROR_CODES,
} from "../../utils/constants";
import { color } from "@mui/system";



const IuCheckpointTable = (props) => {
  const { tableHeaders, tableData, appliedFilters } = props;
  const theme = useTheme();
  const [forceRender,setForceRender] = useState(false);
  const [poppage, setPopPage] = useState(0);
  const [rowsPerPopPage, setRowsPerPopPage] = useState(3);
  const [custColor,setCustColor] = useState('black')
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const state = useSelector((fnState) => fnState);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("centreName");
  const[open,setOpen] = useState(false);
  const [time,setTime] = useState(false);
  const [by,setBy] = useState([]);
  const [point,setPoint] = useState([])
  const [pg,setPg] = useState(1);
  
  const pageSize = 1;
  const handleNewChangePage = (event,newPage) =>
  {
    setPg(newPage);
  }
  const startIndex = (pg - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  // const [pageData,setPageData] = React.useState([])
  // let Tdata = tableData.map((entry,auditlog) => ({...entry,auditlog: "Auditlog"})); 
  console.log(tableData,"tabledata")
  const itemsPerPage = 100;
  const totalPages  = Math.ceil(tableData.length / itemsPerPage);
  let pageData = []
  for (let i = 0; i < totalPages ; i++)
  {
    const startIndex = i * itemsPerPage;
    const endIndex = startIndex + itemsPerPage - 1;
  const chunk = tableData.slice(startIndex,endIndex + 1).map(item => ({
  ...item,
  auditlog: 'AuditLog'}));
  pageData = pageData.concat(chunk);
 }
console.log(pageData,"pageData")
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // useEffect(() =>{
  //   const getCheckpointStatus = () => {
  //     return muleApiInstance({
  //       url: "checkPointStatusForCentresHistory",
  //       params: {
  //         centreId: state.selectedOrg.orgId,
  //       },
  //     })
  //     .then((res) =>
  //     {
  //         console.log(res.data[0].subjectAndLevel.value,"value");
  //         const jsonData = JSON.parse(res.data[0].subjectAndLevel.value)
  //         console.log(jsonData);
  //         setPoint(jsonData.subjectandlevel.map((row) => row.subjectlevel))
  //     })
  //        .catch((err)  =>
  //     {
  //         console.log(err);
  //     })
  //   };
  //   getCheckpointStatus();
  // },[]);

  console.log(point,"SubjectLevel");
  
  const handleDateString = (str) =>
  {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    console.log(str,"string");
    const month = new Date(str).getMonth();
    const yr = new Date(str).getFullYear();
    const day = new Date(str).getDate();
    const hours = new Date(str).getHours();
    const minutes = new Date(str).getMinutes();
    const seconds = new Date(str).getSeconds();

    return[`${months[month]} ${day}, ${yr} ${hours}:${minutes}`]
  }

  useEffect(() =>
{
  setForceRender(prev => !prev);
},[theme]);

let colorVar;
const handleExcelStatus = (str) =>
{
  switch(str)
  {
    case "A":
    return "Ready To Submit"
    break
    case "P":
    return "In Progress"
    break;
    case "C":
    return "Completed"
    break;
    case "I":
    return "Not Started"
    break;
    default:
    colorVar = '';
  }
}
const handleStatusText = (str) =>
{
  switch(str)
  {
    case "A":
    return <TableCell style ={{color: 'blue' }}>Ready To Submit</TableCell>
    break
    case "P":
    return <TableCell style ={{color: '#FFBF00'}}>In Progress</TableCell> 
    break;
    case "C":
    return <TableCell style ={{color: 'green'}}> Completed </TableCell>
    break;
    case "I":
    return <TableCell style ={{color: 'red'}}> Not Started </TableCell> 
    break;
    default:
    colorVar = '';
  }
}


  const getCandidateHistory = async (centreno) => {
    setTime(true);
    setOpen(true);
    return muleApiInstance({
      url: "checkPointStatusForCentresHistory",
      params: {
        centreId: centreno,
      },
    })
    .then((res) =>
    {
      console.log(res.data.subjectandlevel);
        setPoint(res.data);
    })
       .catch((err)  =>
    {
        console.log(err);
    })
    
  }
  const totalPagination = Math.ceil(point.length / pageSize);
  
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePopPage = (event, newPage) => {
    setPopPage(newPage);
  };

  const handleChangeRowsPerPopPage = (event) => {
    setRowsPerPopPage(parseInt(event.target.value));
    setPopPage(0);
  };
  const handleClose = () =>
{
  setTime(false);
  setOpen(false);
}

console.log(point.map((row) => row.checkpointstatus))

const handleViewExcel = () =>
{
  const Main = point.map(row => [
    getJson(row.subjectandlevel.value) !== null ? getJson(row.subjectandlevel.value).map(item =>item.description).join(', ') : 'Unchecked Value',
    row.checkpointstatus ? handleExcelStatus(row.checkpointstatus) : '',
    row.lastupdatedby,
    row.lastupdatedtime ? handleDateString(row.lastupdatedtime) : ''
  ]);
  const ws = XLSX.utils.aoa_to_sheet([["Subject","Status","Modified By","Modified On"],...Main]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb,ws,"Sheet1");
  const excelBuffer = XLSX.write(wb,{bookType: 'xlsx',type: 'array'});
  saveAs(new Blob([excelBuffer],{type: 'application/octet-stream'}),`AuditLog_${dayjs().format("DD/MM/YYYY")}.xlsx`);
  console.log(Main,"Excel working");
}
const getJson = (val) =>
{
 
const obj = JSON.parse(val);
console.log(obj.subjectandlevel,"obj");
return  obj.subjectandlevel.length > 0 ? obj.subjectandlevel : null;
}
  const onDownloadClick = () => {
    // const headers = ['Centre number','Centre name','Modified by','Modified on','Checkpoint status']
    // let csv = headers.join(',') +  '\r\n';
    let FormatData = tableData.map((row) => (
    {
     centreName: row.centreName,
     modifiedBy: row.modifiedBy ? row.modifiedBy : '-',
     modifiedOn: row.modifiedOn ? row.modifiedOn : '-',
     checkPointStatus: row.checkPointStatus ? handleExcelStatus(row.checkPointStatus) : '-',
     centreNo: row.centreNo,
    }
    
  ))
    console.log(FormatData,"check");
    console.log(tableData,"format");
    let jsonData = FormatData;

    // const encodedContent = new TextEncoder().encode(csv);

    let csv = csvGenerator(FormatData, checkpointsHeader, "candidateCheckpoint");
    const link = document.createElement("a");
    let blob = new Blob([csv], {type: 'text/csv; charset=utf-8'});
    let url = URL.createObjectURL(blob);
    link.setAttribute("href",url);
    link.setAttribute("download",`checkpoint_${dayjs().format("DD/MM/YYYY")}.csv`)
    document.body.appendChild(link); //Name the file here
    link.click();
    document.body.removeChild(link)
  };

  let filteredResult = [];
  const getPagination = () => {
console.log(appliedFilters,"filterdata")
console.log(pageData,"Data");
    if (pageData.length > 0) {
      if (_.isEmpty(appliedFilters)) {
        filteredResult = pageData;
      } else {
        filteredResult = _.filter(pageData, appliedFilters);
      }
      if (rowsPerPage > 0) {
        return (filteredResult.length > 0 ? filteredResult : pageData)
          .sort(getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      } else {
        return (filteredResult.length > 0 ? filteredResult : pageData).sort(
          getComparator(order, orderBy)
        );
      }
    } else return [];
  };

  const tableDataContent = getPagination().map((centre, idx) => {
    return (
      <TableRow key={Number(centre.centreName + idx)}>
        <TableCell padding="normal">{centre.centreNo}</TableCell>
        <TableCell padding="normal">{centre.centreName}</TableCell>
        <TableCell padding="normal">
          {centre.modifiedBy ? centre.modifiedBy : "-"}
        </TableCell>
        <TableCell padding="normal">
          {centre.modifiedOn ? centre.modifiedOn : "-"}
        </TableCell>
        <TableCell className="minWidthStatus-col">
          {getCheckpointStatusStyle(centre.checkPointStatus)}
        </TableCell>
        <TableCell className="minWidthStatus-col">
          <Link onClick = {() =>  getCandidateHistory(centre.centreNo)} className="text-decoration-none">{(centre.auditlog)} </Link>
        </TableCell>
      </TableRow>
    );
  });

  const tableDataContentNo = (
    <TableRow>
      <TableCell colSpan={12}>
        <Typography variant="subtitle1" className="text-center py-3">
          No Results Found
        </Typography>
      </TableCell>
    </TableRow>
  );
  return (
    <div className="p-2">
      <div className="iu-checkpoint-text-section px-2">
        <Button
          variant="text"
          className="text-start"
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon /> Back to progress tracker
        </Button>
        <Typography variant="h5" className="pt-3 pb-3 text-start ps-3">
          Checkpoint status
        </Typography>
        <Typography variant="subtitle2" className="pt-3 pb-3 text-start ps-3">
          List of centres
        </Typography>
      </div>

      <div className="table-data-wrap">
        <div className="download-checkpoint-csv-button">
          <Button
            color="primary"
            onClick={() => onDownloadClick()}
            size="large"
            variant="contained"
            className=""
          >
            {candidateListPageLabels.downloadCsv}
          </Button>
        </div>
        <div style ={{position: 'relative'}}>
        <TableContainer component = {Paper}> 
        <Table aria-label="simple table" className="aaastable table-responsive">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableHeaders}
            checkPointTable={true}
          />
          <TableBody>
            {filteredResult.length > 0 ? tableDataContent : tableDataContentNo}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        </TableContainer> 
{time ? 
  <Dialog open ={open} onClose = {handleClose} maxWidth = "auto" 
  PaperProps = {{style:{position: 'absolute',top:'50%',
  left:'50%',transform:'translate(-50%,-50%)',height:'70%',width:'60%',display:'flex',flexDirection:'column'}}}>
<Typography id="modal-modal-title" variant="h6" component="h2">
  <div style={{display:'flex',alignItems:'center'}}>  
  <span style ={{paddingLeft: '10px'}}><b>Audit Log</b></span>
   <CloseIcon style={{marginLeft : 'auto' }} onClick = {() => {handleClose()}}/>
   </div>
   </Typography>
  <TableContainer component={Paper} style={{flexGrow: 1}}>
  <Table  aria-label="simple table">
  <TableHead color="primary" style={{position: 'sticky',top:0,background: 'white',zIndex: 1}}>
    <TableRow>
      <TableCell component="th" scope="row" style={{color: "black"}}>Subject</TableCell>
      <TableCell style ={{color:"black"}}>Status</TableCell>
      <TableCell style ={{color:"black}"}}>Modified by</TableCell>
      <TableCell style ={{color: "black"}}>Modified on</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
   {point.length > 0 ? point.slice(startIndex,endIndex).map((row,index) => (
      <TableRow
      > 
      {getJson(row.subjectandlevel.value) !== null ? getJson(row.subjectandlevel.value).map((item) =>(
        <TableRow>
        <TableCell >{item.description}</TableCell>
        </TableRow>)) : <TableCell>{`Unchecked value`}</TableCell> }
        <TableCell> {row.checkpointstatus ? handleStatusText (row.checkpointstatus) : ''}</TableCell>
        <TableCell >{row.lastupdatedby}</TableCell>
        <TableCell >{row.lastupdatedtime? handleDateString(row.lastupdatedtime) : ''}</TableCell>
      </TableRow>
    )) : ''}
 </TableBody>
</Table>
</TableContainer>
<div style={{position: 'sticky',bottom:0,background: 'white',zIndex: 1}}>
<div style ={{display:'flex',padding: '10px'}}>
<FontAwesomeIcon onClick = {() => handleViewExcel()} icon={faFileExcel} size="2x" style={{color: "#06845e"}}></FontAwesomeIcon>
<Link onClick = {() => handleViewExcel()} style={{padding:'5px'}} className="text-decoration-none">View Excel</Link> 
{totalPagination <= 5 ?(
      <Pagination
        style={{marginLeft: '470px'}}
        count={Math.ceil(point.length/pageSize)}
        page={pg}
        onChange = {handleNewChangePage}
        />
  ):
  (
      <Pagination
        style={{marginLeft: '470px'}}
        count={Math.ceil(point.length/pageSize)}
        page={pg}
        onChange = {handleNewChangePage}
        siblingCount={0}
        boundaryCount={1}
        />
  )
  }
  </div>
  </div>
   </Dialog>
   :   ''}
   </div>

        <TablePagination
          component="div"
          count={filteredResult.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[50, 100, 150, 200]}
        />
      </div>
    </div>
  );
};

export default IuCheckpointTable;
