import React, { useState } from "react";
import { landingPageLabels } from "../../utils/labels";
import { Button, SearchField } from "@cambridgeassessment/cambridge-ui";
import _ from "lodash";
import "./checkpoint-filter.css";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { CHECKPOINT_STATUS_FILTER_OPTIONS } from "../../utils/constants";

const CheckpointFilters = (props) => {
  const [suggestionOpen, setSuggestionOpen] = useState(true);
  const [suggestedSearchList, setSuggestedSearchList] = useState([]);
  const [inputValues, setInputValues] = useState({
    centreNo: '',
    checkPointStatus: "",
  });
  const [filters, setFilters] = useState({});
  
  const clearFilters = () => {
    setInputValues({ ...inputValues, centreNo: '', checkPointStatus:'' });
    setFilters({});
   };

  const formatText = (val) =>
  {
    if(val === 'Completed')
    {
      return 'C'
    }
    else if(val === 'In Progress')
    {
      return 'P'
    }
    else if(val === 'Ready To Submit')
    {
      return 'A'
    }
    else{
      return 'I'
    }
  }


  return (
    <div className="bg-white checkpoint-filter-wrapper">
      <div className="text-start">
        <div className="my-1 ps-3 pb-3 pt-2 border-bottom">
          <span className="typography-medium18-lato-bold">
            {landingPageLabels.filterLabels.centre}
          </span>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 px-3">
          <p className="typography-small-bold">
            {landingPageLabels.filterLabels.checkPointStatus}
          </p>
          <FormControl fullWidth>
          <SearchField
                dropdownVariant="suggested"
                onChange={(input) => {
                  if (input == "") {
                    setInputValues({
                      ...inputValues,
                      centreNo: input,
                    });
                    delete filters.centerNo;
                  }
                  setSuggestionOpen(true);
                  setInputValues({ ...inputValues, centreNo: input });
                  let list = props.centersList.filter((item) => {
                    return (
                      `${item.centreName}`
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      item.centreNo.includes(input?.toLowerCase())
                    );
                  });
                  list = list.map((item) => ({
                    text: `${item.centreNo} - ${item.centreName}`.trim(),
                  }));
                  setSuggestedSearchList(list);
                  setFilters({
                    ...filters,
                    centreNo: inputValues.centreNo,
                  });
                }}
                onDropdownItemSelect={(item) => {
                  setInputValues({ ...inputValues, centreNo: item });
                  setFilters({ ...filters, centreNo: item.split('-')[0].trim() });
                  setSuggestionOpen(false);                 
                }}
                onClear={()=>{
                  setInputValues({ ...inputValues, centreNo: '' });
                  delete filters.centreNo
                }}
                placeholder="Centre name or number"
                popperProps={{
                  style: {
                    height: 150,
                    width:280
                  },
                }}
                
                suggestedSearchResults={suggestedSearchList}
                value={inputValues.centreNo}
                open={suggestionOpen && inputValues.centreNo}
                dataTestId="searchField"
              />
          </FormControl>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 border-bottom px-3">
          <Typography variant="subtitle1" className="mb-3">
            Checkpoint status
          </Typography>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={inputValues.checkPointStatus}
              onChange={(e) => {
                setInputValues({ ...inputValues, checkPointStatus: e.target.value });
                setFilters({
                  ...filters,
                  checkPointStatus: formatText(e.target.value),
                });
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return `All status`;
                }
                return inputValues.checkPointStatus;
              }}
            >
              <MenuItem disabled value="">
                <em>All status</em>
              </MenuItem>
              {CHECKPOINT_STATUS_FILTER_OPTIONS.map((option) => (
                <MenuItem key={option + 1} value={option}>
                  <ListItemText primary={`${option}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="d-flex justify-content-evenly p-3 button-container">
          <Button
            color="primary"
            onClick={() => clearFilters()}
            size="large"
            style={{
              margin: 8,
            }}
            variant="outlined"
            disabled={_.isEmpty(filters)}
          >
            Clear filters
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.applyFilter(filters);
            }}
            size="large"
            style={{
              margin: 8,
            }}
            variant="contained"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CheckpointFilters;
