import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Filters from "../../components/Filters";
import { Outlet, useOutletContext, useNavigate,useLocation } from "react-router-dom";
import {
  APP_ERROR_CODES,
  DEFAULT_DATE_SPAN,
  muleApiInstance,
} from "../../utils/constants";
import "./homepage.css";
import { loadDataLayer, loadGa } from "../../utils/utils";
import Feedback from "../Feedback";
import { setIneligibleFilter,setCertificatingFilter } from "../../redux/actions/actions";

const Homepage = () => {
  const state = useSelector((fnState) => fnState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outletProps = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [candidateData, setCandidateData] = useState();
  const [unitFilterItems, setUnitFilterItems] = useState({});
  const [initialFilterData, setInitialFilterData] = useState({});
  const [notificationDates, setNotificationDates] = useState(null);
  const [appliedFilters, setappliedFilters] = useState({
    regStartDateFilter: DEFAULT_DATE_SPAN.startDate,
    regEndDateFilter: DEFAULT_DATE_SPAN.endDate,
  });

  const location = useLocation();
  useEffect(()=>{
      if(!location.pathname.includes('ineligible-candidate-status')){
        dispatch(setIneligibleFilter({}));
      }
      if(!location.pathname.includes('certification-status')){
        dispatch(setCertificatingFilter({}));
      }
  },[location])

  useEffect(() => {
    if (
      state.selectedOrg.name &&
      state.selectedOrg.role &&
      state.selectedOrg.orgId
    ) {
      loadGa();
      loadDataLayer();
      window.gtag("set", "user_properties", {
        school_name: `${state.selectedOrg.name}`,
        school_ID: `${state.selectedOrg.orgId}`,
        user_role: `${state.selectedOrg.role}`,
      });
      window.gtag("event", "pageview", {
        page_location: window.location.pathname,
      });
    }
  }, [state.selectedOrg.name, state.selectedOrg.role, state.selectedOrg.orgId]);

  useEffect(() => {
    setIsLoading(true);
    let startDateSplit =
      appliedFilters && appliedFilters.regStartDateFilter
        ? appliedFilters.regStartDateFilter
        : DEFAULT_DATE_SPAN.startDate;
    let enddateSplit =
      appliedFilters && appliedFilters.regEndDateFilter
        ? appliedFilters.regEndDateFilter
        : DEFAULT_DATE_SPAN.endDate;
    let reqCandidates = muleApiInstance({
      url: "candidatesbyCentre",
      params: {
        centreId: state.selectedOrg.orgId,
        startDate: startDateSplit,
        endDate: enddateSplit,
      },
    });
    let reqLastUpdate = muleApiInstance({
      url: "lastUpdateDate",
    });
    let reqQualification = muleApiInstance({
      url: "subjectLevelAndQualification",
      params: {
        centreId: state.selectedOrg.orgId,
      },
    });
    axios
      .all([reqCandidates, reqLastUpdate, reqQualification])
      .then(
        axios.spread((...resp) => {
          if (
            (resp[0].status == 200 && resp[0].data.error) ||
            (resp[1].status == 200 && resp[1].data.error) ||
            (resp[2].status == 200 && resp[2].data.error)
          ) {
            console.log(resp[0].data.error.details, resp[0].data.error.details);
            setIsLoading(false);
            navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
          }
          setCandidateData(resp[0].data);
          setNotificationDates(resp[1].data[0]);
          setInitialFilterData((fnInitialFilterData) => ({
            ...fnInitialFilterData,
            regStartDate: DEFAULT_DATE_SPAN.startDate,
            regEndDate: DEFAULT_DATE_SPAN.endDate,
            qualifications: resp[2].data.data,
          }));
          setIsLoading(false);
        })
      )
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
      });
  }, [
    state.selectedOrg,
    appliedFilters.regStartDateFilter,
    appliedFilters.regEndDateFilter,
  ]);

  return (
    <>
      {isLoading ? (
        <div className="homepage-loader d-flex justify-content-center min-vh-100">
          <Loader />
        </div>
      ) : (
        <>
          <Header adminCenter={outletProps.internalAdminCenters} />
          <div className="homepage-wrapper">
            <div className="d-flex p-0">
              <div className="min-vh-100">
                <Filters
                  applyFilter={setappliedFilters}
                  initialFilterData={initialFilterData}
                  candidateDataArray={candidateData}
                  unitFilterItems={unitFilterItems}
                />
              </div>
              <div className="container-fluid">
                <div className="mx-auto">
                  <Outlet
                    context={{
                      welcomePageProps: notificationDates,
                      candidatePageProps: {
                        candidateApiResponse: candidateData,
                      },
                      appliedFilters: appliedFilters,
                      qualificationSubjectResponse: initialFilterData,
                      unitFilterItems: [unitFilterItems, setUnitFilterItems],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Feedback />
    </>
  );
};

export default Homepage;
