import achievedIcon from "../assets/images/achieved-icon.svg";
import eligibleIcon from "../assets/images/eligible-icon.svg";
import noteligibleIcon from "../assets/images/noteligible-icon.svg";
import pendingAssessedGradeIcon from "../assets/images/pending-assessed-grade-icon.svg";
import _ from "lodash";
import { INTERNAL_ADMIN_ORGS, PORTAL_USER_ROLES } from "./constants";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import SyncIcon from '@mui/icons-material/Sync';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const hasLoginParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.has("code");
};

export const isInternalAdmin = (userInfo) => {
  let adminOrg = userInfo.orgs.filter((item) =>
    item.sourceSystems.length > 0
      ? item.sourceSystems[0].orgId === INTERNAL_ADMIN_ORGS[0]
      : null
  );
  // let adminRoleOrg=adminOrg.length>0 && userInfo.roles.filter((item)=>item.bpid===adminOrg[0].bpid &&item.roleNames.includes('INTERNAL_ADMIN'))
  let adminRoleOrg =
    adminOrg.length > 0 &&
    userInfo.roles.filter(
      (item) =>
        item.bpid === adminOrg[0].bpid &&
        item.roleNames.includes("EXAMS_OFFICER")
    );
  return adminRoleOrg;
};

export const formatMultiRoleUser = (roles) => {
  let userOrgRoles = roles[0].roleNames;
  let foundRole = PORTAL_USER_ROLES.filter((el) => {
    return userOrgRoles.indexOf(el.role) >= 0;
  })[0];
  return foundRole;
};

export const createSelectedOrg = (userOrgDetails, roles, forAdmin) => {
  if (forAdmin && forAdmin.admin) {
    let impersonatedOrg = forAdmin.center.split("-");
    return {
      orgId: impersonatedOrg[0].trim(),
      name: impersonatedOrg[1].trim(),
      role: "INTERNAL_ADMIN",
    };
    // return {orgId: impersonatedOrg[0].trim(), name: impersonatedOrg[1].trim(), role: "INTERNAL_ADMIN"}
  } else {
    let obj = { ...userOrgDetails };
    let userRoles = roles;
    let systemId, orgId;
    // const { systemId, orgId } = userOrgDetails.sourceSystems[0];
    systemId =
      userOrgDetails.sourceSystems.length > 0
        ? userOrgDetails.sourceSystems[0].systemId
        : "";
    orgId =
      userOrgDetails.sourceSystems.length > 0
        ? userOrgDetails.sourceSystems[0].orgId
        : "";
    if (roles) {
      const selectedOrgRole = userRoles.filter(
        (role) => role.bpid === userOrgDetails.bpid
      );
      let thisRole = formatMultiRoleUser(selectedOrgRole);
      obj = { ...obj, systemId, orgId, role: thisRole ? thisRole.role : "-" };
    }
    obj = { ...obj, systemId, orgId };
    delete obj.sourceSystems;
    return obj;
  }
};

export const getOnlyNameOrNumber = (string) => {
  if (string) {
    if (string.match(/^[0-9]+$/) != null) {
      if (string.includes("(")) {
        let number = string.split("(")[1];
        return number.trim().replace(")", "");
      }
      return string;
    } else {
      let name = string.split("(")[0];
      return name.trim();
    }
  }
  return string;
};

export const createFilterableCandidateObject = (
  dataObj,
  centerSpecifications
) => {
  const candidateSpecifications = dataObj.specificationcode
    .toString()
    .split(",");
  const candidateQualifications = candidateSpecifications.map((schemeCode) => {
    return centerSpecifications.find((item) => item.schemecode == schemeCode)
      .qualification;
  });

  const candidateSubjectLevels = candidateSpecifications.map((schemeCode) => {
    return centerSpecifications.find((item) => item.schemecode == schemeCode)
      .subjectlevel;
  });
  // const subjectlevelField = centerSpecifications.find((item)=>item.schemecode===dataObj.specificationcode).subjectlevel
  return {
    ...dataObj,
    regStartDate: "01/09/2021",
    regEndDate: "31/08/2024",
    subjectlevel: candidateSubjectLevels,
    qualification: candidateQualifications,
  };
};

export const redirectToLogin = (response) => {
  if (response.status === 302) {
    window.location = response.request.responseURL;
    return;
  }
};

export const logOutOfSSO = () => {
  sessionStorage.clear();
  localStorage.clear();
  // need to change the url for logout
  window.location.assign(
    `${process.env.REACT_APP_MY_CAMBRIDGE_LOGOUT_LINK}?redirect_uri=${process.env.REACT_APP_UI_REDIRECT_LINK}&client_id=${process.env.REACT_APP_SAP_CLIENT_ID}`
  );
};

export const getCurrentPath = () => {
  return window.location.pathname;
};

export const getStatusStyle = (status) => {
  if (status?.trim() !== "") { 
    if (status?.toLowerCase() === "achieved") {
      return (
        <div style={{display:'flex', flexDirection:'column',alignItems:'flex-start'}}> 
        <img src={achievedIcon} alt="achieved Icon" width = '80px' height = '80px'/>
        <div style={{ marginLeft:6 }}>{status}</div>
          </div>
      );
    }
    else if (status?.toLowerCase() === "pending") {
      return (
        <div style={{display:'flex', flexDirection:'column',alignItems:'flex-start'}}> 
          <img src={eligibleIcon} alt="Pending Icon" width = '90px' height = '90px' />
          <div style={{ marginLeft:12 }}>{status}</div>
          </div>
      );
    } else if (status?.toLowerCase() ==="not eligible") {
      return (
        <div style={{display:'flex', flexDirection:'column',alignItems:'flex-start'}}> 
        <img src={noteligibleIcon} alt="not Eligible Icon" width = '80px' height = '80px'/>
         {status}
        </div>
      );
    }
    else if (status?.toLowerCase().includes("result achieved")) {
      return (
        <span className="success-color">
          <CheckCircleOutlineRoundedIcon sx={{ marginRight: "4px" }} />
          {status}
        </span>
      );
    }else if (!status ) {
      return '-';
    } else return (
      <span className="warn-color">
        <WarningAmberRoundedIcon sx={{ marginRight: "4px" }} />
        {status}
      </span>
    );
  }
  return '-';
};

export const getCheckpointStatusStyle = (status) => {
  if (status.trim() !== "") {
    if (status.toLowerCase().includes("c")) {
      return (
        <span className="success-color">
          <CheckCircleOutlineRoundedIcon sx={{ marginRight: "4px" }} />
          {`Completed`}
        </span>
      );
    } else if(status.toLowerCase().includes("a")) {
      return (
        <span style ={{color:'blue'}}>
          <PublishedWithChangesIcon sx={{ marginRight: "4px" }} />
          {`Ready To Submit`}
        </span>
      );
    }
    else if(status.toLowerCase().includes("p")) {
      return (
        <span style ={{color:'#FFBF00'}}>
          <SyncProblemIcon sx={{ marginRight: "4px" }} />
          {`In Progress`}
        </span>
      );
    }
    else {
      return (
        <span className="warn-color">
          <WarningAmberRoundedIcon sx={{ marginRight: "4px" }} />
          {`Not Started`}
        </span>
      );
    }
    
  }
  return;
};

export const getRelevantFilters = (
  schemeCode,
  subQualArray,
  candidateArray,
  fromSearch,
  fromSub
) => {
  let updatedSubQual;
  if (fromSearch && !schemeCode) {
    return { updatedCandidates: candidateArray, updatedSubQual: subQualArray };
  }
  schemeCode = schemeCode?.toString();
  let allschemeCodes = schemeCode?.split(",");
  let updateCadts = allschemeCodes?.map((item) =>
    candidateArray?.filter((item2) => item2?.schemecode?.includes(item))
  );
  if (fromSub) {
    updatedSubQual = allschemeCodes?.map((item) =>
      subQualArray?.filter((item2) => `${item2?.subjectlevelcode}` == item)
    );
  } else {
    updatedSubQual = allschemeCodes?.map((item) =>
      subQualArray?.filter((item2) => `${item2?.schemecode}`.includes(item))
    );
  }
  return {
    updatedCandidates: _.uniq(_.flattenDeep(updateCadts)),
    updatedSubQual: _.flattenDeep(updatedSubQual),
  };
};

export const checkpointsHeader = [
  "Centre number",
  "Centre name",
  "Modified by",
  "Modified on",
  "Checkpoint status",
];

const checkpointsHeaderMatch = [
  "centreNo",
  "centreName",
  "modifiedBy",
  "modifiedOn",
  "checkPointStatus",
];

export const preferredOrder = (obj, order) => {
  let newObject = {};
  for (let i = 0; i < order.length; i++) {
    if (obj.hasOwnProperty(order[i])) {
      newObject[order[i]] = obj[order[i]];
    }
  }
  return newObject;
};

export const csvGenerator = (jsonData, tableHeaders, forTable) => {
  // filedMapping per row for csv
  const summaryHeader = [
    "centreCandidateNo",
    "rsaCandidateNo",
    "candidateFirstname",
    "candidateLastname",
    "qualification",
    "specificationCode",
    "expectedGlh",
    "actualGlh",
    "certificationStatus",
    "resultStatus",
  ];
  const detailHeader = [
    "centreCandidateNo",
    "ocrCandidateNumber",
    "candidateFirstname",
    "candidateLastname",
    "qualification",
    "mainScheme",
    "expectedGlh",
    /*'actualGlh',*/
    "certificationStatus",
    "glhUnit",
    "unitType",
    "unitNumber",
    "unitAchievableName",
    "finalResultStatus",
  ];
  const ineligibleHeader = [
    "centreCandidateNo",
    "rsaCandidateNo",
    "candidateFirstname",
    "candidateLastname",
    "qualification",
    "specificationCode",
    "expectedGlh",
    "actualGlh",
    "resultStatus",
    "ineligiblereason",
    "eligibilityoverride",
  ];

  const ineligibleCandidateStatusHeader = [
    "centreno",
    "ocrcandidatenumber",
    "firstname",
    "lastname",
    "qualification",
    "specificationcode",
    "eligibilityStatus",
    "reason",
    "modifiedby",
    "modifiedon",
    "status",
    "eligibilityOverride",
  ];

  const certificationstatusHeader = [
    "centreNo",
    "centreCandidateNo",
    "rsaCandidateNo",
    "candidateFirstname",
    "candidateLastname",
    "qualification",
    "specificationCode",
    "certificationStatus",
    "lastUpdatedBy",
    "lastUpdatedTime",
  ];

  const certificationstatusHistoryHeader = [
    "centreno",
    "ocrcandidatenumber",
    "firstname",
    "lastname",
    "qualification",
    "specificationcode" ,
    "intendedcompletionstatus",
    "modifiedby",
    "modifiedon",
    "status",
  ];

  const feedbackHeader = [
    "centreName",
    "centreNo",
    "score",
    "feedback",
    "submitttedBy",
    "modifiedOn"
  ]
  // ordering the object key to reflect in csv
  let loopingHeader;
  if (forTable === "candidateSummary") {
    loopingHeader = summaryHeader;
  }
  if (forTable === "candidateDetailed") {
    loopingHeader = detailHeader;
  }
  if (forTable === "ineligibleCandidates") {
    loopingHeader = ineligibleHeader;
  }
  if (forTable === "candidateCheckpoint") {
    loopingHeader = checkpointsHeaderMatch;
  }
  if (forTable === "candidateCertificationstatus") {
    loopingHeader = certificationstatusHeader;
  }
  if (forTable === "certificationstatusHistoryHeader") {
    loopingHeader = certificationstatusHistoryHeader;
  }
  if (forTable === "feedbackReport") {
    loopingHeader = feedbackHeader;
  }
  if (forTable === "ineligiblecandidatestatus") {
    loopingHeader = ineligibleCandidateStatusHeader;
  }

  const items = jsonData.map((item) => preferredOrder(item, loopingHeader));

  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here

//   const csv = [tableHeaders.join(",")] // header row first
//      items.map((row) =>{
//      const rowData =  loopingHeader
//         .map((fieldName) =>
//           {
//            let fieldValue = row[fieldName] !== undefined ? row[fieldName] : '';
//            if(!isNaN(fieldValue) && fieldValue.toString().length < 5)
//            {
//              fieldValue = fieldValue.padStart(5,'0');
//            }
//           return JSON.stringify(fieldValue, replacer)
//           }).join(",")
//           csv.push(rowData)
//      })
//   return csv.join("\r\n");;
// };
const csv = [
  tableHeaders.join(","), // header row first
  ...items.map((row) =>
    loopingHeader
      .map((fieldName) =>
        JSON.stringify(row[fieldName], replacer)
      )
      .join(",")
  ),
].join("\r\n");

return csv;
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const isValidCheckpointDate=(startDate,endDate)=>{
let today=dayjs();
return today.isSameOrAfter(dayjs(startDate))&&today.isSameOrBefore(dayjs(endDate));
}

export const loadGa = (callback) => {
  const existingScript = document.getElementById('loadGtagJs');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`;
    script.id = 'loadGtagJs';
    document.body.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export const loadDataLayer = (callback) => {
  const existingScript = document.getElementById('loadDataLayer');
  if (!existingScript) {
    const script = document.createElement('script');
    script.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config','${process.env.REACT_APP_GA_ID}',{
      send_page_view: false
    });
    `;
    script.id = 'loadDataLayer';
    document.body.appendChild(script);
    script.onload = () => { 
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export const getAllTaggedCenterId=(sapUserResp)=>{
return sapUserResp.orgs.map(item=>item.sourceSystems[0].orgId)
}