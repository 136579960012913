import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IuCheckpointTable from "../../../components/IuCheckpointTable";
import Loader from "../../../components/Loader";
import './internalAdminCheckpoint.css'
import {
  APP_ERROR_CODES,
  CHECKPOINT_STATUS_TABLE_HEADER,
  muleApiInstance,
} from "../../../utils/constants";
import CheckpointFilters from "../../../components/CheckpointFilters";

const InternalAdminCheckpoint = () => {
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(true);
  const [appliedFilters, setappliedFilters] = useState({});
  const [checkpointDetails, setCheckpointDetails] = useState({});

  const getCheckpointStatus = () => {
    return muleApiInstance({
      url: "checkPointStatusForCentres",
    });
  };

  const catchError=(err)=>{
    console.log(err);
    setDataLoading(false);
    navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
  }
  useEffect(() => {
    muleApiInstance({
      url: "centres",
      params: {
        limit: 1,
        offset: 2,
      },
    })
      .then((resp) => {
        if (resp.status == 200 && resp.data.error) {
          console.log(resp.data.error.details, resp.data.error.details);
          setDataLoading(false);
          navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
        }
        setDataLoading(false);
      })
      .catch((err) => {
        catchError(err)
      });

    getCheckpointStatus()
      .then((resp) => {
        setCheckpointDetails(resp.data);
        setDataLoading(false);
      })
      .catch((err) => {
        catchError(err)
      });
  }, []);

  return dataLoading ? (
    <div className="d-flex checkpoint-table-loader">
      <Loader />
    </div>
  ) : (
    <>
      <div className="d-flex">
        <CheckpointFilters
          centersList={checkpointDetails}
          applyFilter={setappliedFilters}
        />
        <IuCheckpointTable
          tableData={checkpointDetails}
          tableHeaders={CHECKPOINT_STATUS_TABLE_HEADER}
          appliedFilters={appliedFilters}
        />
      </div>
    </>
  );
};

export default InternalAdminCheckpoint;
