import React, { useState } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { commonLabels } from "../../utils/labels";
import { useSelector, useDispatch } from "react-redux";
import OCRLogo from "../../assets/images/OCR_Logo.svg";
import { setOrgSelection, setFeedback, setTabValue, setIneligibleViewActive,setDetailViewActive, setSummaryViewActive } from "../../redux/actions/actions";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { createSelectedOrg, logOutOfSSO } from "../../utils/utils";
import {
  MyAccountDropdown,
  MenuItem,
  SearchField,
} from "@cambridgeassessment/cambridge-ui";
import * as _ from "lodash";
import "./header.css";
const Header = (props) => {
  const state = useSelector((fnState) => fnState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [suggestedCenters, setSuggestedCenters] = useState([]);
  const [searchFieldInput, setSearchFieldInput] = useState("");
  const [suggestionOpen, setSuggestionOpen] = useState(true);

  const onSignOutClick = () => {
    logOutOfSSO();
  };

  const renderCenterList = () => {
    if (props.adminCenter && props.adminCenter.length > 0) {   
      return (
        suggestedCenters.length > 0 ? suggestedCenters : props.adminCenter
      ).map((organization) => {
        return (
          <li
            key={`organization_${organization.centreno}`}
            className={`py-1 ${
              state.selectedOrg &&
              state.selectedOrg.orgId === organization.centreno
                ? "active"
                : ""
            }`}
          >
            {state.selectedOrg &&
            state.selectedOrg.orgId === organization.centreno ? (
              <span>
                <CheckRoundedIcon fontSize="small" />
              </span>
            ) : (
              <span className="invisible">
                <CheckRoundedIcon fontSize="small" />
              </span>
            )}
            <Link
              component="button"
              underline="none"
              variant="body2"
              onClick={(e) => {
                dispatch(
                  setOrgSelection(
                    createSelectedOrg(organization, state.userInfo.roles, {
                      admin: true,
                      center: e.target.innerHTML,
                    })
                  )
                );
                navigate("/dashboard");
                dispatch(setTabValue('1'));
                dispatch(setIneligibleViewActive(false));
                dispatch(setDetailViewActive(false))
                dispatch(setSummaryViewActive(true));
              }}
            >
              {organization.centreno} - {organization.centrename}
            </Link>
          </li>
        );
      });
    } else {
      return state && state.userInfo
        ? state.userInfo.orgs.map((organization) => {
            if (!organization.sourceSystems.length > 0) {
              return null;
            }
            return (
              <li
                key={`organization_${organization.bpid}`}
                className={`py-1 ${
                  state.selectedOrg &&
                  state.selectedOrg.bpid === organization.bpid
                    ? "active"
                    : ""
                }`}
              >
                {state.selectedOrg &&
                state.selectedOrg.bpid === organization.bpid ? (
                  <span>
                    <CheckRoundedIcon fontSize="small" />
                  </span>
                ) : (
                  <span className="invisible">
                    <CheckRoundedIcon fontSize="small" />
                  </span>
                )}
                <Link
                  component="button"
                  underline="none"
                  variant="body2"
                  onClick={() => {
                    dispatch(
                      setOrgSelection(
                        createSelectedOrg(organization, state.userInfo.roles)
                      )
                    );
                    navigate("/dashboard");
                    dispatch(setTabValue('1'));
                    dispatch(setIneligibleViewActive(false));
                    dispatch(setDetailViewActive(false))
                    dispatch(setSummaryViewActive(true));
                  }}
                >
                  {organization.sourceSystems.length > 0
                    ? organization.sourceSystems[0].orgId
                    : ""}{" "}
                  - {organization.name}
                </Link>
              </li>
            );
          })
        : null;
    }
  };

  const filterCenterList = () => {
    if (props.adminCenter) {
      let list = props.adminCenter.filter(
        (center) =>
          center.centreno.includes(searchFieldInput) ||
          center.centrename
            .toLowerCase()
            .includes(searchFieldInput.toLowerCase())
      );
      list = list.map((item) => ({
        ...item,
        text: `${item.centreno} - ${item.centrename}`,
      }));
      setSuggestedCenters(list);
    }
  };

  const renderSearchBar = () => {
    return (
      <SearchField
        sx={{ width: "99%" }}
        className={`py-2`}
        dropdownVariant="suggested"
        onChange={(input) => {
          setSuggestionOpen(true);
          setSearchFieldInput(input);
          filterCenterList();
        }}
        // isLoading
        onDropdownItemSelect={() => {
          setSuggestionOpen(false);
        }}
        placeholder="Search centre"
        popperProps={{
          style: {
            height: 150,
          },
        }}
        suggestedSearchResults={
          searchFieldInput && suggestedCenters ? suggestedCenters : []
        }
        value={searchFieldInput}
        open={suggestionOpen}
      />
    );
  };
  const showSearchBar = () => {
    return props.adminCenter && props.adminCenter.length > 0
      ? renderSearchBar()
      : null;
  };
  const renderAdminDropdownMenus = () => {
    if (state.selectedOrg.role === "INTERNAL_ADMIN") {
      return (
        <>
          <MenuItem
            onClick={() => {
              navigate("checkpoint-setDate");
            }}
          >
            <Link underline="none" className="my-cambrige-link">
              {"Set admin dates"}
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("checkpoint-status");
            }}
            className={"border-bottom"}
          >
            <Link underline="none" className="my-cambrige-link">
              {"Checkpoint status"}
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("certification-status");
            }}
            className={"border-bottom"}
          >
            <Link underline="none" className="my-cambrige-link">
              {"Certification status"}
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("ineligible-candidate-status");
            }}
            className={"border-bottom"}
          >
            <Link underline="none" className="my-cambrige-link">
              {"Ineligible candidate status"}
            </Link>
          </MenuItem>
        </>
      );
    }
    return null;
  };
  return (
    <header data-testid="siteHeader">
      <div className="container-fluid shadow-sm d-flex align-items-center">
        <div className="ocrLogo m-2 me-4">
          <img src={OCRLogo} alt="OCR Logo" />
        </div>
        <div className="titleDivider"></div>
        <div className="ms-4">
          <h5 className="typography-medium18-lato-bold">
            {commonLabels.portalTitle}
          </h5>
        </div>

        <div
          className="ms-auto typography-small-regular d-flex flex-row bd-highlight mb-3"
          data-testid="myAccountDropdown"
        >
            <div style={{paddingRight:'24px', paddingTop:'22px'}}>
              <Typography variant="body1" pb={3}>
                <a
                  href="https://ocr.org.uk/contact-us/"
                  target="_blank"
                  className="text-decoration-none"
                  rel="noreferrer"
                >
                  Contact us
                </a>
              </Typography>
            </div>
            <div style={{paddingRight:'24px', paddingTop:'22px'}}>
              <Typography variant="body1" pb={3}>
                <a
                  href="https://ocr.org.uk/administration/cambridge-technicals/"
                  target="_blank"
                  className="text-decoration-none"
                  rel="noreferrer"
                >
                  Administration
                </a>
              </Typography>
            </div>
            <div style={{paddingRight:'24px', paddingTop:'22px'}}>
              <Typography variant="body1" pb={3}>
                <a
                onClick={()=>{dispatch(setFeedback(true))}}
                  className="text-decoration-none pointer-evt"
                  rel="noreferrer"
                >
                  Feedback
                </a>
              </Typography>
            </div>

          {state.userInfo ? (
            <MyAccountDropdown
              onClick={() => setIsOpen(true)}
              onClose={() => setIsOpen(!isOpen)}
              className="dropdown-card"
              onSignOutClick={onSignOutClick}
              open={isOpen}
              user={{
                id: "1",
                imageSrc: "bdcdaf9f3323a4147f6d.png",
                name: `${state.userInfo && state.userInfo.name}`,
                role: `${
                  state.selectedOrg &&
                  _.startCase(
                    _.toLower(state.selectedOrg.role)
                  ) /*getUserRole()*/
                }`,
              }}
            >
              <MenuItem
                className="dropdown-card"
                divider
                style={{ paddingRight: "4px" }}
                sx={{ ":hover": { background: "none" } }}
              >
                <Grid item style={{ width: "265px" }}>
                  <span className="">{commonLabels.switchCenter}</span>
                  {showSearchBar()}
                  <ul className="list-unstyled centers-list">
                    {renderCenterList()}
                  </ul>
                </Grid>
              </MenuItem>
              {renderAdminDropdownMenus()}
              <MenuItem
                onClick={() => {
                  window.location.replace(
                    process.env.REACT_APP_MY_CAMBRIDGE_HOMEPAGE
                  );
                }}
              >
                <Link underline="none" className="my-cambrige-link">
                  {commonLabels.myCambrigeLinkText}
                </Link>
              </MenuItem>
            </MyAccountDropdown>
          ) : null}
        </div>
      </div>
    </header>
  );
};
export default Header;
