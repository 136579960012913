import React from "react";
import IneligibleCandidatesTable from "../../components/IneligibleCandidatesTable";
import { INELIGIBLE_TABLE_HEADER } from "../../utils/constants";
const IneligibleCandidatesView = (props) => {
  return (
    <IneligibleCandidatesTable
      tableHeader={INELIGIBLE_TABLE_HEADER}
      ineligibleCandidatesData={props.ineligibleView}
      getIneligibleSummary = {props.getSummary}
      outletProps = {props.outprops}

    />
  );
};
export default IneligibleCandidatesView;