export const SET_USER_INFO='SET_USER_INFO'
export const SET_ORG_SELECTION = 'SET_ORG_SELECTION'
export const SET_ALIAS_ORG = 'SET_ALIAS_ORG'
export const SET_TAB_VALUE = 'SET_TAB_VALUE'
export const SET_SUMMARY_VIEW_ACTIVE = 'SET_SUMMARY_VIEW_ACTIVE'
export const SET_DETAIL_VIEW_ACTIVE = 'SET_DETAIL_VIEW_ACTIVE'
export const SET_INELIGIBLE_VIEW_ACTIVE = 'SET_INELIGIBLE_VIEW_ACTIVE'
export const SET_MAIN_FILTER='SET_MAIN_FILTER'
export const SET_CERTIFICATING_FILTER='SET_CERTIFICATING_FILTER'
export const SET_INELIGIBLE_FILTER='SET_INELIGIBLE_FILTER'
export const SET_INELIGIBLE_COUNT='SET_INELIGIBLE_COUNT'
export const SET_FEEDBACK='SET_FEEDBACK'
export const SET_TABLE_DATA='TABLE_DATA'
export const SET_LOADER='LOADER'
export const SET_APPROVE='APPROVE'
export const SET_EOAPPROVE='EOAPPROVE'
export const SET_STATUS = 'STATUS'
export const SET_ALERT_CLICK = 'SET_ALERT_CLICK'