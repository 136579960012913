import React, { useState,useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { landingPageLabels } from "../../utils/labels";
import { Button, SearchField } from "@cambridgeassessment/cambridge-ui";
import { useNavigate,useOutletContext } from "react-router-dom";
import _ from "lodash";
import "./certificationstatus-filter.css";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Tooltip
} from "@mui/material";
import { CHECKPOINT_STATUS_FILTER_OPTIONS,DEFAULT_DATE_SPAN,muleApiInstance,
    APP_ERROR_CODES } from "../../utils/constants";
import { getRelevantFilters } from "../../utils/utils";
import { setCertificatingFilter,setStatus } from "../../redux/actions/actions";

const CertificationStatusFilters = (props) => {
  const { initialFilterData, candidateDataArray,setSelectedOption } = props;
  const [candidateData, setCandidateData] = useState();
  const [tooltipOpen,setTooltipOpen] = useState(false);
  const navigate = useNavigate();
  const outletProps = useOutletContext();
  const [suggestionOpenCenter, setSuggestionOpenCenter] = useState(true);
  const [suggestionOpenCandidate, setSuggestionOpenCandidate] = useState(true);
  const [suggestedSearchList, setSuggestedSearchList] = useState([]);
  const state = useSelector((fnState) => fnState);
  const [statusData,setStatusData] = useState([]);
  const isRsaEmpty = useRef(false)
  const dispatch = useDispatch();

  useEffect(() => {
    const uniqueStatus = Array.from(new Set(props.totalDetails.map((candidate) => candidate.certificationStatus)));
  setStatusData(uniqueStatus.sort());
  setCandidateData(props.totalDetails);
  if(state?.certificatingFilter?.searchFieldFilter?.rsaCandidateNo){
  let item = props?.centersList?.filter(x => x?.rsaCandidateNo == state?.certificatingFilter?.searchFieldFilter?.rsaCandidateNo.toString().trim())[0];
  console.log(item,"typeof");
  let rsaInput =  item !== undefined ? `${item?.candidateFirstname} ${item?.candidateLastname} (${item?.rsaCandidateNo})`: state?.certificatingFilter?.searchFieldFilter?.rsaCandidateNo;
  if(rsaInput){
    setInputValues(prev => ({...prev,searchFieldText: rsaInput}))
  }
    }},[])

 


console.log(state.certificatingFilter,"statuscertification")

  const getPersistedFilters = () => {
    console.log(state.certificatingFilter,'state values')
    return !_.isEmpty(state.certificatingFilter)
      ? {
        centreId: state.certificatingFilter.centreId 
          ? state.certificatingFilter.centreId
          : "",
          searchFieldText: state?.certificatingFilter?.searchFieldFilter ? Object.keys(state?.certificatingFilter?.searchFieldFilter)?.length > 3 
          ? `${state.certificatingFilter.searchFieldFilter.candidateFirstname} ${state.certificatingFilter.searchFieldFilter?.candidateLastname} (${state.certificatingFilter.searchFieldFilter?.rsaCandidateNo})`
          : "" : "",
        certificatingStatus: state.certificatingFilter.certificatingStatus
            ? state.certificatingFilter.certificatingStatus
            : "",
          }
          : {
            centreId:"",
            searchFieldText: "",
            certificatingStatus:"",
          };
        };

   const [inputValues, setInputValues] = useState(getPersistedFilters());
   console.log(inputValues,'input values')
  const [filters, setFilters] = useState(
    !_.isEmpty(state.certificatingFilter) ? state.certificatingFilter : {}
  );
 
  const [filteredSubQual, setFilteredSubQual] = useState([]);
  
  const clearFilters = () => {
    setInputValues({ ...inputValues, centreId: '',searchFieldText: "",
    certificatingStatus:"", });
    setFilters({});
    dispatch(setCertificatingFilter({}));
   };

   useEffect(() =>
   {
     dispatch(setStatus(inputValues?.certificatingStatus))
   },[inputValues?.certificatingStatus])
   console.log(filters,"filters")

  return (
    <div className="bg-white checkpoint-filter-wrapper">
      <div className="text-start">
        <div className="my-1 ps-3 pb-3 pt-2 border-bottom">
          <span className="typography-medium18-lato-bold">
            {landingPageLabels.filterLabels.centre}
          </span>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 px-3"> 
          <FormControl fullWidth>
          <SearchField
                dropdownVariant="suggested"
                onChange={(input) => {
                  if (input == "") {
                    setInputValues({
                      ...inputValues,
                      centreId: input,
                    });
                    delete filters.centreId;
                     setSuggestionOpenCenter(false);
                     setSuggestionOpenCandidate(false);
                  }
                  setSuggestionOpenCenter(true);
                  setInputValues({ ...inputValues, centreId: input });
                  let list = props.totalDetails.filter((item) => {
                    return item.centreNo.includes(input?.toLowerCase())
                  });
                  list = list.map((item) => ({
               text: `${item.centreNo}`.trim(),})).filter((item, index, self) => 
               index === self.findIndex((t) => t.text === item.text)
                   )
  .map((item) => ({text:item.text}));
                  setSuggestedSearchList(list);
                  setFilters({
                    ...filters,
                    centreId: input
                  });
                }}
                onDropdownItemSelect={(item) => {
                   setInputValues({ ...inputValues, centreId: item });
                  setFilters({ ...filters, centreId: item.split('-')[0] });
                  setSuggestionOpenCenter(false);              
                }}
                onClear={()=>{
                  setInputValues({ ...inputValues, centreId: '' });
                  delete filters.centreId
                }}
                placeholder="Centre number"
                popperProps={{
                  style: {
                    height: 150,
                    width:280
                  },
                }}
                
                suggestedSearchResults={suggestedSearchList}
                value={inputValues.centreId}
                open={suggestionOpenCenter && inputValues.centreId}
                dataTestId="searchField"
              />
          </FormControl>
        </div>
        <div className="candidate-search-bar my-1 ps-3 pb-3 pt-2 px-3" style={{ position: 'relative' }}>
              <p className="typography-small-bold">
                {landingPageLabels.filterLabels.candidateSearch}
              </p>
              <div style={{ position: 'relative' }}>
              {tooltipOpen && (<Tooltip title="Click on Done button to apply filter" placement="top-end" open={true}>
              <div></div>
              </Tooltip>)}
              <FormControl>
                <div onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)}>
              <SearchField
              // data-title={`Click the done button below\n\n to apply filter`}
                dropdownVariant="suggested"
                onChange={(input) => {
                  if (input == "" || input.length < 3) {
                    setInputValues({
                      ...inputValues,
                      searchFieldText: input,
                    });
                    delete filters.searchFieldFilter;
                  setSuggestionOpenCenter(false);
                  setSuggestionOpenCandidate(false);
                  return false
                  }
                  setSuggestionOpenCandidate(true);
                  setInputValues({ ...inputValues, searchFieldText: input });
                  let list = candidateData.filter((item) => {
                    return (
                      `${item.candidateFirstname} ${item.candidateLastname}`
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      item.rsaCandidateNo.includes(input.toLowerCase()) ||
                      item.centreCandidateNo.includes(input?.toLowerCase())
                    );
                  }).slice(0,10);

                 
                  list = list.map((item) => ({...item,text: `${item.candidateFirstname} ${item.candidateLastname} (${item.rsaCandidateNo})`.trim(),}))
                  .filter((item, index, self) => index === self.findIndex((t) => t.text === item.text))
                  .map((item) => ({text:item.text}));
                  setSuggestedSearchList(list);
                 isRsaEmpty.current =! list.length 
                  setFilters({
                    ...filters,
                    searchFieldFilter:{...filters.searchFieldFilter,rsaCandidateNo:input.trim()},
                  });
                }}
                
                onDropdownItemSelect={(item) => { 
                  let idOnly;
                  if (item.includes("(")) {
                    let number = item.split("(")[1];
                    idOnly = number.trim().replace(")", "");
                  }
                const selectedItem = candidateData.filter((i) => i.rsaCandidateNo === idOnly )[0] || ''
                const det = `${selectedItem.candidateFirstname} ${selectedItem.candidateLastname} (${selectedItem.rsaCandidateNo})`
                setInputValues({ ...inputValues, searchFieldText: item });               
                setFilters((fnFilters) => ({
                  ...fnFilters,
                  searchFieldFilter: selectedItem,
                }));
                 
                  setSuggestionOpenCandidate(false);
                }}
               
                onClear = { () => {
                  setInputValues({...inputValues,searchFieldFilter : ''});
                  delete filters.searchFieldFilter
                }}
                
                placeholder="Candidate name or number"
                popperProps={{
                  style: {
                    height: 150,
                    width:280,
                  },
                }}
              // {console.log(inputValues.searchFieldText,'search')}
                suggestedSearchResults={suggestedSearchList}
                value={inputValues.searchFieldText}
                open={suggestionOpenCandidate && inputValues.searchFieldText}
                dataTestId="searchField"
              />
              </div>
              </FormControl>
              </div>
        </div>
        <div className="my-1 ps-3 pb-3 pt-2 px-3">
          <p className="typography-small-bold">
            {landingPageLabels.filterLabels.certificating}
          </p>
          <FormControl fullWidth>
            <Select
              displayEmpty
              value={inputValues.certificatingStatus}
              onChange={(e) => {
                setInputValues({
                  ...inputValues,
                  certificatingStatus: e.target.value,
                });
                setFilters((fnFilters) => ({
                  ...fnFilters,
                  certificatingStatus: e.target.value,
                }));
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected === "") {
                  return `Select`;
                }
                return inputValues.certificatingStatus;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              {statusData.map((option, idx) => (
                <MenuItem key={`certificating_${idx}`} value={option}>
                  {option}
                </MenuItem>
              ))}
              
            </Select>
          </FormControl>
        </div>

        <div className="d-flex justify-content-evenly p-3 button-container">
          <Button
            color="primary"
            onClick={() => clearFilters()}
            size="large"
            style={{
              margin: 8,
            }}
            variant="outlined"
            disabled={_.isEmpty(filters)}
          >
            Clear filters
          </Button>
          <Button
            color="primary"
            onClick={() => {  
              if(isRsaEmpty.current){
                setSelectedOption('Select')
              }
              dispatch(setCertificatingFilter(filters));
              props.applyFilter(filters);
              setSuggestionOpenCenter(false);
                  setSuggestionOpenCandidate(false);
            }}
            size="large"
            style={{
              margin: 8,
            }}
            variant="contained"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CertificationStatusFilters;
