import {
  SET_USER_INFO,
  SET_ORG_SELECTION,
  SET_ALIAS_ORG,
  SET_TAB_VALUE,
  SET_SUMMARY_VIEW_ACTIVE,
  SET_DETAIL_VIEW_ACTIVE,
  SET_INELIGIBLE_VIEW_ACTIVE,
  SET_MAIN_FILTER,
  SET_FEEDBACK,
  SET_TABLE_DATA,
  SET_CERTIFICATING_FILTER,
  SET_INELIGIBLE_FILTER,
  SET_LOADER,
  SET_APPROVE,
  SET_EOAPPROVE,
  SET_STATUS,
  SET_ALERT_CLICK,
  SET_INELIGIBLE_COUNT
} from "./actionType";

export const setUserInfo = (userInfo) => {
  return {
    type: SET_USER_INFO,
    payload: userInfo,
  };
};

export const setOrgSelection = (selectedOrg) => {
  return { type: SET_ORG_SELECTION, payload: selectedOrg };
};

export const setAliasOrg = (selectedCenter) => {
  return { type: SET_ALIAS_ORG, payload: selectedCenter };
};
export const setTabValue = (tabValue) => {
  return { type: SET_TAB_VALUE, payload: tabValue };
};
export const setSummaryViewActive = (isActive) => {
  return { type: SET_SUMMARY_VIEW_ACTIVE, payload: isActive };
};
export const setDetailViewActive = (isActive) => {
  return { type: SET_DETAIL_VIEW_ACTIVE, payload: isActive };
};

export const setIneligibleViewActive = (isActive) => {
  return { type: SET_INELIGIBLE_VIEW_ACTIVE, payload: isActive };
};

export const setMainFilter = (mainFilter) => {
  return { type: SET_MAIN_FILTER, payload: mainFilter };
};

export const setCertificatingFilter = (certificatingFilter) => {
  return { type: SET_CERTIFICATING_FILTER, payload: certificatingFilter };
};

export const setIneligibleFilter = (ineligibleFilter) => {
  return { type: SET_INELIGIBLE_FILTER, payload: ineligibleFilter };
};

export const setIneligibleCount = (count) => {
  return { type: SET_INELIGIBLE_COUNT, payload: count };
};

export const setFeedback=(feedback)=>{
    return {type: SET_FEEDBACK, payload: feedback}
}

export const setTableData = (data) => {
  return {type: SET_TABLE_DATA, payload: data}
}

export const setApprove = (data) => {
  return {type: SET_APPROVE, payload: data}
}

export const setEoApprove = (data) => {
  return {type: SET_EOAPPROVE, payload: data}
}

export const setStatus = (data) => {
  return {type: SET_STATUS, payload: data}
}

export const setLoader = (loader) => {
  return {type: SET_LOADER, payload: loader}
}
export const setAlertClick = (alertClick) => {
  return {type: SET_ALERT_CLICK, payload: alertClick}
}
