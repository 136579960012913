import {
  Button,
  Divider,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
  SearchField,
  Typography,
} from "@cambridgeassessment/cambridge-ui";
import { FormControlLabel, RadioGroup } from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAliasOrg, setOrgSelection } from "../../redux/actions/actions";
import {
  APP_ERROR_CODES,
  muleApiInstance
} from "../../utils/constants";
import Loader from "../Loader";
import { internalUserModalLabels } from "../../utils/labels";

const InternalUserModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((fnState) => fnState);
  const [internalAdminCenters, setInternalAdminCenters] = useState([]);
  const [suggestedCenters, setSuggestedCenters] = useState([]);
  const [searchFieldInput, setSearchFieldInput] = useState("");
  const [radioValueSelection, setRadioValueSelection] = useState("");
  const [suggestionOpen, setSuggestionOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const onCloseOrCancel = () => {
    navigate("/error", {
      state: { code: APP_ERROR_CODES.centerSelectionMissing },
    });
  };
  const onContinue = () => {
    let radioValueSelectionArr = radioValueSelection.split("-");
    dispatch(
      setAliasOrg({
        orgId: radioValueSelectionArr[0].trim(),
        name: radioValueSelectionArr[1].trim(),
      })
    );
    dispatch(
      setOrgSelection({
        ...state.selectedOrg,
        orgId: radioValueSelectionArr[0].trim(),
        name: radioValueSelectionArr[1].trim(),
        role: "INTERNAL_ADMIN",
      })
    );
  };
  useEffect(() => {
    muleApiInstance({
      url: "centres",
      params: {
        limit: 1,
        offset: 2,
      },
    })
      .then((resp) => {
        if (resp.status == 200 && resp.data.error) {
          console.log(resp.data.error.details, resp.data.error.details);
          setIsLoading(false);
          navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
        }
        setInternalAdminCenters(resp.data);
        props.setCenters(resp.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        navigate("/error", { state: { code: APP_ERROR_CODES.apiFailed } });
      });
  }, []);

  const filterCenterList = () => {
    if (internalAdminCenters) {
      let list = internalAdminCenters.filter(
        (center) =>
          center.centreno.includes(searchFieldInput) ||
          center.centrename
            .toLowerCase()
            .includes(searchFieldInput.toLowerCase())
      );
      list = list.map((item) => ({
        ...item,
        text: `${item.centreno} - ${item.centrename}`,
      }));
      setSuggestedCenters(list);
    }
  };

  const renderCentersArray=()=>{
    return suggestedCenters.length > 0
    ? suggestedCenters
    : internalAdminCenters
  }
  return (
    <Modal onClose={onCloseOrCancel} size="medium" open>
      <ModalTitle onClose={onCloseOrCancel}>
        <Typography variant="h4">
          {internalUserModalLabels.modalTitle}
        </Typography>
      </ModalTitle>
      <ModalContent>
        <Typography variant="subtitle2" component="p">
          {internalUserModalLabels.popupLine1}
        </Typography>
        <Typography variant="body1">
          {internalUserModalLabels.popupLine2}
        </Typography>
        <Typography id={""} variant="body1" aria-hidden="true"></Typography>
        <Divider />
        <SearchField
          dropdownVariant="suggested"
          onChange={(input) => {
            setSuggestionOpen(true);
            setSearchFieldInput(input);
            filterCenterList();
          }}
          // isLoading
          onDropdownItemSelect={() => {
            setSuggestionOpen(false);
          }}
          placeholder="Centre name or number"
          popperProps={{
            style: {
              height: 150,
            },
          }}
          suggestedSearchResults={
            searchFieldInput && suggestedCenters ? suggestedCenters : []
          }
          value={searchFieldInput}
          open={suggestionOpen}
        />
        <Divider />
        <div className={`d-flex flex-column px-2`}>
          <Typography variant="h5">
            {internalUserModalLabels.listHeading}
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={(input) => {
                setRadioValueSelection(input.target.value);
              }}
            >
              {isLoading ? (
                <Loader />
              ) : (
                renderCentersArray().map((center) => {
                  return (
                    <FormControlLabel
                      value={`${center.centreno} - ${center.centrename}`}
                      control={<Radio />}
                      label={`${center.centreno} - ${center.centrename}`}
                    />
                  );
                })
              )}
            </RadioGroup>
          </FormControl>
        </div>
      </ModalContent>
      <ModalActions>
        <Button
          autoFocus
          color="primary"
          onClick={onCloseOrCancel}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button color="primary" onClick={onContinue}>
          Continue
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default InternalUserModal;
