import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/typography.css";
import "@popperjs/core";
import { mainTheme } from "@cambridgeassessment/cambridge-ui";
import reportWebVitals from "./reportWebVitals";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root"));

export const renderApp = () => {
console.log(mainTheme)
  root.render(
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
  );

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./App", renderApp);
  }
};
renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
