import React from "react";
import  { Table, TableBody, TableCell, TableContainer, TableRow, } from "@cambridgeassessment/cambridge-ui";
import { Button, TablePagination, Typography, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import {makeStyles} from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import { useEffect, useState,useRef } from "react";
import CustomCandidateTable from "../../components/CustomCandidateTable";
import {mulePostApiInstance,muleApiInstance,DEFAULT_DATE_SPAN,APP_ERROR_CODES} from "../../utils/constants";
import { csvGenerator, getComparator, getStatusStyle } from "../../utils/utils";
import EnhancedTableHead from "../EnhancedTabelHead";
import "./ineligibleCandidatesTable.css";
import {setIneligibleCount } from '../../redux/actions/actions';
import Checkbox from '@mui/material/Checkbox';
import { candidateListPageLabels } from "../../utils/labels";
import { INELIGIBLE_TABLE_HEADER } from "../../utils/constants";
const IneligibleCandidatesTable = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(fnState=>fnState)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("rsaCandidateNo");
  const [modifiedData,setModifiedData] = useState(props.ineligibleCandidatesData)
  const [reload,setReload] = useState(0)
  // const useStyles = makeStyles({
  //   checkbox:{
  //     '&.Mui-checked':{
  //       '&:hover' :{
  //       backgroundColor: '#0076BD',
  //     },
  //      '&:focus':{
  //       backgroundColor: '#0076BD',
  //      },
  //     },
  //   },
  // });

  useEffect(() =>
{
setModifiedData(props.ineligibleCandidatesData)

},[props.ineligibleCandidatesData])

  const getAcademicYear = (date) =>  {
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();
    return currentMonth >= 8 ? date.getFullYear() + 1 : date.getFullYear()
  }
  const today = new Date(); 
  const academicYear = `Summer ${getAcademicYear(today)}`;
  const getIneligibleData = (
    startDate,
    endDate,
    rsacandidateno,
    subjectlevel,
    qualification,
    qualificationStatus,
    certificatingStatus
  ) => {
    return muleApiInstance({
      url: "candidatesSummary",
      params: {
        centreId: state.selectedOrg.orgId,
        startDate: startDate ? startDate : DEFAULT_DATE_SPAN.startDate,
        endDate: endDate ? endDate : DEFAULT_DATE_SPAN.endDate,
        rsaCandidateNumber: rsacandidateno ? rsacandidateno : null,
        subjectLevel: subjectlevel ? subjectlevel : null,
        mainscheme: qualification ? qualification : null,
        qualificationResult: academicYear ,
        resultFlag: "Not Eligible"
      },
    });
  };

useEffect(()=>
{
  getIneligibleData(props.outletProps.appliedFilters.regStartDateFilter,
    props.outletProps.appliedFilters.regEndDateFilter,
    props.outletProps.appliedFilters.searchFieldFilter,
    props.outletProps.appliedFilters.subjectLevelFilter,
    props.outletProps.appliedFilters.qualificationFilter,
    props.outletProps.appliedFilters.certificatingStatus)
  .then((resp) =>
{
  console.log(resp.data,"tabledataIneligible")
  setModifiedData(resp.data)
  dispatch(setIneligibleCount(reload));
})
},[reload])

  const handleRequestSort = (_event, property) => {
    console.log("property",property,_event)
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  
  console.log(orderBy,order,"values")

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - props.ineligibleCandidatesData.length)
      : 0;

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      console.log(props.ineligibleCandidatesData,"tableData")
      // const ModifiedData = props.ineligibleCandidatesData.map((ele,i) => [{...ele,CheckedData: false}])
      // console.log(ModifiedData,"changedData")
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  console.log(modifiedData,"updatedState")
  
  const getPagination = () => {
    if (modifiedData.length > 0) {
      return rowsPerPage > 0
        ? modifiedData
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : modifiedData.sort(getComparator(order, orderBy));
    } else return [];
  
  };

  console.log(modifiedData,"TableModifiedData")
  const onDownloadClick = () => {
    
    let jsonData = modifiedData.map((x) =>{
    const reasons = x.ineligiblereason.split(';');
    console.log({ineligiblereason:`${reasons.map((reason,index) => `${reason}:${handleReasonDesc(reason)}`)}`},'json data look')
    return (
      {
        ...x,ineligiblereason:`${reasons.map((reason,index) => `${reason}: ${handleReasonDesc(reason)}`).join('; ')}`
      }
    ) })
    let csv = csvGenerator(
      jsonData,
      INELIGIBLE_TABLE_HEADER,
      'ineligibleCandidates'
    );
    const link = document.createElement("a");
    let blob = new Blob(["\ufeff", csv]);
    let url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${candidateListPageLabels.ineligibleFileLabel}_${dayjs().format("DD/MM/YYYY")}.csv`; //Name the file here
    link.click();
  };

  const handleSplChar = (char) =>
  {
   let regex = /'/
   if(regex.test(char))
   {
     console.log("splchar")
     char = char.replace("'","")
     console.log(char,"trimmed")
     return char
   }
   else{
     return char
   }
  }

const updateCall = (centreno,rsacandidateno,subjectlevel,conditionFlag) =>
{
  return mulePostApiInstance({
    url: "eligibility",
    data: [
    {
      centreId: centreno ? centreno : null,
      ocrCandidateNumber: rsacandidateno ? rsacandidateno : null,
      mainscheme: subjectlevel ? subjectlevel : null,
      eligibilityOverride : conditionFlag === "false" ? true : false,
      submittedBy: handleSplChar(state.userInfo.name),
    },
  ],
  })
  .then((resp)  => {
     console.log(resp.data,"response");
    //  let arr = resp.data;
    // setBy(resp.data)
    // console.log(final,"fetching");
    setReload(prev => prev + 1)

  })
  .catch((err) =>
 {
  setReload(prev => prev + 1)
console.log(err);
})
}

  const handleCheckBox = (idx,cno,rsa,scheme,mainflag) =>
  {
    console.log(idx,"idx")
    // const updatedRows = modifiedData.map( (row,id) => id === idx ? {...row,eligibilityoverride : !row.eligibilityoverride}  : row);
    // setModifiedData(updatedRows)
    updateCall(cno,rsa,scheme,mainflag)
    }
  const useStyles = makeStyles((theme) => ({
    checkbox:{
      '&.Mui-checked':{
        '&:hover' :{
        backgroundColor: '#0076BD',
      },
       '&:focus':{
        backgroundColor: '#0076BD',
       },
      },
    },
    customTooltip: {
      backgroundColor: '#ffcccc',
      color: 'black',
      borderRadius: '4px',
      padding: '8px',
      fontSize: '14px',
      maxWidth: '200px', 
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.8)',
    },
    customArrow:{
      '&:before': {
      backgroundColor: '#ffcccc', 
    }}
  }));

  const classes = useStyles();
  const handleReasonDesc = (str) =>
{
  switch(str)
  {
    case "No activity":
    return "No unit achievements, unit claims or examined unit entries"
    break
    case "On Track with results pending":
    return "Aggregated GLH equal or greater to required GLH + all units listed as Mandatory have an agreed unit status against them (everything apart from not entered/not claimed/not achieved) but where some unit status remain at pending"
    break;
    case "Absent from mandatory examined unit":
    return "Candidate absent from at least one mandatory examined unit"
    break;
    case "Mandatory unit not yet achieved":
    return "At least one mandatory unit is missing"
    break;
    case "Not enough guided learning hours (GLH)":
    return "GLH achieved or in progress is less than the total GLH required"
    default:
     return '';
  }
}

  return (
    <div className="">
      <TableContainer sx={{ width: "auto" }}>
        <div className="download-csv-button py-1">
        <Button
          color="primary"
          onClick={() => onDownloadClick()}
          size="large"
          style={{
            margin: 8,
          }}
          variant="contained"
          className="px-3"
        >
          {candidateListPageLabels.downloadCsv}
        </Button>
      </div>
        <Table
          sx={{
            width: "auto",
          }}
          aria-label="simple table"
          // className="table table-responsive"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={props.tableHeader}
            ineligibleCandidatesViewTable={true}
          />
          <TableBody>
            {props.ineligibleCandidatesData.length > 0 ? (
              getPagination().map((row, idx) => {
                const reasons = row.ineligiblereason.split(';');
                return (
                  <TableRow key={`row_${idx}`}>
                    <TableCell style = {{ opacity : row.eligibilityoverride  === "true" ? 0.4 : 1}}>{row.centreCandidateNo}</TableCell>
                    <TableCell className="light-blue-color" style = {{ opacity : row.eligibilityoverride  === "true" ? 0.4 : 1}}>
            <Link
              to={`${row.rsaCandidateNo}`}
              state={{ candidateDetails: row }}
              className={"text-decoration-none"}
            >
              {row.rsaCandidateNo}
            </Link>
          </TableCell>
          <TableCell className="light-blue-color" style = {{ opacity : row.eligibilityoverride  === "true" ? 0.4 : 1}}>
            <Link
              to={`${row.rsaCandidateNo}`}
              state={{ candidateDetails: row }}
              className="text-decoration-none"
            >{`${row.candidateFirstname}`}</Link>
          </TableCell>
          <TableCell className="light-blue-color" style = {{ opacity : row.eligibilityoverride  === "true" ? 0.4 : 1}}>
            <Link
              to={`${row.rsaCandidateNo}`}
              state={{ candidateDetails: row }}
              className="text-decoration-none"
            >{`${row.candidateLastname}`}</Link>
          </TableCell>
                    <TableCell style = {{ opacity : row.eligibilityoverride === "true"  ? 0.4 : 1}}>{row.qualification}</TableCell>
                    <TableCell style = {{ opacity : row.eligibilityoverride === "true"  ? 0.4 : 1}}>{row.specificationCode}</TableCell>
                    <TableCell style = {{ opacity : row.eligibilityoverride === "true"  ? 0.4 : 1}}>{row.expectedGlh}</TableCell>
                    <TableCell style = {{ opacity : row.eligibilityoverride === "true"  ? 0.4 : 1}}>{row.actualGlh}</TableCell>
                    <TableCell style = {{ opacity : row.eligibilityoverride === "true"  ? 0.4 : 1}}>
                      {row.resultStatus === 'Not Eligible' ? 
                      <Tooltip title={row.ineligiblereason ? 
                        reasons.map((reason, index) => (
                          <div key={index}>
                            <strong>{reason}:</strong><br />
                            {handleReasonDesc(reason)}
                          </div>
                        ))
              : '-'} arrow placement="left" 
                    classes={{ tooltip: classes.customTooltip , arrow:classes.customArrow }}>
                      {getStatusStyle(row.resultStatus)}
                      </Tooltip> : null}
                    
                    </TableCell>
                    <TableCell style = {{ opacity : row.eligibilityoverride  === "true" ? 0.4 : 1}}>     
                <strong>{row.ineligiblereason.split(";")[0]}:</strong><br />
                {handleReasonDesc(row.ineligiblereason.split(";")[0])}
                      </TableCell>
                      <TableCell style = {{ opacity : 1}}>
                        <Checkbox
                        style ={{width:'40px'}}
                        checked = {row.eligibilityoverride == "true" ? true : false}
                        onChange= {()=> handleCheckBox(idx,row.centreNo,row.rsaCandidateNo,row.specificationCode,row.eligibilityoverride)}
                        disabled = {state.selectedOrg.role === "TEACHER" || state.selectedOrg.role === "INTERNAL_ADMIN" ? true : false}
                        classes={{
                          root: classes.checkbox,
                        }} />
                        </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <Typography variant="subtitle1" className="text-center py-3">
                    No Results Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={props.ineligibleCandidatesData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100, 150, 200]}
      />
     
    </div>
  );
};
export default IneligibleCandidatesTable;
