import React from "react";
import {useState,useEffect} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TableContainer,
  Paper,
  Dialog,
  TableHead,
  Box,
  useTheme,Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import {makeStyles} from "@mui/styles";
import {
  csvGenerator,
  getStatusStyle,
  getComparator,
} from "../../utils/utils";
import EnhancedTableHead from "../EnhancedTabelHead";
import _ from "lodash";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ineligibleIcon from "../../assets/images/ineligible-history-icon.svg"
import pendingIcon from "../../assets/images/pending-history-icon.svg"
import achievedIcon from "../../assets/images/achieved-history-icon.svg"
import { useNavigate } from "react-router-dom";
import { candidateListPageLabels } from "../../utils/labels";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {mulePostApiInstance,muleApiInstance,DEFAULT_DATE_SPAN} from "../../utils/constants";
import "./ineligibleCandidateStatusTable.css";
import { margin } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';

const IneligibleCandidateStatusTable = (props) => {
  const { tableHeaders, tableData, appliedFilters,downloadData } = props;
  const state = useSelector(fnState=>fnState)
  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("lastUpdatedTime");
  const [time,setTime] = useState(false);
  const [by,setBy] = useState([]);
  const[open,setOpen] = useState(false);
  const downloadHeaders = [
    "Centre number",
    "OCR candidate number",
    "First name",
    "Last name",
    "Qualification",
    "Specification code",
    "Ineligible candidates",
    "Reason",
    "Modified By" ,
     "Modified On",
    "Status",
    "Qualification result no longer expected"
  ];

  
 
  const getCandidateHistory = (centreno,rsacandidateno, subjectlevel) => {
    setTime(true);
    setOpen(true);
    return muleApiInstance({
      url: "eligibilityHistory",
      params: {
        centreId: centreno ? centreno : null,
        ocrCandidateNumber: rsacandidateno ? rsacandidateno : null,
        mainScheme: subjectlevel ? subjectlevel : null,
        level: "row",
      },
    })
    .then((resp)  => {
       console.log(resp.data,"response");
      //  let arr = resp.data;
      setBy(resp.data)
      // console.log(final,"fetching");
    })
    .catch((err) =>
   {
  console.log(err);
  })
  };

  const useStyles = makeStyles((theme) => ({
    customTooltip: {
      backgroundColor: '#ffcccc',
      color: 'black',
      borderRadius: '4px',
      padding: '8px',
      fontSize: '14px',
      maxWidth: '200px', 
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.8)',
    },
    customArrow:{
      '&:before': {
      backgroundColor: '#ffcccc', 
    }}
  }));

  const classes = useStyles();
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () =>
{
  setTime(false);
  setOpen(false);
}
console.log(downloadData,"tabledata")
console.log(tableData,"AnotherData")
  const onDownloadClick = () => {
      let jsonData = downloadData.map((centre, idx) => {
      const dateString = centre.modifiedon;
         const date = new Date(dateString);
         const year = date.getFullYear();
         const month = date.getMonth();
         const day = date.getDate();
         const hours = date.getHours();
         const minutes = date.getMinutes();
         const seconds = date.getSeconds();
         console.log(centre,'c data')
       return (
         {centreno : centre.centreno,
          ocrcandidatenumber : centre.ocrcandidatenumber,
          firstname : centre.firstname,
          lastname : centre.lastname,
          qualification : centre.qualification,
          specificationcode : centre.specificationcode,
          eligibilityStatus: centre.eligibilityStatus,
          reason : centre.eligibilityStatus !== "Pending" ?
          `${centre.reason.split(";")[0]}: ${handleReasonDesc(centre.reason.split(";")[0])}`
        : `${centre.reason.split(";")[0]}`,
          modifiedby : centre.modifiedby,
          modifiedon : `${months[month]} ${day}, ${year} ${hours}:${minutes}:${seconds}`,
          status : centre.status,
          eligibilityOverride : centre.eligibilityOverride,
        }
       );
     });
    let csv = csvGenerator(jsonData, downloadHeaders, "ineligiblecandidatestatus");
    const link = document.createElement("a");
    let blob = new Blob(["\ufeff", csv]);
    let url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `ineligiblecandidatestatus_${dayjs().format("DD/MM/YYYY")}.csv`; //Name the file here
    link.click();
  };


  
  let filteredResult = [];
 if(orderBy !== "centreCandidateNo"){
  var sort1 = (filteredResult.length > 0 ? filteredResult : tableData)
 .sort(getComparator(order,orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
 } 
 else {
var sort3 = (filteredResult.length > 0 ? filteredResult : tableData)
 .sort((a, b) => {
 const valueA = a.centreCandidateNo;
 const valueB = b.centreCandidateNo;
 return (order === "asc" ? 1 : -1) * (valueA - valueB);
}).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
 }
 console.log('order by',orderBy)
 let Tdata1 = orderBy !== "centreCandidateNo" ? sort1.map((entry,history) => ({...entry,history: "History"})) : sort3.map((entry,history) => ({...entry,history: "History"}))

 if(orderBy !== "centreCandidateNo"){
   var sort2 = (filteredResult.length > 0 ? filteredResult : tableData).sort(
  getComparator(order,orderBy)
)
}
 else {
  var sort4 = (filteredResult.length > 0 ? filteredResult : tableData)
   .sort((a, b) => {
   const valueA = a.centreCandidateNo;
   const valueB = b.centreCandidateNo;
   return (order === "asc" ? 1 : -1) * (valueA - valueB);
  }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
   }
let Tdata2 = orderBy !== "centreCandidateNo" ? sort2.map((entry,history) => ({...entry,history: "History"})) : sort4.map((entry,history) => ({...entry,history: "History"}))

  const getPagination = () => {
    if (tableData.length > 0) {
      if (_.isEmpty(appliedFilters)) {
        filteredResult = tableData;
      } else {
        filteredResult = _.filter(tableData, appliedFilters);
      }
      if (rowsPerPage > 0) {
        return Tdata1
      } else {
        return Tdata2
      }
    } else return [];
  };
  
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const handleReasonDesc = (str) =>
{
  switch(str)
  {
    case "No activity":
    return "No unit achievements, unit claims or examined unit entries"
    break
    case "On Track with results pending":
    return "Aggregated GLH equal or greater to required GLH + all units listed as Mandatory have an agreed unit status against them (everything apart from not entered/not claimed/not achieved) but where some unit status remain at pending"
    break;
    case "Absent from mandatory examined unit":
    return "Candidate absent from at least one mandatory examined unit"
    break;
    case "Mandatory unit not yet achieved":
    return "At least one mandatory unit is missing"
    break;
    case "Not enough guided learning hours (GLH)":
    return "GLH achieved or in progress is less than the total GLH required"
    default:
     return '';
  }
}
 
  const tableDataContent = getPagination().map((centre, idx) => {
   const dateString = centre.modifiedon;
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
    return (
       <TableRow key={Number(centre.rsaCandidateNo + idx)}>
      <TableCell padding="normal">{centre.centreno}</TableCell>
        {/* <TableCell padding="normal">{centre.centreCandidateNo !== " " || "" ? centre.centreCandidateNo : "-"}</TableCell> */}
        <TableCell padding="normal">{centre.ocrcandidatenumber}</TableCell>
        <TableCell padding="normal">{centre.firstname}</TableCell>
        <TableCell padding="normal">{centre.lastname}</TableCell>
        <TableCell padding="normal">{centre.qualification}</TableCell>
        <TableCell padding="normal">{centre.specificationcode}</TableCell>
        <TableCell padding="normal"> 
        <Tooltip title={centre.reason ? 
          <div>
            <strong>{centre.reason.split(";")[0]}</strong>:<br />
            {handleReasonDesc(centre.reason.split(";")[0])}
          </div> 
          : '-'}  arrow placement="right" 
                    classes={{ tooltip: classes.customTooltip , arrow:classes.customArrow }}>
                      {getStatusStyle(centre.eligibilityStatus)}
                      </Tooltip>
                      </TableCell>
        <TableCell padding="normal">
          {centre.modifiedby ? centre.modifiedby: "-"}
        </TableCell>
        <TableCell padding="normal">
          {centre.modifiedon ? `${months[month]} ${day}, ${year} ${hours}:${minutes}:${seconds}` : "-"}
        </TableCell>
        <TableCell padding="normal">
        {centre.eligibilityOverride}
        </TableCell>
        <TableCell className="minWidthStatus-col">
          <Link onClick = {() => getCandidateHistory(centre.centreno, centre.ocrcandidatenumber ,centre.specificationcode)} className="text-decoration-none"> {centre.history} </Link>
          </TableCell> 
      </TableRow>
    );
  });

  const tableDataContentNo = (
    <TableRow>
      <TableCell colSpan={12}>
        <Typography variant="subtitle1" className="text-center py-3">
          No Results Found
        </Typography>
      </TableCell>
    </TableRow>
  );
  return (
    <div className="p-2">
      <div className="iu-checkpoint-text-section px-2">
        <Button
          variant="text"
          className="text-start"
          onClick={() => navigate(-1)}
          style={{ marginLeft: '-10px' }}
        >
          <ChevronLeftIcon />Back to progress tracker
        </Button>
        <Typography variant="h5" className="pt-3 pb-3 text-start ps-3">
          Ineligible candidate status
        </Typography>
      </div>

      <div className="table-data-wrap">
      <div className="wrapper">
        <div className="download-checkpoint-csv-button">
          <Button
          disabled = {downloadData !== null && downloadData.length !== 0 ? false : true}
            color="primary"
            onClick={() => onDownloadClick()}
            size="large"
            variant="contained"
            className=""
          >
            {candidateListPageLabels.downloadCsv}
          </Button>
          </div>  
        </div>

     <div style ={{position: 'relative'}}>
     <TableContainer component = {Paper}>   
     <Table aria-label="simple table" className="aaastable table-responsive">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableHeaders}
            ineligibleCandidateStatusTable={true}
          />
          <TableBody>
            {tableData.length  !== 0 && downloadData !== null   ? tableDataContent : tableDataContentNo}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        </TableContainer> 
{time ? 
  <Dialog open ={open} onClose = {handleClose} maxWidth = "md" 
  PaperProps = {{style:{position: 'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}}>
  <Box>
  <Typography id="modal-modal-title" variant="h6" component="h2">
  <div style={{display:'flex',alignItems:'center',width:'auto'}}>  
  <span style ={{paddingLeft: '10px'}}><b>History</b></span>
   <CloseIcon style={{marginLeft : 'auto' }} onClick = {() => {handleClose()}}/>
   </div>
  </Typography>
  <TableContainer component={Paper}>
  <Table  aria-label="simple table">
  <TableHead color="primary">
    <TableRow>
      <TableCell component="th" scope="row" align='center' style={{color: "black"}}>Ineligibility status</TableCell>
      <TableCell align='center' style ={{color:"black"}}>Reason</TableCell>
      <TableCell align='center' style ={{color:"black}"}}>Modified by</TableCell>
      <TableCell align='center' style ={{color: "black"}}>Modified on</TableCell>
      <TableCell align='center' style={{color:'black'}}>Qualification result no longer expected</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {by.map((row) =>{
    const date = new Date(row.modifiedon);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const renderIcon = (eligibilityStatus) => {
      if(eligibilityStatus.toLowerCase() === "achieved"){
        return <img src={achievedIcon} alt="achieved icon" width='30px' height='70px' />
      }
      else if(eligibilityStatus.toLowerCase() === "pending"){
        return <img src={pendingIcon} alt="pending icon" width='30px' height='70px' />
      }
      else if(eligibilityStatus.toLowerCase() === "not eligible"){
        return <img src={ineligibleIcon} alt="ineligible icon" width='30px' height='70px' />
      }
      else{
        return '-';
      }
      
    }
    console.log('which icon',renderIcon(row.eligibilityStatus))
  return (
      <TableRow
      >
        <TableCell align="center">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        {renderIcon(row.eligibilityStatus)}
        <span>{row.eligibilityStatus}</span>
      </div>
        </TableCell>
        <TableCell align="center">{
          row.eligibilityStatus !== "Pending" ?
          `${row.reason.split(";")[0]}: ${handleReasonDesc(row.reason.split(";")[0])}`
        : `${row.reason.split(";")[0]}`
        }</TableCell>
        <TableCell align="center" style={{width:"120px"}}>{row.modifiedby}</TableCell>
        <TableCell align="center">{row.modifiedon ? `${months[month]} ${day}, ${year} ${hours}:${minutes}:${seconds}` : "-"}</TableCell>
        <TableCell align="center">{row.eligibilityOverride}</TableCell>
      </TableRow>
    )})}
 </TableBody>
</Table>
</TableContainer>
   </Box>
   </Dialog>
   :   ''}
   </div>

        <TablePagination
          component="div"
           count={filteredResult.length ? _.filter(tableData, appliedFilters).length : tableData.length }
           onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[50, 100, 150, 200]}
        />
      
    </div>
    </div>
  );
};

export default IneligibleCandidateStatusTable;
